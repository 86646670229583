import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Services from '../CKCService';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const UploadBanners = () => {

    const [data, setdata] = useState([]);

    useEffect(() => {
        // Services
        //     .gethyperlinks()
        //     .then((e) => setdata(e.data));

        Services
            .getallckcuploadbanners()
            .then((e) => setdata(e?.data));
    }, []);

    const Swal = useSwalWrapper();

    const deleteuploadbanner = (id) => {

        if (id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {
                    Services
                        // .deletehyperlink(id)
                        .deleteckcuploadbanner(id)
                        // .then(() => Services.gethyperlinks())
                        .then(() => Services.getallckcuploadbanners())
                        .then((e) => setdata(e.data))
                        .catch((err) => console.error("Error Deleting Upload Banner: ", err));
                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };

    return (
        <>
            <Card >
                <CardHeader
                    sx={{
                        '& .MuiCardHeader-action': { alignSelf: 'center' },
                    }}
                    action={
                        <Tooltip title="Add Upload Banner">
                            <IconButton >
                                <Link to="/uploadbannersform">
                                    <AddOutlinedIcon fontSize='medium' color='action' />
                                </Link>
                            </IconButton>
                        </Tooltip>
                    }

                    title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                        Upload Banners
                    </Typography>
                />

                <CardContent sx={{ py: 0 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Sl No</StyledTableCell>
                                    <StyledTableCell align="center">Banner Title</StyledTableCell>
                                    <StyledTableCell align="center">Banner Subtitle</StyledTableCell>
                                    <StyledTableCell align="center">Banner Description</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((e, i) => {
                                    return (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell align='center' component="th" scope="row">{i + 1}</StyledTableCell>
                                            <StyledTableCell align='center' component="th" scope="row">{e?.title}</StyledTableCell>
                                            <StyledTableCell align="center" component="th" scope="row">{e?.subtitle}</StyledTableCell>
                                            <StyledTableCell align="center" component="th" scope="row">{e?.description}</StyledTableCell>
                                            <StyledTableCell align="center" component="th" scope="row">
                                                <Stack justifyContent={'center'} direction="row" alignItems="center" spacing={-0.3}>
                                                    <Tooltip title="Modify">
                                                        <Link to='/uploadbannersform' state={e}>
                                                            <IconButton >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>

                                                    <Tooltip title="Delete">
                                                        <IconButton onClick={() => deleteuploadbanner(e.id)}>
                                                            <DeleteIcon color='error' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </>
    )
}

export default UploadBanners;