import { Box, Button, Card, CardContent, CardHeader, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Services from '../CKCService'
import JoditEditor from 'jodit-react';


const TermsAndConditions = () => {

    const editor = useRef(null);

    const Swal = useSwalWrapper();
    const [content, setcontent] = useState('');

    useEffect(() => {
        Services
            .getckctncbyid(1)
            .then((e) => setcontent(e?.data?.htmlcontent))
    }, [])

    const invokeAlert = () => {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Updating Terms and Conditions Successful!',
        })
    }

    const onSubmit = () => {
        invokeAlert();

        const dat = {
            id: 1,
            htmlcontent: content
        }

        Services
            .updateckctnc(dat)
            .catch((err) => console.log("error updating CKCTNC", err));
    }


    return (
        <Box
            component={'form'}
        >

            <Card >
                <CardHeader
                    title=<Typography fontSize={'1.7rem'} color="text.primary">
                        Terms And Conditions
                    </Typography>
                />

                <CardContent sx={{ py: 0 }}>
                    <Box
                        component={'section'}
                    >
                        <Box
                            component={'div'}
                            display='flex'
                            flexDirection={{ xs: 'column', lg: 'column' }}
                            gap={{ lg: 3 }}
                            sx={{ mb: 1 }}
                        >

                            {/* <label htmlFor="Terms and Conditions"></label> */}

                            <JoditEditor
                                ref={editor}
                                value={content}
                                onChange={newContent => setcontent(newContent)}
                            />

                        </Box>

                        <Box
                            component={'div'}
                            display={'flex'}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}
                            sx={{ mt: 2 }}
                        >
                            <Button variant="contained" onClick={() => onSubmit()}>
                                Save
                            </Button>
                        </Box>

                    </Box>
                </CardContent>
            </Card>

        </Box>
    )
}

export default TermsAndConditions;