import { Box, Button, Card, CardContent, CardHeader, TextField, Tooltip, IconButton, Typography, Divider, Breadcrumbs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Services from './Services';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from 'moment';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import LogServices from '../LogServices/LogServices';

const BlogForm = () => {

  let { state } = useLocation();

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const currentDate = new Date();
  const datestring = moment(currentDate).format('YYYY-MM-DD');
  const cusdateString = moment(currentDate).format('llll');

  const [attraddcount, setattraddcount] = useState(1);
  const [attrdelcount, setattrdelcount] = useState(1);
  const [blogsubaddcount, setblogsubaddcount] = useState(1);
  const [blogsubdelcount, setblogsubdelcount] = useState(1);

  const [attraddtext, setattraddtext] = useState(null);
  const [attrdeltext, setattrdeltext] = useState(null);
  const [blogsubaddtext, setblogsubaddtext] = useState(null);
  const [blogsubdeltext, setblogsubdeltext] = useState(null);

  const [attrimgcount, setattrimgcount] = useState(1);
  const [blogsubimgcount, setblogsubimgcount] = useState(1);

  const [blogimgtext, setblogimgtext] = useState(null);
  const [attrimgtext, setattrimgtext] = useState(null);
  const [blogsubimgtext, setblogsubimgtext] = useState(null);

  const [blogform, setblogform] = useState([{ id: '', title: '', subtitle: '', heading: '', imagePath: '', readtime: '', description: '', filename: '', travellername: '' }]);
  const [blogimage, setblogimage] = useState(null);
  const [blogformerr, setblogformerr] = useState([]);
  const [blogimageerr, setblogimageerr] = useState('');

  const [attractionsform, setattractionsform] = useState([{ id: '', title: '', imagePath: '', description: '', filename: '' }]);
  const [blogsubform, setblogsubform] = useState([{ id: '', title: '', imagePath: '', imageheader: '', description: '', filename: '' }]);
  const [attractionerrors, setattractionerrors] = useState([]);
  const [blogsuberrors, setblogsuberrors] = useState([]);
  const [attrimg, setattrimg] = useState([]);
  const [attrimgerr, setattrimgerr] = useState([]);
  const [blogsubimg, setblogsubimg] = useState([]);
  const [blogsubimgerr, setblogsubimgerr] = useState([]);

  const [toggleattractions, settoggleattractions] = useState(false);
  const [toggleblogsub, settoggleblogsub] = useState(false);

  const [logincred, setlogincred] = useState({});
  const userdat = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const ef = userdat?.menus.filter((es) => es?.menuname.includes("Blogs"));

    setlogincred(ef);

    if (ef?.length == 1) {
      console.log("If part");
    } else {
      console.log("Else part");
      Swal.fire({
        icon: 'error',
        title: 'UnAuthorized Access',
        text: 'Please Login to continue',
      }).then(() => navigate('/'))
    }
  }, [])

  useEffect(() => {
    if (state) {
      const fetchData = async () => {

        const [tablename, date, filename] = state.imagePath.split('/');

        setblogform([{ id: state.id, title: state.title, subtitle: state.subtitle, heading: state.heading, imagePath: state.imagePath, readtime: state.readtime, description: state.description, filename: filename, travellername: state?.travellername }])

        Services
          .getImage(tablename, date, filename)
          .then((res) => setblogimage(res.data))
          .catch((err) => console.error(err));

        settoggleattractions(true);
        setattractionsform(
          state.attractions.map((e) => {
            const [table, d, file] = e.imagePath.split('/');
            return ({
              id: e.id,
              title: e.title,
              imagePath: e.imagePath,
              description: e.description,
              filename: file
            })
          })
        );

        const attractionImagePromises = state.attractions.map(async (e) => {
          const [table, d, file] = e.imagePath.split('/');
          try {
            const res = await Services.getImage(table, d, file);
            return res.data;
          } catch (err) {
            console.error('Error fetching attraction image:', err);
            return null;
          }
        });

        const attractionImages = await Promise.all(attractionImagePromises);
        setattrimg(attractionImages);

        settoggleblogsub(true);
        setblogsubform(
          state.blogSubSections.map((e) => {
            const [table, d, file] = e.imagePath.split('/');
            return ({
              id: e.id,
              title: e.title,
              imagePath: e.imagePath,
              imageheader: e.imageheader,
              description: e.description,
              filename: file
            })
          })
        );

        const blogsubImagePromises = state.blogSubSections.map(async (e) => {
          const [table, d, file] = e.imagePath.split('/');
          try {
            const res = await Services.getImage(table, d, file);
            return res.data;
          } catch (err) {
            console.error('Error fetching attraction image:', err);
            return null;
          }
        });

        const blogsubimages = await Promise.all(blogsubImagePromises);
        setblogsubimg(blogsubimages);

      };

      fetchData();
    }
  }, []);

  //Blog

  const handleBlogFieldChange = (field, value) => {
    const newfields = [...blogform];
    newfields[0][field] = value;
    setblogform(newfields);
    fieldvalidation();
  }

  //Blog Image Handling

  const handleBlogImageChange = (e) => {
    const file = e.target.files[0];
    setblogimageerr('');

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      setblogimgtext("Replaced Background Image");
      setblogimage(file);
      handleBlogFieldChange('filename', file.name);
      handleBlogFieldChange('imagePath', `Blogs/${datestring}/${file.name}`);
    } else {
      setblogimageerr('Invalid file type. Please choose a JPEG or PNG image.');
    }
  };

  //Attractions Fields

  const handleaddattrbutton = () => {
    setattraddcount((prev) => prev + 1);
    setattraddtext(`Added ${attraddcount} Attractions`);
    setattractionsform([{ id: '', title: '', imagePath: '', description: '', filename: '' }]);
    settoggleattractions(true);
  }

  const handleAttractionFieldChange = (i, field, value) => {
    const newFields = [...attractionsform];
    newFields[i][field] = value;
    setattractionsform(newFields);
    fieldvalidation();
  };

  const handleAddAttractionField = () => {
    setattraddcount((prev) => prev + 1);
    setattraddtext(`Added ${attraddcount} Attractions`);
    setattractionsform([...attractionsform, { id: '', title: '', imagePath: '', description: '', filename: '' }]);
  };

  const handleRemoveAttractionField = (i) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        setattrdelcount((prev) => prev + 1);
        setattrdeltext(`Deleted ${attrdelcount} Attractions`);
        const updatedAttractions = [...attractionsform];
        const updatedAttrimages = [...attrimg];
        updatedAttractions.splice(i, 1);
        updatedAttrimages.splice(i, 1);
        setattractionsform(updatedAttractions);
        setattrimg(updatedAttrimages);
        Swal.fire('Deleted!', 'Attraction has been removed!', 'success');
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    });
  };

  const handleAttractionImageChange = (i, event) => {
    const file = event.target.files[0];
    setattrimgerr([]);

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      const newimg = [...attrimg];
      setattrimgcount((prev) => prev + 1);
      setattrimgtext(`Replaced ${attrimgcount} Attraction Images`);
      newimg[i] = file;
      setattrimg(newimg);
      handleAttractionFieldChange(i, 'filename', file.name);
      handleAttractionFieldChange(i, 'imagePath', `Attractions/${datestring}/${file.name}`);
    } else {
      const newerr = [...attrimgerr];
      newerr[i] = 'Invalid file type. Please choose a JPEG or PNG image.';
      setattrimgerr(newerr);
    }
  };

  //BlogSubSections Fields

  const handleaddblogsubbutton = () => {
    setblogsubaddcount((prev) => prev + 1);
    setblogsubaddtext(`Added ${blogsubaddcount} BlogSubSections`);
    setblogsubform([{ id: '', title: '', imagePath: '', imageheader: '', description: '', filename: '' }]);
    settoggleblogsub(true);
  }

  const handleBlogSubSecFieldChange = (i, e, val) => {
    const newattr = [...blogsubform];
    newattr[i][e] = val;
    setblogsubform(newattr);
    fieldvalidation();
  };

  const handleBlogSubSecField = () => {
    setblogsubaddcount((prev) => prev + 1);
    setblogsubaddtext(`Added ${blogsubaddcount} BlogSubSections`);
    setblogsubform([...blogsubform, { id: '', title: '', description: '', imagePath: '', imageheader: '', filename: '' }]);
  };

  const handleRemoveBlogSubField = (i) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        setblogsubdelcount((prev) => prev + 1);
        setblogsubdeltext(`Deleted ${blogsubdelcount} BlogSubSections`);
        const updatedblogsub = [...blogsubform];
        const updatedblogsubimg = [...blogsubimg];
        updatedblogsub.splice(i, 1);
        updatedblogsubimg.splice(i, 1);
        setblogsubform(updatedblogsub);
        setblogsubimg(updatedblogsubimg);
        Swal.fire('Deleted!', 'BlogSubSection has been removed!', 'success');
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    });
  };

  const handleBlogSubImageChange = (i, event) => {
    const file = event.target.files[0];
    setblogsubimgerr([]);

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      setblogsubimgcount((prev) => prev + 1);
      setblogsubimgtext(`Replaced ${blogsubimgcount} BlogSubSection Images`);
      const newimg = [...blogsubimg];
      newimg[i] = file;
      setblogsubimg(newimg);
      handleBlogSubSecFieldChange(i, "filename", file.name);
      handleBlogSubSecFieldChange(i, "imagePath", `BlogSubSections/${datestring}/${file.name}`)
    } else {
      const newerr = [...blogsubimgerr];
      newerr[i] = 'Invalid file type. Please choose a JPEG or PNG image.';
      setblogsubimgerr(newerr);
    }
  };

  //field validations
  const fieldvalidation = () => {

    const blogerrors = blogform.map((e) => {
      const titleerr = !(e?.title?.trim().length > 60);
      const subtitleerr = !(e?.subtitle?.trim().length > 140);
      const headingerr = !(e?.heading?.trim().length > 20);
      const imagepatherr = !e.imagePath.trim();
      const readtimeerr = !e.readtime.trim();
      const descriptionerr = !(e?.description?.trim().length > 450);
      const travellernameerr = !e.travellername.trim();

      return { title: titleerr, subtitle: subtitleerr, heading: headingerr, imagePath: imagepatherr, readtime: readtimeerr, description: descriptionerr, travellername: travellernameerr };
    });

    const attrerrors = attractionsform.map((e) => {
      const titleError = !(e.title.trim().length < 25 && e.title.trim().length > 5);
      const imagePathError = !e.imagePath.trim();
      const descriptionError = !(e?.description?.trim().length < 25 && e.description.trim().length > 5);


      return { title: titleError, imagePath: imagePathError, description: descriptionError };
    });

    const blogsuberrors = blogsubform.map((e) => {
      const titleError = !(e.title.trim().length > 25);
      const imagePathError = !e.imagePath.trim();
      const imageheaderError = !e.imageheader.trim();
      const descriptionError = !(e.description.trim().length > 370);

      return { title: titleError, imagePath: imagePathError, imageheader: imageheaderError, description: descriptionError };
    });

    const blogimgerr = blogform.map((e) => {
      if (!e.filename.trim()) {
        return "Image is required"
      }
    });

    const attrimgerror = attractionsform.map((e) => {
      if (!e.filename.trim()) {
        return "Image is required"
      }
    });

    const blogsubimgerror = blogsubform.map((e) => {
      if (!e.filename.trim()) {
        return "Image is required"
      }
    });

    const blogerrboo = blogform.every((e) => {
      return e.title.trim().length > 60 && e.subtitle.trim().length > 140 && e.heading.trim().length > 20 && e.imagePath.trim() && e.readtime.trim() && e.description.trim().length > 450 && e.filename.trim();
    });

    const attrerrboo = attractionsform.every((e) => {
      return e.description.trim().length < 25 && e.description.trim().length > 5 && e.imagePath.trim() && e.title.trim().length < 25 && e.title.trim().length > 5 && e.filename.trim();
    });

    const blogsuberrboo = blogsubform.every((e) => {
      return e.description.trim().length > 370 && e.imagePath.trim() && e.imageheader.trim() && e.title.trim().length > 25 && e.filename.trim();
    });

    setblogformerr(blogerrors);
    setblogimageerr(blogimgerr);
    setattrimgerr(attrimgerror);
    setblogsubimgerr(blogsubimgerror);
    setattractionerrors(attrerrors);
    setblogsuberrors(blogsuberrors);

    return blogerrboo && attrerrboo && blogsuberrboo;

  };

  const invokeAlert = () => {
    if (state) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Updating Blogs Successful!',
      }).then(() => navigate('/blog'))
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Adding Blogs Successful!',
      }).then(() => navigate('/blog'))
    }
  }

  //CRUD

  const addblogs = () => {

    if (fieldvalidation()) {
      invokeAlert();
      if (state) {
        const dat = {
          id: blogform[0]?.id,
          title: blogform[0]?.title,
          subtitle: blogform[0]?.subtitle,
          readtime: blogform[0]?.readtime,
          heading: blogform[0]?.heading,
          description: blogform[0]?.description,
          imagePath: blogform[0]?.imagePath,
          travellername: blogform[0]?.travellername,
          attractions: attractionsform,
          blogSubSections: blogsubform
        };

        const logdat = {
          loggeduser: userdat.username,
          pagename: "Blogs",
          operation: "UPDATE",
          description: `Updated Blogs with Heading: ${blogform[0]?.heading}${attraddtext != null ? ", " + attraddtext : ''}${attrdeltext != null ? ", " + attrdeltext : ''}${blogsubaddtext != null ? ", " + blogsubaddtext : ''}${blogsubdeltext != null ? ", " + blogsubdeltext : ''}${blogimgtext != null ? ", " + blogimgtext : ''}${attrimgtext != null ? ", " + attrimgtext : ''}${blogsubimgtext != null ? ", " + blogsubimgtext : ''} by LoggedIn user ${userdat.username} on ${cusdateString}`,
          datetime: cusdateString
        }

        Services
          .updateBlogs(dat)
          .catch((err) => console.error("Error adding blogs: ", err));

        LogServices
          .addlogs(logdat)
          .catch((err) => console.error("Error Adding Logs: ", err));

        let formdata = new FormData();
        formdata.append("file", blogimage);
        formdata.append("tablename", "Blogs");
        formdata.append("date", datestring)

        Services
          .uploadImage(formdata)
          .catch((err) => console.error("Error uploading image :", err));


        attrimg.map((e) => {
          let attrimgdata = new FormData();
          attrimgdata.append("file", e);
          attrimgdata.append("tablename", "Attractions");
          attrimgdata.append("date", datestring);

          Services
            .uploadImage(attrimgdata)
            .catch((err) => console.error("Error uploading image :", err));
        });

        blogsubimg.map((e) => {
          let imgdata = new FormData();
          imgdata.append("file", e);
          imgdata.append("tablename", "BlogSubSections");
          imgdata.append("date", datestring);

          Services
            .uploadImage(imgdata)
            .catch((err) => console.error("Error uploading image", err));
        });

      } else {
        const dat = {
          title: blogform[0]?.title,
          subtitle: blogform[0]?.subtitle,
          readtime: blogform[0]?.readtime,
          heading: blogform[0]?.heading,
          description: blogform[0]?.description,
          imagePath: blogform[0]?.imagePath,
          travellername: blogform[0]?.travellername,
          attractions: attractionsform,
          blogSubSections: blogsubform
        };

        const logdat = {
          loggeduser: userdat.username,
          pagename: "Blogs",
          operation: "ADD",
          description: `Added Blogs by LoggedIn user ${userdat.username} on ${cusdateString}`,
          datetime: cusdateString
        }

        Services
          .addBlogs(dat)
          .catch((err) => console.error("Error adding blogs: ", err));

        LogServices
          .addlogs(logdat)
          .catch((err) => console.error("Error adding logs: ", err));

        let formdata = new FormData();
        formdata.append("file", blogimage);
        formdata.append("tablename", "Blogs");
        formdata.append("date", datestring)

        Services
          .uploadImage(formdata)
          .catch((err) => console.error("Error uploading image :", err));

        attrimg.map((e) => {
          let attrimgdata = new FormData();
          attrimgdata.append("file", e);
          attrimgdata.append("tablename", "Attractions");
          attrimgdata.append("date", datestring);

          Services
            .uploadImage(attrimgdata)
            .catch((err) => console.error("Error uploading image :", err));
        });

        blogsubimg.map((e) => {
          let imgdata = new FormData();
          imgdata.append("file", e);
          imgdata.append("tablename", "BlogSubSections");
          imgdata.append("date", datestring);

          Services
            .uploadImage(imgdata)
            .catch((err) => console.error("Error uploading image", err));
        });
      }
      setblogform([{ id: '', title: '', subtitle: '', heading: '', imagePath: '', readtime: '', description: '', filename: '', travellername: '' }]);
      setattractionsform([{ title: '', imagePath: '', description: '', filename: '' }]);
      setblogsubform([{ title: '', imagePath: '', imageheader: '', description: '', filename: '' }]);
      setattrimgerr([]);
      setattrimg([]);
      setblogsubimg([]);
      setblogsubimgerr([]);
      setblogimage();
      setblogimageerr();
    }

  };

  return (
    <>
      {logincred?.length == 1 ?

        <Box
          component={'form'}
        >

          <Box
            component={'div'}
            sx={{ mb: 2 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link to='/blog'>
                <Typography fontSize={'.875rem'} color="text.primary">
                  Blog Table
                </Typography>
              </Link>
              <Typography fontSize={'.875rem'} color="text.primary">Blog Form</Typography>
            </Breadcrumbs>
          </Box>

          <Card >
            <CardHeader
              title=<Typography fontSize={'1.7rem'} color="text.primary">
                Blog Form
              </Typography>
            />

            <CardContent sx={{ mt: -4 }}>
              <Box
                component={'section'}
              >

                <Box
                  component={'div'}
                  display={'flex'}
                  flexDirection={{ xs: 'column', lg: 'row' }}
                >
                  <Box
                    component={'div'}
                    display={'flex'}
                    flexDirection={'column'}
                    flex={3}
                  >
                    <Box
                      component={'div'}
                      display='flex'
                      flexDirection={{ xs: 'column', lg: 'row' }}
                      gap={{ lg: 3 }}
                      sx={{ mb: 1 }}
                    >

                      <TextField
                        error={blogformerr[0]?.title}
                        id="standard-error-helper-text"
                        label="Title"
                        value={blogform[0]?.title}
                        onChange={(ev) => handleBlogFieldChange("title", ev.target.value)}
                        helperText={blogformerr[0]?.title && "Please Enter atleast 60 characters"}
                        variant="standard"
                        sx={{ flex: 1 }}
                      />

                      <TextField
                        error={blogformerr[0]?.subtitle}
                        id="standard-error-helper-text"
                        label="Sub Title"
                        value={blogform[0]?.subtitle}
                        onChange={(ev) => handleBlogFieldChange("subtitle", ev.target.value)}
                        helperText={blogformerr[0]?.subtitle && "Please Enter atleast 140 characters"}
                        variant="standard"
                        sx={{ flex: 1 }}
                      />

                      <TextField

                        error={blogformerr[0]?.heading}
                        id="standard-error-helper-text"
                        label="Heading"
                        value={blogform[0]?.heading}
                        onChange={(ev) => handleBlogFieldChange("heading", ev.target.value)}
                        helperText={blogformerr[0]?.heading && "Please Enter atleast 20 characters"}
                        variant="standard"
                        sx={{ flex: 1 }}
                      />

                      <TextField

                        error={blogformerr[0]?.readtime}
                        id="standard-error-helper-text"
                        label="Read Time"
                        value={blogform[0]?.readtime}
                        onChange={(ev) => handleBlogFieldChange("readtime", ev.target.value)}
                        helperText={blogformerr[0]?.readtime && "required"}
                        variant="standard"
                        sx={{ flex: 1 }}
                      />

                    </Box>

                    <Box
                      component={"div"}
                      display='flex'
                      flexDirection={{ xs: 'column', lg: 'row' }}
                      gap={{ lg: 3 }}
                      sx={{ mb: 2 }}
                    >

                      <TextField
                        error={blogformerr[0]?.travellername}
                        id="standard-error-helper-text"
                        label="Traveller Name"
                        value={blogform[0]?.travellername}
                        onChange={(ev) => handleBlogFieldChange("travellername", ev.target.value)}
                        helperText={blogformerr[0]?.travellername && "required"}
                        variant="standard"
                        sx={{ flex: 1 }}
                      />

                      <TextField
                        error={blogformerr[0]?.description}
                        id="standard-error-helper-text"
                        label="Description"
                        multiline
                        value={blogform[0]?.description}
                        onChange={(ev) => handleBlogFieldChange("description", ev.target.value)}
                        helperText={blogformerr[0]?.description && "Please Enter atleast 450 characters"}
                        variant="standard"
                        sx={{ flex: 3 }}
                      />

                    </Box>
                  </Box>

                  <Box
                    component={'div'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    sx={{ flex: 1 }}
                  >

                    {blogimage ?
                      <Button
                        color='warning'
                        variant="contained"
                        component="label"
                        sx={{ fontSize: 12 }}
                        startIcon={<CloudUploadIcon />}
                      >
                        Re-Upload Image
                        <input
                          hidden
                          accept="image/jpeg, image/png"
                          type="file"
                          onChange={(e) => handleBlogImageChange(e)}
                        />
                      </Button>
                      :
                      <Button
                        variant="contained"
                        component="label"
                        sx={{ fontSize: 12 }}
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload Image
                        <input
                          hidden
                          accept="image/jpeg, image/png"
                          type="file"
                          onChange={(e) => handleBlogImageChange(e)}
                        />
                      </Button>
                    }

                    {/* {blogimageerr && (
                    <Typography color="error" variant="body2">
                      {blogimageerr}
                    </Typography>
                  )} */}

                    <Box
                      component={'div'}
                      mt={2}
                    >
                      {blogimage && (
                        <img src={URL.createObjectURL(blogimage)} alt="image" height={100} width={100} />
                      )}
                    </Box>

                    <Typography color={'GrayText'} sx={{ mb: 1 }} level="body-sm">Background Image</Typography>

                    {blogimageerr && (
                      <Typography color="error" align='center' variant="body2">
                        {blogimageerr}
                      </Typography>
                    )}

                  </Box>
                </Box>



                {/* <Box
                  component={'div'}
                  display='flex'
                  flexDirection={{ xs: 'column', lg: 'row' }}
                  gap={{ lg: 3 }}
                  sx={{ mb: 1 }}
                >

                  <TextField
                    error={blogformerr[0]?.title}
                    id="standard-error-helper-text"
                    label="Title"
                    value={blogform[0]?.title}
                    onChange={(ev) => handleBlogFieldChange("title", ev.target.value)}
                    helperText={blogformerr[0]?.title && "Please Enter atleast 60 characters"}
                    variant="standard"
                    sx={{ flex: 1 }}
                  />

                  <TextField
                    error={blogformerr[0]?.subtitle}
                    id="standard-error-helper-text"
                    label="Sub Title"
                    value={blogform[0]?.subtitle}
                    onChange={(ev) => handleBlogFieldChange("subtitle", ev.target.value)}
                    helperText={blogformerr[0]?.subtitle && "Please Enter atleast 140 characters"}
                    variant="standard"
                    sx={{ flex: 1 }}
                  />

                  <TextField

                    error={blogformerr[0]?.heading}
                    id="standard-error-helper-text"
                    label="Heading"
                    value={blogform[0]?.heading}
                    onChange={(ev) => handleBlogFieldChange("heading", ev.target.value)}
                    helperText={blogformerr[0]?.heading && "Please Enter atleast 20 characters"}
                    variant="standard"
                    sx={{ flex: 1 }}
                  />

                  <TextField

                    error={blogformerr[0]?.readtime}
                    id="standard-error-helper-text"
                    label="Read Time"
                    value={blogform[0]?.readtime}
                    onChange={(ev) => handleBlogFieldChange("readtime", ev.target.value)}
                    helperText={blogformerr[0]?.readtime && "required"}
                    variant="standard"
                    sx={{ flex: 1 }}
                  />

                </Box> */}

                {/* <Box
                  component={"div"}
                  display='flex'
                  flexDirection={{ xs: 'column', lg: 'row' }}
                  gap={{ lg: 3 }}
                  sx={{ mb: 2 }}
                >

                  <TextField
                    error={blogformerr[0]?.travellername}
                    id="standard-error-helper-text"
                    label="Traveller Name"
                    value={blogform[0]?.travellername}
                    onChange={(ev) => handleBlogFieldChange("travellername", ev.target.value)}
                    helperText={blogformerr[0]?.travellername && "required"}
                    variant="standard"
                    sx={{ flex: 1 }}
                  />

                  <TextField
                    error={blogformerr[0]?.description}
                    id="standard-error-helper-text"
                    label="Description"
                    multiline
                    value={blogform[0]?.description}
                    onChange={(ev) => handleBlogFieldChange("description", ev.target.value)}
                    helperText={blogformerr[0]?.description && "Please Enter atleast 450 characters"}
                    variant="standard"
                    sx={{ flex: 3 }}
                  />

                </Box> */}

                {/* <Box
                  component={'div'}
                >
                  {blogimage ?
                    <Button
                      color='warning'
                      variant="contained"
                      component="label"
                      startIcon={<CloudUploadIcon />}
                    >
                      Re-Upload Image
                      <input
                        hidden
                        accept="image/jpeg, image/png"
                        type="file"
                        onChange={(e) => handleBlogImageChange(e)}
                      />
                    </Button>
                    :
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image
                      <input
                        hidden
                        accept="image/jpeg, image/png"
                        type="file"
                        onChange={(e) => handleBlogImageChange(e)}
                      />
                    </Button>
                  }

                  {blogimageerr && (
                    <Typography color="error" variant="body2">
                      {blogimageerr}
                    </Typography>
                  )}

                  <Box
                    component={'div'}
                    mt={2}
                  >
                    {blogimage && (
                      <img src={URL.createObjectURL(blogimage)} alt="image" height={100} width={100} />
                    )}
                  </Box>

                </Box> */}

                {toggleattractions && attractionsform.length > 0 ?
                  <Card sx={{ mb: 2 }}>
                    <CardHeader
                      sx={{ paddingX: 2 }}
                      title=<Typography fontSize={'1.35rem'} color="text.primary">
                        Attractions
                      </Typography>
                    />

                    <CardContent sx={{ p: 0 }}>
                      {attractionsform.map((e, i) => (

                        <Box component={'div'} key={i} sx={{ mb: 1 }}>
                          <CardHeader
                            sx={{
                              '& .MuiCardHeader-action': { alignSelf: 'center' },
                              py: 0, px: 2
                            }}
                            action={
                              <Tooltip title="Remove field">
                                <IconButton onClick={() => handleRemoveAttractionField(i)}>
                                  <DeleteOutlineIcon color='error' />
                                </IconButton>
                              </Tooltip>
                            }
                            title=<Typography fontSize={'1.1rem'} color="text.primary">
                              Attraction {i + 1}
                            </Typography>
                          />

                          <CardContent sx={{ py: 0, px: 2 }}>

                            <Box
                              component={'section'}
                            >

                              <Box
                                component={'div'}
                                display={'flex'}
                                flexDirection={{ xs: 'column', lg: 'row' }}
                              >
                                <Box
                                  component={'div'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  flex={3}
                                >
                                  <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'column' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 2 }}
                                  >

                                    <TextField
                                      error={attractionerrors[i]?.title}
                                      helperText={attractionerrors[i]?.title && 'Please limit your entry to a maximum of 25 characters'}
                                      id="standard-error-helper-text"
                                      label="Title"
                                      value={e.title}
                                      onChange={(event) => handleAttractionFieldChange(i, 'title', event.target.value)}
                                      variant="standard"
                                    // sx={{ flex: 1 }}
                                    />

                                    <TextField
                                      error={attractionerrors[i]?.description}
                                      helperText={attractionerrors[i]?.description && 'Please limit your entry to a maximum of 25 characters'}
                                      id="standard-error-helper-text"
                                      label="Description"
                                      // multiline
                                      value={e.description}
                                      onChange={(event) => handleAttractionFieldChange(i, 'description', event.target.value)}
                                      variant="standard"
                                    // sx={{ flex: 3 }}
                                    />

                                  </Box>


                                </Box>

                                <Box
                                  component={'div'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  alignItems={'center'}
                                  sx={{ flex: 1 }}
                                >
                                  {attrimg[i] ? (
                                    <Button
                                      color='warning'
                                      variant="contained"
                                      component="label"
                                      sx={{ fontSize: 12 }}
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Re-Upload Image
                                      <input
                                        hidden
                                        accept="image/jpeg, image/png"
                                        type="file"
                                        onChange={(ev) => { handleAttractionImageChange(i, ev); }}
                                      />

                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{ fontSize: 12 }}
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Upload Image
                                      <input
                                        hidden
                                        accept="image/jpeg, image/png"
                                        type="file"
                                        onChange={(ev) => { handleAttractionImageChange(i, ev); }}
                                      />

                                    </Button>
                                  )
                                  }

                                  {/* {attrimgerr[i] && (
                                      <Typography color="error" variant="body2">
                                        {attrimgerr[i]}
                                      </Typography>
                                    )} */}

                                  <Box
                                    component={'div'}
                                    mt={2}
                                  >
                                    {attrimg[i] && (
                                      <img src={URL.createObjectURL(attrimg[i])} alt="image" height={100} width={100} />
                                    )}

                                  </Box>

                                  <Typography color={'GrayText'} sx={{ mb: 1 }} level="body-sm">{`Attraction ${i + 1} Image`}</Typography>

                                  {attrimgerr[i] && (
                                    <Typography color="error" align='center' variant="body2">
                                      {attrimgerr[i]}
                                    </Typography>
                                  )}
                                </Box>

                              </Box>

                              {/* <Box
                                component={'div'}
                                display='flex'
                                flexDirection={{ xs: 'column', lg: 'row' }}
                                gap={{ lg: 3 }}
                                sx={{ mb: 2 }}
                              >

                                <TextField
                                  error={attractionerrors[i]?.title}
                                  helperText={attractionerrors[i]?.title && 'Please limit your entry to a maximum of 25 characters'}
                                  id="standard-error-helper-text"
                                  label="Title"
                                  value={e.title}
                                  onChange={(event) => handleAttractionFieldChange(i, 'title', event.target.value)}
                                  variant="standard"
                                  sx={{ flex: 1 }}
                                />

                                <TextField
                                  error={attractionerrors[i]?.description}
                                  helperText={attractionerrors[i]?.description && 'Please limit your entry to a maximum of 25 characters'}
                                  id="standard-error-helper-text"
                                  label="Description"
                                  multiline
                                  value={e.description}
                                  onChange={(event) => handleAttractionFieldChange(i, 'description', event.target.value)}
                                  variant="standard"
                                  sx={{ flex: 3 }}
                                />

                              </Box> */}

                              {/* {attrimg[i] ? (
                                <Button
                                  color='warning'
                                  variant="contained"
                                  component="label"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Re-Upload Image
                                  <input
                                    hidden
                                    accept="image/jpeg, image/png"
                                    type="file"
                                    onChange={(ev) => { handleAttractionImageChange(i, ev); }}
                                  />

                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  component="label"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                  <input
                                    hidden
                                    accept="image/jpeg, image/png"
                                    type="file"
                                    onChange={(ev) => { handleAttractionImageChange(i, ev); }}
                                  />

                                </Button>
                              )
                              }

                              {attrimgerr[i] && (
                                <Typography color="error" variant="body2">
                                  {attrimgerr[i]}
                                </Typography>
                              )}

                              <Box
                                component={'div'}
                                mt={2}
                              >
                                {attrimg[i] && (
                                  <img src={URL.createObjectURL(attrimg[i])} alt="image" height={100} width={100} />
                                )}

                              </Box> */}

                            </Box>
                          </CardContent>
                          {i < attractionsform.length - 1 ? <Divider /> : <Box component={'div'}></Box>}
                        </Box>
                      ))}
                      <Box
                        component={'div'}
                        display={'flex'}
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        sx={{ px: 2 }}
                      >
                        <Button onClick={() => handleAddAttractionField()} variant="contained">
                          Add More
                        </Button>
                      </Box>
                    </CardContent>

                  </Card> : <>
                    <Box
                      component={'div'}
                      display={'flex'}
                      sx={{ mb: 2 }}
                      justifyContent={{ xs: 'center', md: 'flex-start' }}
                    >
                      <Button onClick={() => handleaddattrbutton()} variant="contained">
                        Add Attractions
                      </Button>
                    </Box>
                  </>
                }

                {toggleblogsub && blogsubform.length > 0 ?
                  <Card>
                    <CardHeader
                      sx={{ paddingX: 2 }}
                      title=<Typography fontSize={'1.35rem'} color="text.primary">
                        BlogSubSections
                      </Typography>
                    />

                    <CardContent sx={{ p: 0 }}>
                      {blogsubform.map((e, i) => (

                        <Box component={'div'} key={i} sx={{ mb: 1 }}>
                          <CardHeader
                            sx={{
                              '& .MuiCardHeader-action': { alignSelf: 'center' },
                              py: 0, px: 2
                            }}
                            action={
                              <Tooltip title="Remove field">
                                <IconButton onClick={() => handleRemoveBlogSubField(i)}>
                                  <DeleteOutlineIcon color='error' />
                                </IconButton>
                              </Tooltip>
                            }
                            title=<Typography fontSize={'1.1rem'} color="text.primary">
                              BlogSubSection {i + 1}
                            </Typography>
                          />

                          <CardContent sx={{ px: 2, py: 0 }}>

                            <Box
                              component={'section'}
                            >

                              <Box
                                component={'div'}
                                display={'flex'}
                                flexDirection={{ xs: 'column', lg: 'row' }}
                              >
                                <Box
                                  component={'div'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  flex={3}
                                >
                                  <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                  >

                                    <TextField
                                      error={blogsuberrors[i]?.title}
                                      helperText={blogsuberrors[i]?.title && 'Please Enter atleast 25 characters'}
                                      id="standard-error-helper-text"
                                      label="Title"
                                      value={e.title}
                                      onChange={(ev) => handleBlogSubSecFieldChange(i, 'title', ev.target.value)}
                                      variant="standard"
                                      sx={{ flex: 1 }}
                                    />

                                    <TextField
                                      error={blogsuberrors[i]?.imageheader}
                                      helperText={blogsuberrors[i]?.imageheader && 'required'}
                                      id="standard-error-helper-text"
                                      label="Image Header"
                                      value={e.imageheader}
                                      onChange={(ev) => handleBlogSubSecFieldChange(i, 'imageheader', ev.target.value)}
                                      variant="standard"
                                      sx={{ flex: 1 }}
                                    />

                                  </Box>

                                  <Box
                                    component={"div"}
                                    sx={{
                                      '& .MuiTextField-root': { width: '100%', mb: 2 },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <TextField
                                      error={blogsuberrors[i]?.description}
                                      helperText={blogsuberrors[i]?.description && 'Please Enter atleast 370 characters'}
                                      id="standard-error-helper-text"
                                      label="Description"
                                      multiline
                                      value={e.description}
                                      onChange={(ev) => handleBlogSubSecFieldChange(i, 'description', ev.target.value)}
                                      variant="standard"
                                    />

                                  </Box>

                                </Box>

                                <Box
                                  component={'div'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  alignItems={'center'}
                                  sx={{ flex: 1 }}
                                >
                                  {blogsubimg[i] ? (
                                    <Button
                                      color='warning'
                                      variant="contained"
                                      component="label"
                                      sx={{ fontSize: 12 }}
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Re-Upload Image
                                      <input
                                        hidden
                                        accept="image/jpeg, image/png"
                                        type="file"
                                        onChange={(ev) => { handleBlogSubImageChange(i, ev); }}
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{ fontSize: 12 }}
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Upload Image
                                      <input
                                        hidden
                                        accept="image/jpeg, image/png"
                                        type="file"
                                        onChange={(ev) => { handleBlogSubImageChange(i, ev); }}
                                      />
                                    </Button>
                                  )
                                  }

                                  {/* {blogsubimgerr[i] && (
                                    <Typography color="error" variant="body2">
                                      {blogsubimgerr[i]}
                                    </Typography>
                                  )} */}

                                  <Box
                                    component={'div'}
                                    mt={2}
                                  >
                                    {blogsubimg[i] && (
                                      <img src={URL.createObjectURL(blogsubimg[i])} alt="image" height={100} width={100} />
                                    )}

                                  </Box>

                                  <Typography color={'GrayText'} sx={{ mb: 1 }} level="body-sm">{`BlogSubSection ${i + 1} Image`}</Typography>

                                  {blogsubimgerr[i] && (
                                    <Typography color="error" align='center' variant="body2">
                                      {blogsubimgerr[i]}
                                    </Typography>
                                  )}
                                </Box>

                              </Box>

                              {/* <Box
                                component={'div'}
                                display='flex'
                                flexDirection={{ xs: 'column', lg: 'row' }}
                                gap={{ lg: 3 }}
                                sx={{ mb: 1 }}
                              >

                                <TextField
                                  error={blogsuberrors[i]?.title}
                                  helperText={blogsuberrors[i]?.title && 'Please Enter atleast 25 characters'}
                                  id="standard-error-helper-text"
                                  label="Title"
                                  value={e.title}
                                  onChange={(ev) => handleBlogSubSecFieldChange(i, 'title', ev.target.value)}
                                  variant="standard"
                                  sx={{ flex: 1 }}
                                />

                                <TextField
                                  error={blogsuberrors[i]?.imageheader}
                                  helperText={blogsuberrors[i]?.imageheader && 'required'}
                                  id="standard-error-helper-text"
                                  label="Image Header"
                                  value={e.imageheader}
                                  onChange={(ev) => handleBlogSubSecFieldChange(i, 'imageheader', ev.target.value)}
                                  variant="standard"
                                  sx={{ flex: 1 }}
                                />

                              </Box>

                              <Box
                                component={"div"}
                                sx={{
                                  '& .MuiTextField-root': { width: '100%', mb: 2 },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <TextField
                                  error={blogsuberrors[i]?.description}
                                  helperText={blogsuberrors[i]?.description && 'Please Enter atleast 370 characters'}
                                  id="standard-error-helper-text"
                                  label="Description"
                                  multiline
                                  value={e.description}
                                  onChange={(ev) => handleBlogSubSecFieldChange(i, 'description', ev.target.value)}
                                  variant="standard"
                                />

                              </Box> */}


                              {/* {blogsubimg[i] ? (
                                <Button
                                  color='warning'
                                  variant="contained"
                                  component="label"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Re-Upload Image
                                  <input
                                    hidden
                                    accept="image/jpeg, image/png"
                                    type="file"
                                    onChange={(ev) => { handleBlogSubImageChange(i, ev); }}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  component="label"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                  <input
                                    hidden
                                    accept="image/jpeg, image/png"
                                    type="file"
                                    onChange={(ev) => { handleBlogSubImageChange(i, ev); }}
                                  />
                                </Button>
                              )
                              }

                              {blogsubimgerr[i] && (
                                <Typography color="error" variant="body2">
                                  {blogsubimgerr[i]}
                                </Typography>
                              )}

                              <Box
                                component={'div'}
                                mt={2}
                              >
                                {blogsubimg[i] && (
                                  <img src={URL.createObjectURL(blogsubimg[i])} alt="image" height={100} width={100} />
                                )}

                              </Box> */}

                            </Box>
                          </CardContent>
                          {i < blogsubform.length - 1 ? <Divider /> : <Box component={'div'}></Box>}
                        </Box>
                      ))}
                      <Box
                        component={'div'}
                        display={'flex'}
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        sx={{ px: 2 }}
                      >
                        <Button onClick={() => handleBlogSubSecField()} variant="contained">
                          Add More
                        </Button>
                      </Box>
                    </CardContent>

                  </Card> : <>
                    <Box
                      component={'div'}
                      display={'flex'}
                      justifyContent={{ xs: 'center', md: 'flex-start' }}
                    >
                      <Button onClick={() => handleaddblogsubbutton()} variant="contained">
                        Add BlogSubSections
                      </Button>
                    </Box>
                  </>
                }

                <Box
                  component={'div'}
                  display={'flex'}
                  justifyContent={{ xs: 'center', md: 'flex-end' }}
                  sx={{ mt: 2 }}
                >
                  <Button onClick={() => addblogs()} variant="contained">
                    Save
                  </Button>
                </Box>

              </Box>

            </CardContent>
          </Card>
        </Box>
        : <></>}

    </>
  )

};

export default BlogForm;