import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios"

class Services {

    //News
    getnews() {
        return axios.get(`${SCHEME}${DOMAIN}${[PORT]}/getnews`);
    };

    getnewsbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${[PORT]}/getnews/${id}`);
    };

    addnews(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${[PORT]}/addnews`, dat);
    };

    updatenews(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${[PORT]}/updatenews`, dat);
    };

    deletenews(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${[PORT]}/deletenews/${id}`);
    };

    //NewsSubSection

    addnewssubsection(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${[PORT]}/addnewssubsection`, dat);
    };

    deletenewssubsection(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${[PORT]}/deletenewssubsection/${id}`);
    };

    updatenewssubsection(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${[PORT]}/updatenewssubsection`, dat);
    };

    getnewssubsectionbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${[PORT]}/getnewssubsection/${id}`);
    };

    getallnewssubsections() {
        return axios.get(`${SCHEME}${DOMAIN}${[PORT]}/getnewssubsections`);
    };

    //Image Uploading

    uploadImage(formdata) {
        return axios.post(`${SCHEME}${DOMAIN}${[PORT]}/addimage`, formdata);
    };

    //Image Retrieving
    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${[PORT]}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
    };
};

export default new Services();