import React, { useState, useEffect } from 'react';
import Services from './Services';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7352c7",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const Menus = () => {

  const [data, setdata] = useState([]);
  const [submenusdata, setsubmenusdata] = useState([]);
  const [togglerow, settogglerow] = useState(null);
  const [logincred, setlogincred] = useState({});

  const [puremenudat, setpuremenudat] = useState([]);
  const [sorttoggle, setsorttoggle] = useState(true);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const userdat = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const ef = userdat?.menus.filter((es) => es?.menuname.includes("Menus"));

    setlogincred(ef);

    if (ef?.length == 1) {
      console.log("If part");
    } else {
      console.log("Else part");
      Swal.fire({
        icon: 'error',
        title: 'UnAuthorized Access',
        text: 'Please Login to continue',
      }).then(() => navigate('/'))
    }
  }, [])


  useEffect(() => {
    Services
      .getmenus()
      .then(res => {
        setdata(res.data);
        setpuremenudat(res?.data);
      });

  }, []);


  const deletemenus = (id) => {
    if (id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          Services.deletemenus(id)
            .then(() => Services.getmenus().then(res => {
              setdata(res.data);
              setpuremenudat(res?.data);
            }));
          Swal.fire('Deleted!', 'Record has been deleted', 'success');
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
    }
  };

  const rowtoggle = (i, id) => {
    Services
      .getmenubyid(id)
      .then((e) => setsubmenusdata(e.data.submenus))
      .catch((err) => console.error("error fetching submenus", err));

    settogglerow((prev) => (prev === i ? null : i));
  }

  const handleSearch = (teg) => {
    const filtered = puremenudat?.filter((es) => es?.name.toLowerCase().includes(teg.toLowerCase()) || es?.link.toLowerCase().includes(teg.toLowerCase()));
    setdata(filtered);
    settogglerow(null);
  }

  const handleSort = () => {
    setsorttoggle((prev) => !prev);
    if (sorttoggle) {
      const sorted = data?.sort((a, b) => a?.name > b?.name ? 1 : -1);
      setdata(sorted);
    } else {
      const sorted = data?.sort((a, b) => a?.name < b?.name ? 1 : -1);
      setdata(sorted);
    }
    settogglerow(null);
  }

  return (
    <>
      {logincred?.length == 1 ?
        <Card >
          <CardHeader
            sx={{
              '& .MuiCardHeader-action': { alignSelf: 'center' },
            }}

            action={
              <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                <Tooltip title="Search Menus">
                  <Input
                    id="input-with-icon-adornment"
                    placeholder='Search'
                    onChange={(ev) => handleSearch(ev.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchOutlinedIcon fontSize='small' color='action' />
                      </InputAdornment>
                    }
                  />
                </Tooltip>

                <Tooltip title="Sort Menus">
                  <IconButton onClick={() => handleSort()}>
                    <Link>
                      <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                    </Link>
                  </IconButton>
                </Tooltip>

              </Stack>
            }


            // action={
            //   <Tooltip title="Add Menus">
            //     <IconButton >
            //       <Link to="/menuform">
            //         <AddOutlinedIcon fontSize='medium' color='action' />
            //       </Link>
            //     </IconButton>
            //   </Tooltip>
            // }
            title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
              Menus
            </Typography>
          />

          <CardContent sx={{ py: 0 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                <TableHead>
                  <TableRow >
                    <StyledTableCell
                    // width={76} 

                    />
                    <StyledTableCell align="left">Sl No</StyledTableCell>
                    <StyledTableCell
                      // width={420} 
                      align="left">Menu Name</StyledTableCell>
                    <StyledTableCell align="left">Menu Link</StyledTableCell>
                    {/* <StyledTableCell align="center">Title</StyledTableCell>
                  <StyledTableCell align="center">Description</StyledTableCell>
                  <StyledTableCell align="center">Keywords</StyledTableCell> */}
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((e, i) => {
                    return (
                      <>
                        <StyledTableRow key={i}>
                          <StyledTableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => rowtoggle(i, e.id)}
                            >
                              {togglerow === i ?
                                <Tooltip title="Collapse">
                                  <KeyboardArrowUpIcon />
                                </Tooltip>
                                :
                                <Tooltip title="Expand">
                                  <KeyboardArrowDownIcon />
                                </Tooltip>
                              }
                            </IconButton>
                          </StyledTableCell>
                          <StyledTableCell align='left' component="th" scope="row">{i + 1}</StyledTableCell>
                          <StyledTableCell align='left' component="th" scope="row">{e.name}</StyledTableCell>
                          <StyledTableCell align='left' component="th" scope="row">{e.link}</StyledTableCell>
                          {/* <StyledTableCell align='center' component="th" scope="row">{e.title}</StyledTableCell>
                        <StyledTableCell align='center' component="th" scope="row">{e.description}</StyledTableCell>
                        <StyledTableCell align='center' component="th" scope="row">{e.keywords}</StyledTableCell> */}
                          <StyledTableCell align="left" component="th" scope="row">
                            <Stack justifyContent={'flex-start'} direction="row" alignItems="center" spacing={-0.2}>
                              <Tooltip title="Modify">
                                <Link to="/menuform" state={e}>
                                  <IconButton>
                                    <EditIcon color='action' />
                                  </IconButton>
                                </Link>
                              </Tooltip>

                              {/* <Tooltip title="Delete">
                              <IconButton onClick={() => deletemenus(e.id)}>
                                <DeleteIcon color='error' />
                              </IconButton>
                            </Tooltip> */}
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow >
                          <StyledTableCell
                            sx={{ p: 0, backgroundColor: 'white' }}
                            colSpan={8}
                          >
                            <Collapse in={togglerow === i} timeout={"auto"} unmountOnExit sx={{ m: 2 }}>
                              <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                  {/* <TableHead>
                                  <StyledTableRow>
                                    <StyledTableCell align="center">Sl No</StyledTableCell>
                                    <StyledTableCell align="center">SubMenu Name</StyledTableCell>
                                    <StyledTableCell align="center">SubMenu Link</StyledTableCell>
                                    <StyledTableCell align="center">Title</StyledTableCell>
                                    <StyledTableCell align="center">Description</StyledTableCell>
                                    <StyledTableCell align="center">Keywords</StyledTableCell>
                                  </StyledTableRow>
                                </TableHead> */}
                                  <TableBody>
                                    {submenusdata.length > 0 ?
                                      submenusdata.map((ev, index) => {
                                        return (
                                          <StyledTableRow key={index}>
                                            {/* <StyledTableCell /> */}
                                            <StyledTableCell
                                              width={340}
                                              align='center' component="th" scope="row">{index + 1}</StyledTableCell>
                                            <StyledTableCell
                                              width={330}
                                              align='left' component="th" scope="row">{ev.name}</StyledTableCell>
                                            <StyledTableCell
                                              width={460}
                                              align='left' component="th" scope="row">{ev.link}</StyledTableCell>
                                            {/* <StyledTableCell align='center' component="th" scope="row">{ev.title}</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">{ev.description}</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">{ev.keywords}</StyledTableCell> */}
                                          </StyledTableRow>
                                        )
                                      }) :
                                      (<p style={{ textAlign: 'center' }}>No SubMenu data available for this Menu</p>)
                                    }

                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Collapse>
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    )
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card> : <></>}


    </>
  )
}

export default Menus;