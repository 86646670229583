import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Services from '../FormServices';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import moment from 'moment';
import LogServices from 'app/pages/LogServices/LogServices';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CustomerQueries = () => {
    const [data, setdata] = useState([]);
    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);

    const [contdat, setcontdat] = useState([]);
    const [purecontdat, setpurecontdat] = useState([]);
    // const [togglecontactus, settogglecontactus] = useState(false);

    const [filval, setfilval] = useState("Business Travel")
    const [disdat, setdisdat] = useState([])

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currDate = new Date();
    const cusdateString = moment(currDate).format("llll");

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Customer Queries"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {

        Services
            .getformcommon()
            .then((e) => {
                // setdata(e.data);
                setpuredat(e.data);
                const fil = e.data?.filter((ev) => ev?.formname === "Business Travel");
                setdisdat(fil);
            })
            .catch((err) => console.error("Error fetching Common Forms Data: ", err));

        Services
            .getformcontactus()
            .then((e) => {
                // setcontdat(e.data);
                setpurecontdat(e.data);
            })
            .catch((err) => console.error("Error fetching ContactUs Form Data: ", err));

    }, []);


    const formfil = [
        {
            formname: "Contact Us",
            formval: "Contact Us"
        },
        {
            formname: "Business Travel",
            formval: "Business Travel"
        },
        {
            formname: "Technologies",
            formval: "Technologies"
        },
        {
            formname: "Global Partner",
            formval: "Global Partner"
        },
        {
            formname: "Space Travel",
            formval: "Space Travel"
        },
        {
            formname: "Space Cargo",
            formval: "Space Cargo"
        },
        {
            formname: "Mannai Holiday",
            formval: "Mannai Holiday"
        }
    ]

    const deleteCommonForm = (id) => {

        if (id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat.username,
                        pagename: "Customer Queries",
                        operation: "DELETE",
                        description: `Deleted Customer Query by the LoggedIn user ${userdat.username} on ${cusdateString}`,
                        datetime: cusdateString
                    };

                    Services
                        .deleteformcommon(id)
                        .then(() => Services.getformcommon())
                        .then((e) => {
                            // const btraveldat = e?.data.filter((ev) => ev?.formname === "Mannai Holiday");
                            setdata(e.data);
                            setpuredat(e.data);
                        })
                        .catch((err) => console.error("Error deleting Common Form Data", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };

    const deleteContactUsForm = (id) => {

        if (id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat.username,
                        pagename: "Customer Queries",
                        operation: "DELETE",
                        description: `Deleted Customer Query by the LoggedIn user ${userdat.username} on ${cusdateString}`,
                        datetime: cusdateString
                    };

                    Services
                        .deleteformcontactus(id)
                        .then(() => Services.getformcontactus())
                        .then((e) => {
                            // const btraveldat = e?.data.filter((ev) => ev?.formname === "Mannai Holiday");
                            setcontdat(e.data);
                            setpurecontdat(e.data);
                        })
                        .catch((err) => console.error("Error deleting ContactUs Form Data", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };


    const handleCommonSearch = (hes) => {
        const filSpec = puredat?.filter((pe) => pe?.formname === filval)
        const filtered = filSpec?.filter((es) => es?.fname.toLowerCase().includes(hes.toLowerCase()) || es?.lname.toLowerCase().includes(hes.toLowerCase()) || es?.companyname.toLowerCase().includes(hes.toLowerCase()) || es?.workemail.toLowerCase().includes(hes.toLowerCase()) || es?.phno.toLowerCase().includes(hes.toLowerCase()) || es?.country.toLowerCase().includes(hes.toLowerCase()) || es?.postalcode.toLowerCase().includes(hes.toLowerCase()) || es?.designation.toLowerCase().includes(hes.toLowerCase()) || es?.howdidyouhear.toLowerCase().includes(hes.toLowerCase()) || es?.noofusersrequired.toLowerCase().includes(hes.toLowerCase()));
        setdisdat(filtered);
    };

    const handleCommonSort = () => {
        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            // const sorted = data?.sort((a, b) => a?.fname < b?.fname ? 1 : -1);
            const sorted = disdat?.sort((a, b) => a?.fname < b?.fname ? 1 : -1);
            // setdata(sorted);
            setdisdat(sorted);
        } else {
            // const sorted = data?.sort((a, b) => a?.fname > b?.fname ? 1 : -1);
            const sorted = disdat?.sort((a, b) => a?.fname > b?.fname ? 1 : -1);
            // setdata(sorted);
            setdisdat(sorted);
        }
    };

    const handleContactUsSearch = (hes) => {
        const filtered = purecontdat?.filter((es) => es?.fname.toLowerCase().includes(hes.toLowerCase()) || es?.lname.toLowerCase().includes(hes.toLowerCase()) || es?.companyname.toLowerCase().includes(hes.toLowerCase()) || es?.jobtitle.toLowerCase().includes(hes.toLowerCase()) || es?.phno.toLowerCase().includes(hes.toLowerCase()) || es?.country.toLowerCase().includes(hes.toLowerCase()) || es?.businessemail.toLowerCase().includes(hes.toLowerCase()) || es?.postalcode.toLowerCase().includes(hes.toLowerCase()) || es?.annaultravelspend.toLowerCase().includes(hes.toLowerCase()) || es?.areaofinterest.toLowerCase().includes(hes.toLowerCase()));
        // setcontdat(filtered);
        setdisdat(filtered)
    };

    const handleContactUsSort = () => {

        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            // const sorted = contdat?.sort((a, b) => a?.fname > b?.fname ? 1 : -1);
            const sorted = disdat?.sort((a, b) => a?.fname > b?.fname ? 1 : -1);
            // setcontdat(sorted);
            setdisdat(sorted);
        } else {
            // const sorted = contdat?.sort((a, b) => a?.fname < b?.fname ? 1 : -1);
            const sorted = disdat?.sort((a, b) => a?.fname < b?.fname ? 1 : -1);
            // setcontdat(sorted);
            setdisdat(sorted);
        }
    };

    const handleDataFilter = (des) => {
        console.log("des val: ", des);

        setfilval(des);
        if (des === "Contact Us") {
            // setdisdat(contdat);
            setdisdat(purecontdat);
        } else {
            // const fil = data?.filter((ev) => ev?.formname === des);
            const fil = puredat?.filter((ev) => ev?.formname === des);
            console.log("ELSE DAT: ", fil);
            // setdata(fil);
            setdisdat(fil);
        }
    };


    return (
        <>
            {logincred?.length == 1 ?
                <Card >
                    <CardHeader
                        sx={{
                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                        }}
                        action={
                            <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={1}>
                                <Tooltip title="Search">
                                    <Input
                                        id="input-with-icon-adornment"
                                        placeholder='Search'
                                        onChange={(ev) => filval === "Contact Us" ? handleContactUsSearch(ev.target.value) : handleCommonSearch(ev.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchOutlinedIcon fontSize='small' color='action' />
                                            </InputAdornment>
                                        }
                                    />
                                </Tooltip>

                                <Tooltip title="Filter">
                                    <FormControl variant="standard">
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={filval}
                                            onChange={(ev) => handleDataFilter(ev.target.value)}
                                            label="Button Link"
                                            startAdornment={
                                            <InputAdornment position="start">
                                                <FilterAltOutlinedIcon fontSize='small' color='action' />
                                            </InputAdornment>
                                        }
                                        >
                                            {formfil?.map((ef) => (
                                                <MenuItem value={ef?.formval}>{ef?.formname}</MenuItem>
                                            ))}

                                        </Select>

                                    </FormControl>

                                </Tooltip>

                                <Tooltip title="Sort">
                                    <IconButton onClick={() => filval === "Contact Us" ? handleContactUsSort() : handleCommonSort()}>
                                        <Link>
                                            <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }

                        title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                            Customer Queries
                        </Typography>
                    />
                    {filval === "Contact Us" ?
                        <CardContent sx={{ py: 0 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Sl No</StyledTableCell>
                                            <StyledTableCell align="center">First Name</StyledTableCell>
                                            <StyledTableCell align="center">Last Name</StyledTableCell>
                                            <StyledTableCell align="center">Company Name</StyledTableCell>
                                            <StyledTableCell align="center">Job Title</StyledTableCell>
                                            <StyledTableCell align="center">Phone No</StyledTableCell>
                                            <StyledTableCell align="center">Country</StyledTableCell>
                                            <StyledTableCell align="center">Business Email</StyledTableCell>
                                            <StyledTableCell align="center">Postal Code</StyledTableCell>
                                            <StyledTableCell align="center">Annual Travel Spend</StyledTableCell>
                                            <StyledTableCell align="center">Area of Interest</StyledTableCell>
                                            <StyledTableCell align="center">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {disdat.map((e, i) => {
                                            return (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align='center' component="th" scope="row">{i + 1}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e.fname}</StyledTableCell>
                                                    <StyledTableCell align="left" component="th" scope="row">{e.lname}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e.companyname}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e.jobtitle}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e.phno}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e.country}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e.businessemail}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e.postalcode}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e.annaultravelspend}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e.areaofinterest}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">
                                                        <Stack justifyContent="center" direction="row" alignItems="center" spacing={-0.3}>
                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={() => deleteContactUsForm(e.id)}>
                                                                    <DeleteIcon color='error' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent> :
                        <CardContent sx={{ py: 0 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Sl No</StyledTableCell>
                                            <StyledTableCell align="center">First Name</StyledTableCell>
                                            <StyledTableCell align="center">Last Name</StyledTableCell>
                                            <StyledTableCell align="center">Company Name</StyledTableCell>
                                            <StyledTableCell align="center">Email</StyledTableCell>
                                            <StyledTableCell align="center">Phone No</StyledTableCell>
                                            <StyledTableCell align="center">Country</StyledTableCell>
                                            <StyledTableCell align="center">PostalCode</StyledTableCell>
                                            <StyledTableCell align="center">Designation</StyledTableCell>
                                            <StyledTableCell align="center">No. of users required</StyledTableCell>
                                            {/* <StyledTableCell align="center">Last Name</StyledTableCell> */}
                                            <StyledTableCell align="center">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {disdat.map((e, i) => {
                                            return (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align='center' component="th" scope="row">{i + 1}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e.fname}</StyledTableCell>
                                                    <StyledTableCell align="left" component="th" scope="row">{e.lname}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e.companyname}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e.workemail}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e.phno}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e.country}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e.postalcode}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e.designation}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e.noofusersrequired}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">
                                                        <Stack justifyContent="center" direction="row" alignItems="center" spacing={-0.3}>
                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={() => deleteCommonForm(e.id)}>
                                                                    <DeleteIcon color='error' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    }

                </Card> : <></>}

        </>
    )
}

export default CustomerQueries;