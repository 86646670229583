import { DOMAIN, PORT, SCHEME } from 'app/constants/constants';
import axios from 'axios';

class Services {

    getmenus() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmenus`);
    };

    getmenubyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmenu/${id}`)
    }

    addmenus(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addmenus`, dat);
    };

    updatemenus(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatemenus`, dat);
    };

    deletemenus(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletemenu/${id}`);
    };

    //SubMenus

    getsubmenus() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getsubmenus`);
    };

    addsubmenus(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addsubmenus`, dat);
    };

    deletesubmenu(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletesubmenu/${id}`);
    };

    updatesubmenus(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatesubmenus`, dat)
    };

    //hyperlinks for link dropdown
    gethyperlinks() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/gethyperlinks`);
    };

    //Image Uploading

    uploadImage(formdata) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addimage`, formdata);
    };

    //Image Retrieving
    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
    };

};


export default new Services();