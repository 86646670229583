
//SCHEME without SSL certification
// export const SCHEME = "http://";
export const SCHEME = "https://";
//SCHEME with SSL certification
// export const SCHEME = "https://"

//DOMAIN & PORT for localhost
// export const DOMAIN = "localhost"
// export const PORT = ":8082"  

//DOMAIN & PORT for REMOTE_SERVER
// export const DOMAIN = "103.133.214.220";
export const DOMAIN = "cmservice.mannaitravel.com";
// export const PORT = ":5025";
export const PORT = "";


