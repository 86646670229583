import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import moment from 'moment';
import LogServices from 'app/pages/LogServices/LogServices';
import PhoneInput from 'react-phone-number-input'
import SuperAdminServices from '../SuperAdminServices';
import "./UserFormStyles.css"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const UsersForm = () => {

    let { state } = useLocation();

    let a;
    let cond = [];
    let checkmap = [];

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const [formdata, setformdata] = useState([{ id: '', username: '', password: '', emailid: '', phno: '', fname: '', lname: '', desig: '' }]);
    // const [menumastdat, setmenumastdat] = useState([{ id: '', menumasterid: '' }]);
    const [menumastdat, setmenumastdat] = useState([]);
    const [maproldat, setmaproldat] = useState([]);
    const [puremaproldat, setpuremaproldat] = useState([]);

    const [getmenus, setgetmenus] = useState([]);
    const [getroles, setgetroles] = useState([]);

    const [checkeddat, setcheckeddat] = useState([]);
    const [togglecheckerr, settogglecheckerr] = useState(false);
    const [formerr, setformerr] = useState([]);

    const [logincred, setlogincred] = useState();
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format("llll");

    useEffect(() => {
        const ef = userdat?.rolename.includes("SUPER_ADMIN") ? true : false;

        setlogincred(ef);

        if (ef) {
            console.log("If part");
        } else {
            console.log("Else part");
            Swal.fire({
                icon: 'error',
                title: 'UnAuthorized Access',
                text: 'Please Login to continue',
            }).then(() => navigate('/'))
        }
    }, [])

    useEffect(() => {
        SuperAdminServices
            .getallroles()
            .then((e) => {
                // setgetmenus(e.data);
                setgetroles(e.data);

                if (state) {
                    console.log("STATRE: ", state);

                    setformdata([{ id: state?.id, username: state?.username, password: state?.password, emailid: state?.emailid, phno: state?.phno, fname: state?.firstname, lname: state?.lastname, desig: state?.designation}])

                    e.data.length > 0 && e.data?.map((eg, index) => {
                        a = state?.maproles.filter((ko) => eg.id === ko.id ? ko?.id : "");
                        {/* cond = a.length > 0 ? true : false; */ }
                        console.log("A: ", a);
                        cond.push(a.length > 0 ? true : false);
                        checkmap.push(a.length > 0 ? { id: a[0]?.mapuserid, roleid: a[0]?.id } : { id: '', roleid: '' })

                    });

                    console.log("Checkmen: ", checkmap);

                    console.log("AS: ", a);
                    console.log("COND: ", cond)
                    // setcheckeddat(cond);
                } else {
                    console.log("ELSE PATY");
                    e.data.length > 0 && e.data.map((eg, index) => {
                        cond.push(false);
                        checkmap.push({ id: '', roleid: '' });
                    });

                    // setmenumastdat(checkmen);
                }
                setcheckeddat(cond);
                // setmenumastdat(checkmen);
                setmaproldat(checkmap);
                setpuremaproldat(checkmap);
            })
            .catch((err) => console.error("Error While fetching menus: ", err));
    }, [])


    // useEffect(() => {
    //     if (state) {
    //         console.log("STATRE: ", state);

    //         getmenus.length > 0 && getmenus?.map((eg, index) => {
    //             a = state?.mapmenus.filter((ko) => eg.id === ko.id ? ko?.id : "");
    //             {/* cond = a.length > 0 ? true : false; */ }
    //             cond.push(a.length > 0 ? true : false);
    //         });

    //             console.log("AS: ", a);
    //             console.log("COND: ", cond)
    //             setcheckeddat(cond);
    //             // setcheckeddat(cond);
    //         // setformdata([{ id: state.id, rolename: state.rolename, description: state.description }]);
    //     }
    // }, []);

    const handleformfieldchange = (field, value) => {
        const newfield = [...formdata];
        // if(newfield[0])
        const regex = /^\S*$/;
        if (field.includes("username") || field.includes("password") || field.includes("emailid")) {
            if (regex.test(value)) {
                newfield[0][field] = value;
                setformdata(newfield);
            }
        } else {
            newfield[0][field] = value;
            // console.log("NEWFIEL: ", field);
            setformdata(newfield);
        }
        validation();
    };

    const handlerolescheckboxchange = (index, id, value) => {
        console.log("INdex: ", index, "\nid: ", id, "\nvalue: ", value);
        // console.log("menumast: ", menumastdat);
        console.log("MAPROLDS: ", maproldat);
        if (value === true) {
            // if (state) {
            // maproldat.
            const newf = puremaproldat?.map((ev) => {
                if (ev?.roleid !== id) {
                    return { id: '', roleid: '' };
                }
                return ev;
            });
            console.log("newf: ", newf);
            const newfields = [...newf];
            // const newfields = [...maproldat];
            checkeddat.fill(false);
            // setcheckeddat((prev) => prev.fill(false));
            const chek = [...checkeddat];
            chek[index] = true;
            newfields[index]["roleid"] = id;
            // setmenumastdat(newfields);
            setmaproldat(newfields);
            setcheckeddat(chek);
            // } else {
            //     console.log("TRUE EXE");
            //     const newfields = [...menumastdat, { id: index + 1, menumasterid: id }];
            //     const chek = [...checkeddat, true]
            //     // newfields[index]["menumasterid"] = id;
            //     console.log("NEWFI: ", newfields);
            //     console.log("CHEL: ", chek);
            //     setmenumastdat(newfields);
            //     setcheckeddat(chek);
            // }
        } else {
            console.log("FALSE RXE");
            const updatedfields = [...maproldat];
            console.log("MAPRLDSAT: ", maproldat);
            const chek = [...checkeddat];
            chek[index] = false;
            // updatedfields.splice(index, 1);
            updatedfields[index]["roleid"] = '';
            console.log("ODAT: ", updatedfields);
            console.log("CHEK: ", chek);
            setcheckeddat(chek);
            // setmenumastdat(updatedfields);
            setmaproldat(updatedfields);
        }
        console.log("CHECDSY: ", checkeddat);
        console.log("MAPROLSDDSAT: ", maproldat);
        // validation();
    };

    const validation = () => {

        const formerror = formdata.map((e) => {
            const usernameerr = !e.username.trim();
            const passworderr = !e.password.trim();
            const emailiderr = !e.emailid.trim();
            const fnameerr = !e.fname.trim();
            const lnameerr = !e.lname.trim();
            const desigerr = !e.desig.trim();

            return { username: usernameerr, password: passworderr, emailid: emailiderr, fname: fnameerr, lname: lnameerr, desig: desigerr};
        });

        setformerr(formerror);

        // console.log("VSL CJDL: ", checkeddat[1]);

        const checkerrboo = checkeddat.some((e) => {
            return !!e;
        })

        console.log("CHEXKERRBO: ", checkerrboo);
        settogglecheckerr(!checkerrboo);

        // const checkboxerr = menumastdat.length > 0 ? true : false;
        // console.log("CHECKbox err: ", checkboxerr);

        const formerrboo = formdata.every((e) => {
            return e.emailid.trim() && e.password.trim() && e.username.trim();
        });

        return formerrboo && checkerrboo;
    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating Users Successful!',
            }).then(() => navigate('/users'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding Users Successful!',
            }).then(() => navigate('/Users'))
        }
    }

    const onSubmit = () => {

        if (validation()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: formdata[0]?.id,
                    firstname: formdata[0]?.fname,
                    lastname: formdata[0]?.lname,
                    designation: formdata[0]?.desig,
                    username: formdata[0]?.username,
                    password: formdata[0]?.password,
                    emailid: formdata[0]?.emailid,
                    phno: formdata[0]?.phno,
                    mapuserid: maproldat
                };

                const logdat = {
                    loggeduser: userdat.username,
                    pagename: "Users",
                    operation: "UPDATE",
                    description: `Updated User with Username: ${formdata[0]?.username} by the LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                };

                SuperAdminServices
                    .updatecmsuser(dat)
                    .catch((err) => console.error("error updating users", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error adding Logs", err));

            } else {
                const dat = {
                    firstname: formdata[0]?.fname,
                    lastname: formdata[0]?.lname,
                    designation: formdata[0]?.desig,
                    username: formdata[0]?.username,
                    password: formdata[0]?.password,
                    emailid: formdata[0]?.emailid,
                    phno: formdata[0]?.phno,
                    mapuserid: maproldat
                };

                const logdat = {
                    loggeduser: userdat?.username,
                    pagename: "Users",
                    operation: "ADD",
                    description: `Added User by LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                }

                SuperAdminServices
                    .addcmsusers(dat)
                    .catch((err) => console.error("error adding users", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs: ", err));
            }
            setformdata([{ id: '', username: '', password: '', emailid: '', phno: '', fname: '', lname: '', desig: '' }]);
        }

    }

    return (
        <>
            {logincred ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/users'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    Users Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">Users Form</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                Users Form
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >
                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >

                                    <TextField
                                        error={formerr[0]?.username}
                                        helperText={formerr[0]?.username && 'required'}
                                        id="standard-error-helper-text"
                                        label="Username"
                                        value={formdata[0]?.username}
                                        onChange={(ev) => handleformfieldchange('username', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.password}
                                        helperText={formerr[0]?.password && 'required'}
                                        id="standard-error-helper-text"
                                        label="Password"
                                        // type='password'
                                        value={formdata[0]?.password}
                                        onChange={(ev) => handleformfieldchange('password', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.emailid}
                                        helperText={formerr[0]?.emailid && 'required'}
                                        id="standard-error-helper-text"
                                        label="Email ID"
                                        type='email'
                                        value={formdata[0]?.emailid}
                                        onChange={(ev) => handleformfieldchange('emailid', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1.2 }}
                                    />

                                    <div className="MuiFormControl-root MuiTextField-root css-1vs1jgp-MuiFormControl-root-MuiTextField-root">
                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard css-cys4tf-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true" for="standard-error-helper-text" id="standard-error-helper-text-label">Contact Number</label>

                                        <PhoneInput
                                            name="contact"
                                            className={`outline-none contactnum`}
                                            placeholder="Enter phone number"
                                            value={formdata[0]?.phno}
                                            onChange={(ev) => handleformfieldchange('phno', ev)}
                                            // placeholder="CONTACT NUMBER"
                                            defaultCountry="IN"
                                            withCountryCallingCode={true}
                                            international={true}

                                        />
                                        {/* <div class="PhoneInputCountrySelectArrow"></div> */}
                                    </div>

                                </Box>

                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >

                                    <TextField
                                        error={formerr[0]?.fname}
                                        helperText={formerr[0]?.fname && 'required'}
                                        id="standard-error-helper-text"
                                        label="First Name"
                                        value={formdata[0]?.fname}
                                        onChange={(ev) => handleformfieldchange('fname', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.lname}
                                        helperText={formerr[0]?.lname && 'required'}
                                        id="standard-error-helper-text"
                                        label="Last Name"
                                        // type='password'
                                        value={formdata[0]?.lname}
                                        onChange={(ev) => handleformfieldchange('lname', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.desig}
                                        helperText={formerr[0]?.desig && 'required'}
                                        id="standard-error-helper-text"
                                        label="Designation"
                                        type='email'
                                        value={formdata[0]?.desig}
                                        onChange={(ev) => handleformfieldchange('desig', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1.2 }}
                                    />

                                </Box>

                                {/* <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                                    <FormControlLabel required control={<Checkbox />} label="Required" />
                                    <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
                                </FormGroup> */}

                                <Box
                                    component={'div'}
                                    display='flex'
                                    alignItems={'center'}
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 2 }}
                                    sx={{ mb: 1 }}
                                >
                                    <Typography sx={{}} color={'GrayText'} align='left' level="body-sm">{`Roles`}</Typography>
                                    {togglecheckerr ? <Typography sx={{ fontSize: 12 }} color={'red'} align='left' level="body-sm">{`*Please Select atleast one role`}</Typography> : ''}
                                </Box>
                                {/* <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >
                                    {console.log("GET: ", getmenus)}
                                    {getmenus.length > 0 && getmenus?.map((eg, index) => {

                                        return (
                                            <>
                                                {console.log("INSIDE: ", checkeddat[index])}
                                                <FormControlLabel key={index} control={<Checkbox onChange={(gg) => handlemenuscheckboxchange(index, eg.id, gg.target.checked)} checked={!!checkeddat[index]} />} label={eg.menuname} />
                                            </>
                                        )
                                    })}
                                </Box> */}

                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {getroles.length > 0 && getroles?.map((eg, index) => {

                                            return (
                                                <Grid item xs={12} sm={4} md={3} key={index}>
                                                    {console.log("INSIDE: ", checkeddat[index])}
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(gg) => handlerolescheckboxchange(index, eg.id, gg.target.checked)} checked={!!checkeddat[index]} />}
                                                        label={eg.rolename} />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Box>

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                                    sx={{ mt: 2 }}
                                >
                                    <Button variant="contained" onClick={() => onSubmit()}>
                                        Save
                                    </Button>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>

                </Box> : <></>}

        </>
    )
}

export default UsersForm;