import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class Services {
    getsubscribers() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getsubscribers`);
    };

    addsubscribers(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addsubscribers`, dat);
    };

    updatesubscribers(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatesubscribers`, dat);
    };

    deletesubscribers(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletesubscriber/${id}`);
    };
};

export default new Services();