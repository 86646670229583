import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class LogServices {

    addlogs(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addlogs`, dat);
    };

    getlogs() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getlogs`);
    };

}

export default new LogServices();