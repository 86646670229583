import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Breadcrumbs, Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import Services from './Services';
import moment from 'moment';
import LogServices from '../LogServices/LogServices';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const BlogSections = () => {

    const [Adata, setAdata] = useState([]);
    const [Bdata, setBdata] = useState([]);
    const [blogid, setblogid] = useState('')
    let { state } = useLocation();

    const [Apuredat, setApuredat] = useState([]);
    const [Bpuredat, setBpuredat] = useState([]);
    const [sorttoggleA, setsorttoggleA] = useState(true);
    const [sorttoggleB, setsorttoggleB] = useState(true);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format('llll');

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Blogs"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        setblogid(state.id);
        setAdata(state.attractions);
        setApuredat(state?.attractions);
        setBdata(state.blogSubSections);
        setBpuredat(state?.blogSubSections);
    }, []);

    const deleteattractions = (e) => {
        if (e.id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat?.username,
                        pagename: "Blogs > Blog Sections",
                        operation: "DELETE",
                        description: `Deleted Attraction with Title: ${e.title} by the LoggedIn User ${userdat?.username} on ${cusDateString}`,
                        datetime: cusDateString
                    }

                    Services
                        .deleteattraction(e.id)
                        .then(() => Services.getBlogById(blogid))
                        .then((res) => {
                            setAdata(res.data.attractions);
                            setApuredat(res?.data?.attractions);
                        })
                        .catch((err) => console.error("Error deleting attraction: ", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };

    const deleteblogsubsections = (e) => {
        if (e.id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat?.username,
                        pagename: "Blogs > Blog Sections",
                        operation: "DELETE",
                        description: `Deleted BlogSubSection with Title: ${e.title} by the LoggedIn User ${userdat?.username} on ${cusDateString}`,
                        datetime: cusDateString
                    }

                    Services
                        .deleteblogsubsection(e.id)
                        .then(() => Services.getBlogById(blogid))
                        .then((res) => {
                            setBdata(res.data.blogSubSections);
                            setBpuredat(res?.data?.blogSubSections);
                        })
                        .catch((err) => console.error("Error deleting BlogSubSection: ", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };

    const handleAttractionSearch = (laid) => {
        const filtered = Apuredat?.filter((es) => es?.title.toLowerCase().includes(laid.toLowerCase()) || es?.description.toLowerCase().includes(laid.toLowerCase()));
        setAdata(filtered);
    }

    const handleAttractionSort = () => {
        setsorttoggleA((prev) => !prev);

        if (sorttoggleA) {
            const sorted = Adata?.sort((a, b) => a?.description > b?.description ? 1 : -1);
            setAdata(sorted);
        } else {
            const sorted = Adata?.sort((a, b) => a?.description < b?.description ? 1 : -1);
            setAdata(sorted);
        }
    }

    const handleBlogSubSectionSearch = (maid) => {
        const filtered = Bpuredat?.filter((es) => es?.title.toLowerCase().includes(maid.toLowerCase()) || es?.imageheader.toLowerCase().includes(maid.toLowerCase()));
        setBdata(filtered);
    }

    const handleBlogSubSectionSort = () => {
        setsorttoggleB((prev) => !prev);
        if (sorttoggleB) {
            const sorted = Bdata?.sort((a, b) => a?.title > b?.title ? 1 : -1);
            setBdata(sorted);
        } else {
            const sorted = Bdata?.sort((a, b) => a?.title < b?.title ? 1 : -1);
            setBdata(sorted);
        }
    }


    return (
        <>
            {logincred?.length == 1 ?
                <>
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/blog'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    Blog Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">Blog Sections</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card sx={{ mb: 1 }}>
                        <CardHeader
                            sx={{
                                '& .MuiCardHeader-action': { alignSelf: 'center' },
                            }}

                            action={
                                <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>

                                    <Tooltip title="Search Attractions">
                                        <Input
                                            id="input-with-icon-adornment"
                                            placeholder='Search'
                                            onChange={(ev) => handleAttractionSearch(ev.target.value)}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchOutlinedIcon fontSize='small' color='action' />
                                                </InputAdornment>
                                            }
                                        />
                                    </Tooltip>

                                    <Tooltip title="Sort Attractions">
                                        <IconButton onClick={() => handleAttractionSort()}>
                                            <Link>
                                                <SwapVertOutlinedIcon fontSize='small' color={sorttoggleA ? "action" : "secondary"} />
                                            </Link>
                                        </IconButton>
                                    </Tooltip>

                                </Stack>
                            }

                            title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                                Attractions
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Sl No</StyledTableCell>
                                            <StyledTableCell align="center">Title</StyledTableCell>
                                            {/* <StyledTableCell align="center">Image Path</StyledTableCell> */}
                                            <StyledTableCell align="center">Description</StyledTableCell>
                                            <StyledTableCell align="center">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Adata.map((e, i) => {

                                            return (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align='center' component="th" scope="row">{i + 1}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e.title}</StyledTableCell>
                                                    {/* <StyledTableCell align='center' component="th" scope="row">{e.imagePath}</StyledTableCell> */}
                                                    <StyledTableCell align='center' component="th" scope="row">{e.description}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">
                                                        <Stack justifyContent={'center'} direction="row" alignItems="center" spacing={-0.3}>

                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={() => deleteattractions(e)}>
                                                                    <DeleteIcon color='error' />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </Stack>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>



                    <Card >
                        <CardHeader
                            sx={{
                                '& .MuiCardHeader-action': { alignSelf: 'center' },
                            }}

                            action={
                                <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                    <Tooltip title="Search BlogSubSections">
                                        <Input
                                            id="input-with-icon-adornment"
                                            placeholder='Search'
                                            onChange={(ev) => handleBlogSubSectionSearch(ev.target.value)}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchOutlinedIcon fontSize='small' color='action' />
                                                </InputAdornment>
                                            }
                                        />
                                    </Tooltip>

                                    <Tooltip title='Sort BlogSubSections'>
                                        <IconButton onClick={() => handleBlogSubSectionSort()}>
                                            <Link>
                                                <SwapVertOutlinedIcon fontSize='small' color={sorttoggleB ? "action" : "secondary"} />
                                            </Link>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            }

                            title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                                BlogSubSections
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Sl No</StyledTableCell>
                                            <StyledTableCell align="center">Title</StyledTableCell>
                                            <StyledTableCell align="center">Description</StyledTableCell>
                                            {/* <StyledTableCell align="center">Image Path</StyledTableCell> */}
                                            <StyledTableCell align="left">Image Header</StyledTableCell>
                                            <StyledTableCell align="left">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Bdata.map((e, i) => {

                                            return (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align='left' component="th" scope="row">{i + 1}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e.title}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e.description}</StyledTableCell>
                                                    {/* <StyledTableCell align='center' component="th" scope="row">{e.imagePath}</StyledTableCell> */}
                                                    <StyledTableCell align='left' component="th" scope="row">{e.imageheader}</StyledTableCell>
                                                    <StyledTableCell align="left" component="th" scope="row">
                                                        <Stack justifyContent={'flex-start'} direction="row" alignItems="center" spacing={-0.3}>

                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={() => deleteblogsubsections(e)}>
                                                                    <DeleteIcon color='error' />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </Stack>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </> : <></>}

        </>
    )
}

export default BlogSections