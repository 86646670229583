import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import moment from 'moment';
import LogServices from 'app/pages/LogServices/LogServices';
import SuperAdminServices from '../SuperAdminServices';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MasterMenus = () => {
    const [data, setdata] = useState([]);
    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState();
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format("llll");

    useEffect(() => {
        const ef = userdat?.rolename.includes("SUPER_ADMIN") ? true : false;
    
        setlogincred(ef);
    
        if (ef) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        SuperAdminServices
            .getallmenumasters()
            .then((e) => {
                setdata(e.data);
                setpuredat(e.data);
            })
            .catch((err) => console.error("Error fetching MasterMenus", err));
    }, []);

    const deletemastermenu = (e) => {

        if (e.id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat.username,
                        pagename: "Screens",
                        operation: "DELETE",
                        description: `Deleted Screen with ScreenName: ${e.menuname} by LoggedIn User ${userdat.username} on ${cusDateString}`,
                        datetime: cusDateString
                    };

                    SuperAdminServices
                        .deletemenumaster(e.id)
                        .then(() => SuperAdminServices.getallmenumasters())
                        .then((e) => {
                            setdata(e.data);
                            setpuredat(e.data);
                        })
                        .catch((err) => console.error("Error deleting Screen", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };


    const handleSearch = (hes) => {
        const filtered = puredat?.filter((es) => es?.menuname.toLowerCase().includes(hes.toLowerCase()) || es?.description.toLowerCase().includes(hes.toLowerCase()));
        setdata(filtered);
    };

    const handleSort = () => {

        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            const sorted = data?.sort((a, b) => a?.menuname < b?.menuname ? 1 : -1);
            setdata(sorted);
        } else {
            const sorted = data?.sort((a, b) => a?.menuname > b?.menuname ? 1 : -1);
            setdata(sorted);
        }
    };


    return (
        <>
            {logincred ?
                <Card >
                    <CardHeader
                        sx={{
                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                        }}
                        action={
                            <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                <Tooltip title="Search MasterMenus">
                                    <Input
                                        id="input-with-icon-adornment"
                                        placeholder='Search'
                                        onChange={(ev) => handleSearch(ev.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchOutlinedIcon fontSize='small' color='action' />
                                            </InputAdornment>
                                        }
                                    />
                                </Tooltip>

                                <Tooltip title="Sort MasterMenus">
                                    <IconButton onClick={() => handleSort()}>
                                        <Link>
                                            <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                        </Link>
                                    </IconButton>
                                </Tooltip>

                                {/* <Tooltip title="Add MasterMenus">
                                    <IconButton >
                                        <Link to="/mastermenusform">
                                            <AddOutlinedIcon fontSize='medium' color='action' />
                                        </Link>
                                    </IconButton>
                                </Tooltip> */}
                            </Stack>
                        }

                        title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                            Screens
                        </Typography>
                    />

                    <CardContent sx={{ py: 0 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Sl No</StyledTableCell>
                                        <StyledTableCell align="left">ScreenName</StyledTableCell>
                                        <StyledTableCell align="center">Description</StyledTableCell>
                                        <StyledTableCell align="center">Created Date</StyledTableCell>
                                        {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((e, i) => {
                                        return (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align='left' component="th" scope="row">{i + 1}</StyledTableCell>
                                                <StyledTableCell align='left' component="th" scope="row">{e?.menuname}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">{e?.description}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">{moment(e?.createdDate).format("DD-MM-YYYY")}</StyledTableCell>
                                                {/* <StyledTableCell align="center" component="th" scope="row">
                                                    <Stack justifyContent="center" direction="row" alignItems="center" spacing={-0.3}>
                                                        <Tooltip title="Modify">
                                                            <Link to='/mastermenusform' state={e}>
                                                                <IconButton>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>

                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => deletemastermenu(e)}>
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </StyledTableCell> */}
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>

                </Card> : <></>}

        </>
    )
}

export default MasterMenus;