import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Services from './Services';

const IntroBannerForm = () => {

    const { state } = useLocation();

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const [formdata, setformdata] = useState([{ id: '', bannername: '', heading: '', maindescription: '', subdescription: '', buttonlink: '' }]);
    const [formerr, setformerr] = useState([]);

    useEffect(() => {
        if (state) {
            setformdata([{ id: state.id, bannername: state.bannername, heading: state.heading, maindescription: state.maindescription, subdescription: state.subdescription, buttonlink: state.buttonlink }]);
        }
    }, [])


    const handleformfieldchange = (field, value) => {
        const newfield = [...formdata];
        newfield[0][field] = value;
        setformdata(newfield);
        validate();
    };

    const validate = () => {

        const formerrors = formdata.map((e) => {
            const bannernameerr = !e.bannername.trim();
            const headingerr = !e.heading.trim();
            const maindescriptionerr = !e.maindescription.trim();
            const subdescriptionerr = !e.subdescription.trim();
            const buttonlinkerr = !e.buttonlink.trim();

            return { bannername: bannernameerr, heading: headingerr, maindescription: maindescriptionerr, subdescription: subdescriptionerr, buttonlink: buttonlinkerr };
        });

        setformerr(formerrors);

        const formerrboo = formdata.every((e) => {
            return e.bannername.trim() && e.heading.trim() && e.maindescription.trim() && e.subdescription.trim() && e.buttonlink.trim();
        });

        return formerrboo;
    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating Banners Successful!',
            }).then(() => navigate('/banners'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding Banners Successful!',
            }).then(() => navigate('/banners'))
        }
    }

    const onSubmit = () => {
        if (validate()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: formdata[0]?.id,
                    bannername: formdata[0]?.bannername,
                    heading: formdata[0]?.heading,
                    maindescription: formdata[0]?.maindescription,
                    subdescription: formdata[0]?.subdescription,
                    buttonlink: formdata[0]?.buttonlink
                };

                Services
                    .updateintrobanners(dat)
                    .catch((err) => console.error("error updating banners :", err));

            } else {
                const dat = {
                    bannername: formdata[0]?.bannername,
                    heading: formdata[0]?.heading,
                    maindescription: formdata[0]?.maindescription,
                    subdescription: formdata[0]?.subdescription,
                    buttonlink: formdata[0]?.buttonlink
                };

                Services
                    .addintrobanners(dat)
                    .catch((err) => console.error("error adding banners", err));

            }

            setformdata([{ id: '', bannername: '', heading: '', maindescription: '', subdescription: '', buttonlink: '' }]);
        }
    };

    return (
        <Box
            component={'form'}
        >
            <Box
                component={'div'}
                sx={{ mb: 2 }}
            >
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/banners'>
                        <Typography fontSize={'.875rem'} color="text.primary">
                            Banners Table
                        </Typography>
                    </Link>
                    <Typography fontSize={'.875rem'} color="text.primary">Banners Form</Typography>
                </Breadcrumbs>

            </Box>

            <Card >
                <CardHeader
                    title=<Typography fontSize={'1.7rem'} color="text.primary">
                        Banners Form
                    </Typography>
                />

                <CardContent sx={{ py: 0 }}>
                    <Box
                        component={'section'}
                    >
                        <Box
                            component={'div'}
                            display='flex'
                            flexDirection={{ xs: 'column', lg: 'row' }}
                            gap={{ lg: 3 }}
                            sx={{ mb: 1 }}
                        >

                            <TextField
                                error={formerr[0]?.bannername}
                                helperText={formerr[0]?.bannername && 'required'}
                                id="standard-error-helper-text"
                                label="Banner Name"
                                value={formdata[0]?.bannername}
                                onChange={(ev) => handleformfieldchange('bannername', ev.target.value)}
                                variant="standard"
                                sx={{ flex: 1 }}
                            />

                            <TextField
                                error={formerr[0]?.heading}
                                helperText={formerr[0]?.heading && 'required'}
                                id="standard-error-helper-text"
                                label="Heading"
                                value={formdata[0]?.heading}
                                onChange={(ev) => handleformfieldchange('heading', ev.target.value)}
                                variant="standard"
                                sx={{ flex: 1 }}
                            />

                            <TextField
                                error={formerr[0]?.buttonlink}
                                helperText={formerr[0]?.buttonlink && 'required'}
                                id="standard-error-helper-text"
                                label="Button Link"
                                value={formdata[0]?.buttonlink}
                                onChange={(ev) => handleformfieldchange('buttonlink', ev.target.value)}
                                variant="standard"
                                sx={{ flex: 2 }}
                            />

                        </Box>

                        <Box
                            component={"div"}
                            sx={{
                                '& .MuiTextField-root': { width: '100%', mb: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >

                            <TextField
                                error={formerr[0]?.maindescription}
                                id="standard-error-helper-text"
                                label="Main Description"
                                multiline
                                value={formdata[0]?.maindescription}
                                onChange={(ev) => handleformfieldchange("maindescription", ev.target.value)}
                                helperText={formerr[0]?.maindescription && "required"}
                                variant="standard"
                            />

                        </Box>

                        <Box
                            component={"div"}
                            sx={{
                                '& .MuiTextField-root': { width: '100%', mb: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >

                            <TextField
                                error={formerr[0]?.subdescription}
                                id="standard-error-helper-text"
                                label="Sub Description"
                                multiline
                                value={formdata[0]?.subdescription}
                                onChange={(ev) => handleformfieldchange("subdescription", ev.target.value)}
                                helperText={formerr[0]?.subdescription && "required"}
                                variant="standard"
                            />

                        </Box>

                        <Box
                            component={'div'}
                            display={'flex'}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}
                            sx={{ mt: 2 }}
                        >
                            <Button variant="contained" onClick={() => onSubmit()}>
                                Save
                            </Button>
                        </Box>

                    </Box>
                </CardContent>
            </Card>

        </Box>
    )
}

export default IntroBannerForm