import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Services from './Services';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LogServices from '../LogServices/LogServices';
import moment from 'moment';
// import Services from './Services';

const MenuForm = () => {

    let { state } = useLocation();
    // let addcount = 0;
    // let delcount = 0;

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const date = new Date();
    const cusdateString = moment(date).format('llll');
    const datestring = moment(date).format('YYYY-MM-DD');

    const [addcount, setaddcount] = useState(1);
    const [delcount, setdelcount] = useState(1);

    const [submenudel, setsubmenudel] = useState(null);
    const [submenuadd, setsubmenuadd] = useState(null);

    const [menuform, setmenuform] = useState([{ id: '', name: '', link: '', title: '', keywords: '', description: '', canonical: '', sitename: '', imagePath: '' }]);
    const [menuformerr, setmenuformerr] = useState([]);

    const [seomenuimg, setseomenuimg] = useState(null);
    const [seomenuimgerr, setseomenuimgerr] = useState(null);
    const [seomenuimgtext, setseomenuimgtext] = useState(null);

    const [submenutoggle, setsubmenutoggle] = useState(false);
    const [submenusform, setsubmenusform] = useState([{ id: '', name: '', link: '', title: '', keywords: '', description: '', canonical: '', sitename: '', imagePath: '' }]);
    const [submenusformerr, setsubmenusformerr] = useState([]);
    const [linkdrop, setlinkdrop] = useState([]);

    const [seosubmenuimg, setseosubmenuimg] = useState([]);
    const [seosubmenuimgerr, setseosubmenuimgerr] = useState([]);
    const [seosubmenuimgcount, setseosubmenuimgcount] = useState(1);
    const [seosubmenuimgtext, setseosubmenuimgtext] = useState(null);

    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Menus"));
        setlogincred(ef);

        if (ef?.length == 1) {
            console.log("If part");
        } else {
            console.log("Else part");
            Swal.fire({
                icon: 'error',
                title: 'UnAuthorized Access',
                text: 'Please Login to continue',
            }).then(() => navigate('/'))
        }
    }, [])


    useEffect(() => {

        Services
            .gethyperlinks()
            .then((ev) => setlinkdrop(ev?.data))
            .catch((err) => console.error("Error while fetching HyperLinks: ", err));

    }, []);


    useEffect(() => {
        if (state) {
            const fetchData = async () => {

                const [tablename, date, filename] = state?.imagePath.split('/');

                setmenuform([{ id: state.id, name: state.name, link: state.link, title: state.title, keywords: state.keywords, description: state.description, canonical: state?.canonical, imagePath: state?.imagePath, sitename: state?.sitename }]);

                Services
                    .getImage(tablename, date, filename)
                    .then((res) => setseomenuimg(res.data))
                    .catch((err) => console.error("Error fetching SEOMenuImage: ", err));

                setsubmenutoggle(true);

                setsubmenusform(state.submenus.map((e) => {
                    return ({
                        id: e.id,
                        name: e.name,
                        link: e.link,
                        imagePath: e?.imagePath,
                        title: e.title,
                        keywords: e.keywords,
                        description: e.description,
                        sitename: e?.sitename,
                        canonical: e?.canonical
                    })
                }));

                const seosubmenuImagePromises = state?.submenus?.map(async (e) => {
                    const [tabname, da, fil] = e?.imagePath.split('/');

                    try {
                        const res = await Services.getImage(tabname, da, fil);
                        return res.data;
                    } catch (err) {
                        console.error("Error fetching SEOSubMenuImages: ", err);
                        return null;
                    }
                });

                const seosubImages = await Promise.all(seosubmenuImagePromises);
                setseosubmenuimg(seosubImages);
            };

            fetchData();
        }
    }, [])



    // useEffect(() => {
    //     if (state) {
    //         setmenuform([{ id: state.id, name: state.name, link: state.link, title: state.title, keywords: state.keywords, description: state.description }]);
    //         if (state.submenus[0]?.id) {
    //             setsubmenutoggle(true);
    //             setsubmenusform(state.submenus.map((e) => {
    //                 return ({
    //                     id: e.id,
    //                     name: e.name,
    //                     link: e.link,
    //                     title: e.title,
    //                     keywords: e.keywords,
    //                     description: e.description
    //                 })
    //             }));
    //         }
    //     }
    // }, [])


    const handlemenufieldchange = (field, value) => {
        const newfields = [...menuform];
        newfields[0][field] = value;
        setmenuform(newfields);
        validation();
    }

    const handleSEOMenuImageChange = (e) => {
        const file = e.target.files[0];
        setseomenuimgerr('');

        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            setseomenuimgtext("Replaced Meta Tag Image for Menu");
            setseomenuimg(file);
            handlemenufieldchange('imagePath', `SEOMenuImages/${datestring}/${file.name}`);
        } else {
            setseomenuimgerr("Invalid file type. Please chooese a JPEG or PNG Image.");
        }
    };

    const addSubMenuFields = () => {
        setsubmenusform([...submenusform, { id: '', name: '', link: '', title: '', keywords: '', description: '', canonical: '', imagePath: '', sitename: '' }])
        setaddcount((prev) => prev + 1);
        setsubmenuadd(`Added ${addcount} Submenus`);
    };

    const handleSEOSubMenuImageChange = (i, event) => {
        const file = event.target.files[0];
        setseosubmenuimgerr([]);

        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            const newimg = [...seosubmenuimg];
            setseosubmenuimgcount((prev) => prev + 1);
            setseosubmenuimgtext(`Replaced ${seosubmenuimgcount} SubMenu Meta Tag Images`);
            newimg[i] = file;
            setseosubmenuimg(newimg);
            handlesubmenufieldchange(i, 'imagePath', `SEOSubMenuImages/${datestring}/${file.name}`);
        } else {
            const newerr = [...seosubmenuimgerr];
            newerr[i] = 'Invalid file type. Please choose a JPEG or PNG image.';
            setseosubmenuimgerr(newerr);
        }
    };

    const removeSubMenuFields = (i) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                setdelcount((prev) => prev + 1);
                const existingsubmenus = [...submenusform];
                const existingseosubimgs = [...seosubmenuimg];
                existingsubmenus.splice(i, 1);
                existingseosubimgs.splice(i, 1);
                setsubmenusform(existingsubmenus);
                setseosubmenuimg(existingseosubimgs);
                setsubmenudel(`Deleted ${delcount} Submenus`);
                Swal.fire('Deleted!', 'SubMenu has been removed!', 'success');
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
            }
        });
    };

    const handlesubmenufieldchange = (i, field, value) => {
        const newfield = [...submenusform];
        newfield[i][field] = value;
        setsubmenusform(newfield);
        validation();
    };

    const handleaddsubmenubutton = () => {
        console.log("inside handleaddsubmenubutton");

        setsubmenusform([{ id: '', name: '', link: '', title: '', keywords: '', description: '', canonical: '', sitename: '', imagePath: '' }]);
        setsubmenutoggle(true);
        setaddcount((prev) => prev + 1);
        setsubmenuadd(`Added ${addcount} Submenus`);

    }

    const validation = () => {

        const menuerros = menuform.map((e) => {
            const nameerror = !(e?.name.trim().length > 4);
            const linkerror = !e.link.trim();

            return { name: nameerror, link: linkerror };
        });

        const submenuerrors = submenusform.map((e) => {
            const nameerror = !(e.name.trim().length > 4);
            const linkerror = !e.link.trim();

            return { name: nameerror, link: linkerror };
        });

        const menuerrboo = menuform.every((e) => {
            return e.name.trim().length > 4 && e.link.trim();
        });

        const submenuerrboo = submenusform.every((e) => {
            return e.name.trim().length > 4 && e.link.trim();
        });

        setmenuformerr(menuerros);
        setsubmenusformerr(submenuerrors);

        return submenutoggle ? menuerrboo && submenuerrboo : menuerrboo;
    }

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating Menus Successful!',
            }).then(() => navigate('/menu'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding Menus Successful!',
            }).then(() => navigate('/menu'))
        }
    }

    const addmenus = () => {
        if (validation()) {
            invokeAlert();
            if (state) {
                if (submenutoggle && submenusform.length > 0) {
                    const dat = {
                        id: menuform[0]?.id,
                        name: menuform[0]?.name,
                        link: menuform[0]?.link,
                        description: menuform[0]?.description,
                        title: menuform[0]?.title,
                        keywords: menuform[0]?.keywords,
                        canonical: menuform[0]?.canonical,
                        sitename: menuform[0]?.sitename,
                        imagePath: menuform[0]?.imagePath,
                        submenus: submenusform
                    };

                    const logdat = {
                        loggeduser: userdat?.username,
                        pagename: "Menus",
                        operation: "UPDATE",
                        description: `Updated Menus & Submenus${submenuadd != null ? ", " + submenuadd : ''}${submenudel != null ? ", " + submenudel : ''}${seomenuimgtext != null ? ", " + seomenuimgtext : ''}${seosubmenuimgtext != null ? ", " + seosubmenuimgtext : ''} by the Loggedin User ${userdat?.username} on ${cusdateString}.`,
                        datetime: cusdateString
                    };

                    let formdata = new FormData();
                    formdata.append("file", seomenuimg);
                    formdata.append("tablename", "SEOMenuImages");
                    formdata.append("date", datestring);

                    Services
                        .updatemenus(dat)
                        .catch((err) => console.error('error updating menus :', err));

                    Services
                        .uploadImage(formdata)
                        .catch((err) => console.error("Error uploading SEOMenuImage: ", err));

                    seosubmenuimg.map((e) => {
                        let seosubimgF = new FormData();
                        seosubimgF.append("file", e);
                        seosubimgF.append("tablename", "SEOSubMenuImages");
                        seosubimgF.append("date", datestring);

                        Services
                            .uploadImage(seosubimgF)
                            .catch((err) => console.error("Error uploading SEOSubMenuImages: ", err));
                    });

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs", err));

                } else {
                    const dat = {
                        id: menuform[0]?.id,
                        name: menuform[0]?.name,
                        link: menuform[0]?.link,
                        description: menuform[0]?.description,
                        title: menuform[0]?.title,
                        keywords: menuform[0]?.keywords,
                        canonical: menuform[0]?.canonical,
                        sitename: menuform[0]?.sitename,
                        imagePath: menuform[0]?.imagePath,
                    };

                    const logdat = {
                        loggeduser: userdat?.username,
                        pagename: "Menus",
                        operation: "UPDATE",
                        description: `Updated Menus${submenuadd != null ? ", " + submenuadd : ''}${submenudel != null ? ", " + submenudel : ''}${seomenuimgtext != null ? ", " + seomenuimgtext : ''} by the Loggedin User ${userdat?.username} on ${cusdateString}`,
                        datetime: cusdateString
                    };

                    let formdata = new FormData();
                    formdata.append("file", seomenuimg);
                    formdata.append("tablename", "SEOMenuImages");
                    formdata.append("date", datestring);

                    Services
                        .updatemenus(dat)
                        .catch((err) => console.error('error updating menus :', err));

                    Services
                        .uploadImage(formdata)
                        .catch((err) => console.error("Error uploading SEOMenuImage: ", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs", err));
                }
            } else if (submenutoggle && submenusform.length > 0) {
                const dat = {
                    name: menuform[0]?.name,
                    link: menuform[0]?.link,
                    description: menuform[0]?.description,
                    title: menuform[0]?.title,
                    keywords: menuform[0]?.keywords,
                    keywords: menuform[0]?.keywords,
                    canonical: menuform[0]?.canonical,
                    sitename: menuform[0]?.sitename,
                    imagePath: menuform[0]?.imagePath,
                    submenus: submenusform
                };

                const logdat = {
                    loggeduser: userdat?.username,
                    pagename: "Menus",
                    operation: "ADD",
                    description: `Added Menus & Submenus by the Loggedin User ${userdat?.username} on ${cusdateString}`,
                    datetime: cusdateString
                };

                let formdata = new FormData();
                formdata.append("file", seomenuimg);
                formdata.append("tablename", "SEOMenuImages");
                formdata.append("date", datestring);

                Services
                    .addmenus(dat)
                    .catch((err) => console.error('error adding menus :', err));

                Services
                    .uploadImage(formdata)
                    .catch((err) => console.error("Error uploading SEOMenuImage: ", err));

                seosubmenuimg.map((e) => {
                    let seosubimgF = new FormData();
                    seosubimgF.append("file", e);
                    seosubimgF.append("tablename", "SEOSubMenuImages");
                    seosubimgF.append("date", datestring);

                    Services
                        .uploadImage(seosubimgF)
                        .catch((err) => console.error("Error uploading SEOSubMenuImages: ", err));
                });

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs", err));

            } else {
                const dat = {
                    name: menuform[0]?.name,
                    link: menuform[0]?.link,
                    description: menuform[0]?.description,
                    title: menuform[0]?.title,
                    keywords: menuform[0]?.keywords,
                    keywords: menuform[0]?.keywords,
                    canonical: menuform[0]?.canonical,
                    sitename: menuform[0]?.sitename,
                    imagePath: menuform[0]?.imagePath,
                };

                const logdat = {
                    loggeduser: userdat?.username,
                    pagename: "Menus",
                    operation: "ADD",
                    description: `Added Menus${submenuadd != null ? ", " + submenuadd : ''}${submenudel != null ? ", " + submenudel : ''} by the Loggedin User ${userdat?.username} on ${cusdateString}`,
                    datetime: cusdateString
                };

                let formdata = new FormData();
                formdata.append("file", seomenuimg);
                formdata.append("tablename", "SEOMenuImages");
                formdata.append("date", datestring);

                Services
                    .addmenus(dat)
                    .catch((err) => console.error('error adding menus :', err));

                Services
                    .uploadImage(formdata)
                    .catch((err) => console.error("Error uploading SEOMenuImage: ", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs", err));
            }

            setmenuform([{ id: '', name: '', link: '', title: '', keywords: '', description: '', canonical: '', imagePath: '', sitename: '' }]);
            setsubmenusform([{ id: '', name: '', link: '', title: '', keywords: '', description: '', canonical: '', imagePath: '', sitename: '' }]);
            setsubmenutoggle(false);

        }
    };

    return (
        <>
            {logincred?.length == 1 ?
                <Box
                    component={'form'}
                >

                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/menu'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    Menu Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">Menu Form</Typography>
                        </Breadcrumbs>
                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                Menu Form
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >

                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 3 }}
                                >

                                    <TextField
                                        error={menuformerr[0]?.name}
                                        id="standard-error-helper-text"
                                        label="Menu Name"
                                        value={menuform[0]?.name}
                                        onChange={(ev) => handlemenufieldchange('name', ev.target.value)}
                                        helperText={menuformerr[0]?.name && "Please Enter atleast 4 characters"}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <FormControl variant="standard" sx={{ flex: 1 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Menu Link</InputLabel>
                                        <Select
                                            // error={true}
                                            error={menuformerr[0]?.link}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={menuform[0]?.link}
                                            onChange={(ev) => handlemenufieldchange('link', ev.target.value)}
                                            label="Menu Link"
                                        >
                                            {linkdrop?.map((ef) => (
                                                <MenuItem value={ef?.link}>{ef?.description}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>

                                </Box>

                                {/* SEO Section starts */}
                                {submenutoggle && submenusform.length > 0 ? ""
                                    :
                                    <Accordion
                                        sx={{ mb: 2 }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography >SEO Section</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box
                                                component={'div'}
                                                display={'flex'}
                                                flexDirection={{ xs: 'column', lg: 'row' }}
                                            >
                                                <Box
                                                    component={'div'}
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    flex={3}
                                                >
                                                    <Box
                                                        component={'div'}
                                                        display='flex'
                                                        flexDirection={{ xs: 'column', lg: 'row' }}
                                                        gap={{ lg: 3 }}
                                                    >

                                                        <TextField
                                                            label="Title"
                                                            value={menuform[0]?.title}
                                                            onChange={(ev) => handlemenufieldchange('title', ev.target.value)}
                                                            variant="standard"
                                                            sx={{ flex: 1 }}
                                                        />

                                                        <TextField
                                                            label="Keywords"
                                                            value={menuform[0]?.keywords}
                                                            onChange={(ev) => handlemenufieldchange('keywords', ev.target.value)}
                                                            variant="standard"
                                                            sx={{ flex: 1 }}
                                                        />

                                                    </Box>

                                                    <Box
                                                        component={'div'}
                                                        display='flex'
                                                        flexDirection={{ xs: 'column', lg: 'row' }}
                                                        gap={{ lg: 3 }}
                                                    >

                                                        <TextField
                                                            label="Canonical"
                                                            value={menuform[0]?.canonical}
                                                            onChange={(ev) => handlemenufieldchange('canonical', ev.target.value)}
                                                            variant="standard"
                                                            sx={{ flex: 1 }}
                                                        />

                                                        <TextField
                                                            label="Sitename"
                                                            value={menuform[0]?.sitename}
                                                            onChange={(ev) => handlemenufieldchange('sitename', ev.target.value)}
                                                            variant="standard"
                                                            sx={{ flex: 1 }}
                                                        />

                                                    </Box>

                                                    <Box
                                                        component={"div"}
                                                        sx={{
                                                            '& .MuiTextField-root': { width: '100%', mb: 2 },
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >

                                                        <TextField
                                                            id="standard-error-helper-text"
                                                            label="Description"
                                                            multiline
                                                            value={menuform[0]?.description}
                                                            onChange={(ev) => handlemenufieldchange('description', ev.target.value)}
                                                            variant="standard"
                                                        />

                                                    </Box>

                                                </Box>

                                                <Box
                                                    component={'div'}
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    alignItems={'center'}
                                                    sx={{ flex: 1 }}
                                                >

                                                    {seomenuimg ?
                                                        <Button
                                                            color='warning'
                                                            variant="contained"
                                                            component="label"
                                                            sx={{ fontSize: 12 }}
                                                            startIcon={<CloudUploadIcon />}
                                                        >
                                                            Re-Upload Image
                                                            <input
                                                                hidden
                                                                accept="image/jpeg, image/png"
                                                                type="file"
                                                                onChange={(e) => handleSEOMenuImageChange(e)}
                                                            />
                                                        </Button>
                                                        :
                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            sx={{ fontSize: 12 }}
                                                            startIcon={<CloudUploadIcon />}
                                                        >
                                                            Upload Image
                                                            <input
                                                                hidden
                                                                accept="image/jpeg, image/png"
                                                                type="file"
                                                                onChange={(e) => handleSEOMenuImageChange(e)}
                                                            />
                                                        </Button>
                                                    }


                                                    <Box
                                                        component={'div'}
                                                        mt={2}
                                                    >
                                                        {seomenuimg && (
                                                            <img src={URL.createObjectURL(seomenuimg)} alt="image" height={100} width={100} />
                                                        )}
                                                    </Box>

                                                    <Typography color={'GrayText'} sx={{ mb: 1 }} level="body-sm">Meta Tag Image</Typography>

                                                    {seomenuimgerr && (
                                                        <Typography color="error" align='center' variant="body2">
                                                            {seomenuimgerr}
                                                        </Typography>
                                                    )}

                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                {/* SEO Section Ends */}


                                {submenutoggle && submenusform.length > 0 ?
                                    <Card>
                                        <CardHeader
                                            // title=<h3>SubMenus Form</h3>
                                            sx={{ paddingX: 2 }}
                                            title=<Typography fontSize={'1.35rem'} color="text.primary">
                                                SubMenus
                                            </Typography>
                                        />
                                        <CardContent sx={{ padding: 0 }}>
                                            {submenusform.map((e, i) => (

                                                <Box component={'div'} key={i} sx={{ mb: 1 }}>
                                                    <CardHeader
                                                        sx={{
                                                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                                                            paddingY: 0, paddingX: 2
                                                        }}
                                                        action={
                                                            <Tooltip title="Remove field">
                                                                <IconButton onClick={() => removeSubMenuFields(i)}>
                                                                    <DeleteOutlineIcon color='error' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        // title=<h4>SubMenus {i + 1}</h4>
                                                        title=<Typography fontSize={'1.1rem'} color="text.primary">
                                                            SubMenu {i + 1}
                                                        </Typography>
                                                    />

                                                    <CardContent sx={{ paddingY: 0, paddingX: 2 }}>

                                                        <Box
                                                            component={'section'}
                                                        >
                                                            <Box
                                                                component={'div'}
                                                                display='flex'
                                                                flexDirection={{ xs: 'column', lg: 'row' }}
                                                                gap={{ lg: 3 }}
                                                                sx={{ mb: 3 }}
                                                            >

                                                                <TextField
                                                                    error={submenusformerr[i]?.name}
                                                                    id="standard-error-helper-text"
                                                                    label="SubMenu Name"
                                                                    value={e.name}
                                                                    onChange={(ev) => handlesubmenufieldchange(i, 'name', ev.target.value)}
                                                                    helperText={submenusformerr[i]?.name && "Please Enter atleast 4 characters"}
                                                                    variant="standard"
                                                                    sx={{ flex: 1 }}
                                                                />

                                                                <FormControl variant="standard" sx={{ flex: 1 }}>
                                                                    <InputLabel id="demo-simple-select-standard-label">SubMenu Link</InputLabel>
                                                                    <Select
                                                                        error={submenusformerr[i]?.link}
                                                                        labelId="demo-simple-select-standard-label"
                                                                        id="demo-simple-select-standard"
                                                                        value={e.link}
                                                                        onChange={(ev) => handlesubmenufieldchange(i, 'link', ev.target.value)}
                                                                        label="SubMenu Link"
                                                                    >
                                                                        {linkdrop?.map((ef) => (
                                                                            <MenuItem value={ef?.link}>{ef?.description}</MenuItem>
                                                                        ))}

                                                                    </Select>
                                                                </FormControl>


                                                                {/* <TextField
                                                            error={submenusformerr[i]?.link}
                                                            id="standard-error-helper-text"
                                                            label="SubMenu Link"
                                                            value={e.link}
                                                            onChange={(ev) => handlesubmenufieldchange(i, 'link', ev.target.value)}
                                                            helperText={submenusformerr[i]?.link && "required"}
                                                            variant="standard"
                                                            sx={{ flex: 1 }}
                                                        /> */}

                                                            </Box>

                                                            {/* SubMenus SEO Section starts --temporily disabled */}
                                                            <Accordion
                                                                sx={{ mb: 2 }}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography >SEO Section</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>

                                                                    <Box
                                                                        component={'div'}
                                                                        display={'flex'}
                                                                        flexDirection={{ xs: 'column', lg: 'row' }}
                                                                    >

                                                                        <Box
                                                                            component={'div'}
                                                                            display={'flex'}
                                                                            flexDirection={'column'}
                                                                            flex={3}
                                                                        >

                                                                            <Box
                                                                                component={'div'}
                                                                                display='flex'
                                                                                flexDirection={{ xs: 'column', lg: 'row' }}
                                                                                gap={{ lg: 3 }}
                                                                            >

                                                                                <TextField
                                                                                    label="Title"
                                                                                    value={e.title}
                                                                                    onChange={(ev) => handlesubmenufieldchange(i, 'title', ev.target.value)}
                                                                                    variant="standard"
                                                                                    sx={{ flex: 1 }}
                                                                                />

                                                                                <TextField
                                                                                    label="Keywords"
                                                                                    value={e.keywords}
                                                                                    onChange={(ev) => handlesubmenufieldchange(i, 'keywords', ev.target.value)}
                                                                                    variant="standard"
                                                                                    sx={{ flex: 1 }}
                                                                                />

                                                                            </Box>

                                                                            <Box
                                                                                component={'div'}
                                                                                display='flex'
                                                                                flexDirection={{ xs: 'column', lg: 'row' }}
                                                                                gap={{ lg: 3 }}
                                                                            >

                                                                                <TextField
                                                                                    label="Canonical"
                                                                                    value={e.canonical}
                                                                                    onChange={(ev) => handlesubmenufieldchange(i, 'canonical', ev.target.value)}
                                                                                    variant="standard"
                                                                                    sx={{ flex: 1 }}
                                                                                />

                                                                                <TextField
                                                                                    label="Sitename"
                                                                                    value={e.sitename}
                                                                                    onChange={(ev) => handlesubmenufieldchange(i, 'sitename', ev.target.value)}
                                                                                    variant="standard"
                                                                                    sx={{ flex: 1 }}
                                                                                />

                                                                            </Box>

                                                                            <Box
                                                                                component={"div"}
                                                                                sx={{
                                                                                    '& .MuiTextField-root': { width: '100%', mb: 2 },
                                                                                }}
                                                                                noValidate
                                                                                autoComplete="off"
                                                                            >

                                                                                <TextField
                                                                                    label="Description"
                                                                                    multiline
                                                                                    value={e.description}
                                                                                    onChange={(ev) => handlesubmenufieldchange(i, 'description', ev.target.value)}
                                                                                    variant="standard"
                                                                                />

                                                                            </Box>
                                                                        </Box>

                                                                        <Box
                                                                            component={'div'}
                                                                            display={'flex'}
                                                                            flexDirection={'column'}
                                                                            alignItems={'center'}
                                                                            sx={{ flex: 1 }}
                                                                        >
                                                                            {seosubmenuimg[i] ? (
                                                                                <Button
                                                                                    color='warning'
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                    sx={{ fontSize: 12 }}
                                                                                    startIcon={<CloudUploadIcon />}
                                                                                >
                                                                                    Re-Upload Image
                                                                                    <input
                                                                                        hidden
                                                                                        accept="image/jpeg, image/png"
                                                                                        type="file"
                                                                                        onChange={(ev) => { handleSEOSubMenuImageChange(i, ev); }}
                                                                                    />

                                                                                </Button>
                                                                            ) : (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                    sx={{ fontSize: 12 }}
                                                                                    startIcon={<CloudUploadIcon />}
                                                                                >
                                                                                    Upload Image
                                                                                    <input
                                                                                        hidden
                                                                                        accept="image/jpeg, image/png"
                                                                                        type="file"
                                                                                        onChange={(ev) => { handleSEOSubMenuImageChange(i, ev); }}
                                                                                    />

                                                                                </Button>
                                                                            )
                                                                            }


                                                                            <Box
                                                                                component={'div'}
                                                                                mt={2}
                                                                            >
                                                                                {seosubmenuimg[i] && (
                                                                                    <img src={URL.createObjectURL(seosubmenuimg[i])} alt="image" height={100} width={100} />
                                                                                )}

                                                                            </Box>

                                                                            <Typography color={'GrayText'} sx={{ mb: 1 }} level="body-sm">{`Meta Tag ${i + 1} Image`}</Typography>

                                                                            {seosubmenuimgerr[i] && (
                                                                                <Typography color="error" align='center' variant="body2">
                                                                                    {seosubmenuimgerr[i]}
                                                                                </Typography>
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            {/* SubMenus SEO Section Ends --temporily disabled */}
                                                        </Box>
                                                    </CardContent>
                                                    {i < submenusform.length - 1 ? <Divider /> : <Box component={'div'}></Box>}
                                                </Box>
                                            ))}
                                            <Box
                                                component={'div'}
                                                display={'flex'}
                                                justifyContent={{ xs: 'center', md: 'flex-start' }}
                                                sx={{ paddingX: 2 }}
                                            >
                                                <Button onClick={() => addSubMenuFields()} variant="contained">
                                                    Add More
                                                </Button>
                                            </Box>
                                        </CardContent>

                                    </Card> : <>
                                        <Box
                                            component={'div'}
                                            display={'flex'}
                                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                                        >
                                            <Button onClick={() => handleaddsubmenubutton()} variant="contained">
                                                Add SubMenus
                                            </Button>
                                        </Box>
                                    </>
                                }

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                                    sx={{ mt: 2 }}
                                >
                                    <Button onClick={() => addmenus()} variant="contained">
                                        Save
                                    </Button>
                                </Box>

                            </Box>

                        </CardContent>
                    </Card>
                </Box> : <></>}
        </>
    )
}

export default MenuForm;