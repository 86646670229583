import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import moment from 'moment';
import LogServices from 'app/pages/LogServices/LogServices';
import SuperAdminServices from '../SuperAdminServices';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const RolesForm = () => {

    let { state } = useLocation();

    let a;
    let cond = [];
    let checkmen = [];

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const [formdata, setformdata] = useState([{ id: '', rolename: '', description: '' }]);
    // const [menumastdat, setmenumastdat] = useState([{ id: '', menumasterid: '' }]);
    const [menumastdat, setmenumastdat] = useState([]);
    const [getmenus, setgetmenus] = useState([]);
    const [checkeddat, setcheckeddat] = useState([]);
    const [togglecheckerr, settogglecheckerr] = useState(false);
    const [formerr, setformerr] = useState([]);

    const [logincred, setlogincred] = useState();
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format("llll");

    useEffect(() => {
        const ef = userdat?.rolename.includes("SUPER_ADMIN") ? true : false;
    
        setlogincred(ef);
    
        if (ef) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        SuperAdminServices
            .getallmenumasters()
            .then((e) => {
                setgetmenus(e.data);

                if (state) {
                    console.log("STATRE: ", state);

                    setformdata([{ id: state?.id, rolename: state?.rolename, description: state?.description }])

                    e.data.length > 0 && e.data?.map((eg, index) => {
                        a = state?.mapmenus.filter((ko) => eg.id === ko.id ? ko?.id : "");
                        {/* cond = a.length > 0 ? true : false; */ }
                        console.log("A: ", a);
                        cond.push(a.length > 0 ? true : false);
                        checkmen.push(a.length > 0 ? { id: a[0]?.maproleid, menumasterid: a[0]?.id } : { id: '', menumasterid: '' })

                    });

                    console.log("Checkmen: ", checkmen);

                    console.log("AS: ", a);
                    console.log("COND: ", cond)
                    // setcheckeddat(cond);
                } else {
                    console.log("ELSE PATY");
                    e.data.length > 0 && e.data.map((eg, index) => {
                        cond.push(false);
                        checkmen.push({ id: '', menumasterid: '' });
                    });

                    // setmenumastdat(checkmen);
                }
                setcheckeddat(cond);
                setmenumastdat(checkmen);
            })
            .catch((err) => console.error("Error While fetching menus: ", err));
    }, [])


    // useEffect(() => {
    //     if (state) {
    //         console.log("STATRE: ", state);

    //         getmenus.length > 0 && getmenus?.map((eg, index) => {
    //             a = state?.mapmenus.filter((ko) => eg.id === ko.id ? ko?.id : "");
    //             {/* cond = a.length > 0 ? true : false; */ }
    //             cond.push(a.length > 0 ? true : false);
    //         });

    //             console.log("AS: ", a);
    //             console.log("COND: ", cond)
    //             setcheckeddat(cond);
    //             // setcheckeddat(cond);
    //         // setformdata([{ id: state.id, rolename: state.rolename, description: state.description }]);
    //     }
    // }, []);

    const handleformfieldchange = (field, value) => {
        const newfield = [...formdata];
        newfield[0][field] = value;
        setformdata(newfield);
        validation();
    };

    const handlemenuscheckboxchange = (index, id, value) => {
        console.log("INdex: ", index, "\nid: ", id, "\nvalue: ", value);
        console.log("menumast: ", menumastdat);
        if (value === true) {
            // if (state) {
            const newfields = [...menumastdat];
            const chek = [...checkeddat];
            chek[index] = true;
            newfields[index]["menumasterid"] = id;
            setmenumastdat(newfields);
            setcheckeddat(chek);
            // } else {
            //     console.log("TRUE EXE");
            //     const newfields = [...menumastdat, { id: index + 1, menumasterid: id }];
            //     const chek = [...checkeddat, true]
            //     // newfields[index]["menumasterid"] = id;
            //     console.log("NEWFI: ", newfields);
            //     console.log("CHEL: ", chek);
            //     setmenumastdat(newfields);
            //     setcheckeddat(chek);
            // }
        } else {
            console.log("FALSE RXE");
            const updatedfields = [...menumastdat];
            console.log("MENUMAST: ", menumastdat);
            const chek = [...checkeddat];
            chek[index] = false;
            // updatedfields.splice(index, 1);
            updatedfields[index]["menumasterid"] = '';
            console.log("ODAT: ", updatedfields);
            console.log("CHEK: ", chek);
            setcheckeddat(chek);
            setmenumastdat(updatedfields);
        }
        console.log("CHECDSY: ", checkeddat);
        console.log("MENUMASDAT: ", menumastdat);
        validation();
    };

    const validation = () => {

        const formerror = formdata.map((e) => {
            const rolenameerr = !e.rolename.trim();
            const descriptionerr = !e.description.trim();

            return { rolename: rolenameerr, description: descriptionerr };
        });

        setformerr(formerror);

        // console.log("VSL CJDL: ", checkeddat[1]);

        const checkerrboo = checkeddat.some((e) => {
            return !!e;
        })

        console.log("CHEXKERRBO: ", checkerrboo);
        settogglecheckerr(!checkerrboo);

        // const checkboxerr = menumastdat.length > 0 ? true : false;
        // console.log("CHECKbox err: ", checkboxerr);

        const formerrboo = formdata.every((e) => {
            return e.rolename.trim() && e.description.trim();
        });

        return formerrboo && checkerrboo;
    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating Roles Successful!',
            }).then(() => navigate('/roles'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding Roles Successful!',
            }).then(() => navigate('/roles'))
        }
    }

    const onSubmit = () => {

        if (validation()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: formdata[0]?.id,
                    rolename: formdata[0]?.rolename,
                    description: formdata[0]?.description,
                    menumasterids: menumastdat
                };

                const logdat = {
                    loggeduser: userdat.username,
                    pagename: "Roles",
                    operation: "UPDATE",
                    description: `Updated Roles with Rolename: ${formdata[0]?.rolename} by the LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                };

                SuperAdminServices
                    .updaterole(dat)
                    .catch((err) => console.error("error updating roles", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error adding Logs", err));

            } else {
                const dat = {
                    rolename: formdata[0]?.rolename,
                    description: formdata[0]?.description,
                    menumasterids: menumastdat
                };

                const logdat = {
                    loggeduser: userdat?.username,
                    pagename: "Roles",
                    operation: "ADD",
                    description: `Added Roles by LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                }

                SuperAdminServices
                    .addroles(dat)
                    .catch((err) => console.error("error adding roles", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs: ", err));
            }
            setformdata([{ id: '', rolename: '', description: '' }]);
        }

    }

    return (
        <>
            {logincred ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/roles'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    Roles Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">Roles Form</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                Roles Form
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >
                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >

                                    <TextField
                                        error={formerr[0]?.rolename}
                                        helperText={formerr[0]?.rolename && 'required'}
                                        id="standard-error-helper-text"
                                        label="RoleName"
                                        value={formdata[0]?.rolename}
                                        onChange={(ev) => handleformfieldchange('rolename', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.description}
                                        helperText={formerr[0]?.description && 'required'}
                                        id="standard-error-helper-text"
                                        label="Description"
                                        multiline
                                        value={formdata[0]?.description}
                                        onChange={(ev) => handleformfieldchange('description', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                </Box>

                                {/* <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                                    <FormControlLabel required control={<Checkbox />} label="Required" />
                                    <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
                                </FormGroup> */}

                                <Box
                                    component={'div'}
                                    display='flex'
                                    alignItems={'center'}
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 2 }}
                                    sx={{ mb: 1 }}
                                >
                                    <Typography sx={{ }} color={'GrayText'} align='left' level="body-sm">{`Screens`}</Typography>
                                    {togglecheckerr ? <Typography sx={{ fontSize: 12 }} color={'red'} align='left' level="body-sm">{`*Please Select atleast one screen`}</Typography> : ''}
                                </Box>
                                {/* <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >
                                    {console.log("GET: ", getmenus)}
                                    {getmenus.length > 0 && getmenus?.map((eg, index) => {

                                        return (
                                            <>
                                                {console.log("INSIDE: ", checkeddat[index])}
                                                <FormControlLabel key={index} control={<Checkbox onChange={(gg) => handlemenuscheckboxchange(index, eg.id, gg.target.checked)} checked={!!checkeddat[index]} />} label={eg.menuname} />
                                            </>
                                        )
                                    })}
                                </Box> */}

                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {getmenus.length > 0 && getmenus?.map((eg, index) => {

                                            return (
                                                <Grid item xs={12} sm={4} md={3} key={index}>
                                                    {console.log("INSIDE: ", checkeddat[index])}
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(gg) => handlemenuscheckboxchange(index, eg.id, gg.target.checked)} checked={!!checkeddat[index]} />}
                                                        label={eg.menuname} />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Box>

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                                    sx={{ mt: 2 }}
                                >
                                    <Button variant="contained" onClick={() => onSubmit()}>
                                        Save
                                    </Button>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>

                </Box> : <></>}

        </>
    )
}

export default RolesForm;