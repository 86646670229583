import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class SuperAdminServices {
    //roles start
    addroles(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addroles`, dat);
    };

    updaterole(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateroles`, dat);
    };

    deleterole(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleterole/${id}`);
    };

    getallroles() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getroles`);
    };

    getrolebyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getrole/${id}`);
    };

    getroleswmenus() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getroleswmenus`);
    };
    //roles end

    //Users Starts
    addcmsusers(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addcmsusers`, dat);
    };

    updatecmsuser(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatecmsusers`, dat);
    };

    deletecmsuser(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletecmsuser/${id}`);
    };

    getallcmsusers() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getcmsusers`);
    };

    getcmsuserbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getcmsuser/${id}`);
    };

    getuserswroles() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getuserswroles`);
    };
    //Users Ends

    //MenuMaster Starts
    addmenumaster(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addmenumaster`, dat);
    };

    updatemenumaster(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatemenumaster`, dat);
    };

    deletemenumaster(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletemenumaster/${id}`);
    };

    getallmenumasters() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmenumasters`);
    };

    getmenumasterbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmenumaster/${id}`);
    };
    //MenuMaster Ends

    //MapRoles Starts
    addmaprole(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addmaproles`, dat);
    };

    updatemaproles(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatemaproles`, dat);
    };

    deletemaprole(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletemaprole/${id}`);
    };

    getallmaproles() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmaproles`);
    };

    getmaprolebyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmaprole/${id}`);
    };
    //MapRoles Ends

    //MapUser Starts
    addmapuser(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addmapuser`, dat);
    };

    updatemapuser(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatemapuser`, dat);
    };

    deletemapuser(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletemapuser/${id}`);
    };

    getallmapusers() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmapusers`);
    };

    getmapuserbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getmapuser/${id}`);
    };
    //MapUser Ends

    //Change User State Starts
    changeuserstate(id, state) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/userstate/${id}/${state}`)
    }

    //Change User State Ends
}

export default new SuperAdminServices();