import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Services from '../CKCService';

const UploadHomePageForm = () => {

  let { state } = useLocation();

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const [formdata, setformdata] = useState([{ id: '', title: '', subtitle: '', description: '' }]);
  const [formerr, setformerr] = useState([]);

  useEffect(() => {
    if (state) {
      setformdata([{ id: state.id, title: state.title, subtitle: state.subtitle, description: state?.description }]);
    }
  }, [])


  const handleformfieldchange = (field, value) => {
    const newfield = [...formdata];
    newfield[0][field] = value;
    setformdata(newfield);
    validation();
  };

  const validation = () => {
    const formerror = formdata.map((e) => {
      // const linkerr = !e.link.trim();
      // const descriptionerr = !e.description.trim();

      // return { link: linkerr, description: descriptionerr };
      const titleerr = !e?.title.trim();
      const subtitleerr = !e?.subtitle.trim();
      const descriptionerr = !e?.description.trim();

      return { title: titleerr, subtitle: subtitleerr, description: descriptionerr };
    });

    setformerr(formerror);

    const formerrboo = formdata.every((e) => {
      // return e.link.trim() && e.description.trim();
      return e?.title.trim() && e?.subtitle.trim() && e?.description.trim();
    });

    return formerrboo;
  };

  const invokeAlert = () => {
    if (state) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Updating Upload HomePage Successful!',
      }).then(() => navigate('/uploadhomepage'))
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Adding Upload HomePage Successful!',
      }).then(() => navigate('/uploadhomepage'))
    }
  }

  const onSubmit = () => {
    if (validation()) {
      invokeAlert();
      if (state) {
        // const dat = {
        //     id: formdata[0]?.id,
        //     link: formdata[0]?.link,
        //     description: formdata[0]?.description
        // };

        const dat = {
          id: formdata[0]?.id,
          title: formdata[0]?.title,
          subtitle: formdata[0]?.subtitle,
          description: formdata[0]?.description
        }

        Services
          .updateckcuploadhomepage(dat)
          .catch((err) => console.log("error updating Upload HomePage", err));

        // Services
        //     .updatehyperlinks(dat)
        //     .catch((err) => console.error("error updating Saving Plan", err));
      } else {
        // const dat = {
        //     link: formdata[0]?.link,
        //     description: formdata[0]?.description
        // };

        const dat = {
          id: formdata[0]?.id,
          title: formdata[0]?.title,
          subtitle: formdata[0]?.subtitle,
          description: formdata[0]?.description
        }

        // Services
        //     .addhyperlinks(dat)
        //     .catch((err) => console.error("error adding Saving Plan", err));

        Services
          .addckcuploadhomepage(dat)
          .catch((err) => console.log("error adding Upload HomePage", err));
      }

      // setformdata([{ id: '', link: '', description: '' }]);
      setformdata([{ id: '', title: '', subtitle: '', description: '' }]);

    }
  }


  return (
    <Box
      component={'form'}
    >
      <Box
        component={'div'}
        sx={{ mb: 2 }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link to='/uploadhomepage'>
            <Typography fontSize={'.875rem'} color="text.primary">
              Upload HomePage Table
            </Typography>
          </Link>
          <Typography fontSize={'.875rem'} color="text.primary">Upload HomePage Form</Typography>
        </Breadcrumbs>

      </Box>

      <Card >
        <CardHeader
          title=<Typography fontSize={'1.7rem'} color="text.primary">
            Upload HomePage Form
          </Typography>
        />

        <CardContent sx={{ py: 0 }}>
          <Box
            component={'section'}
          >
            <Box
              component={'div'}
              display='flex'
              flexDirection={{ xs: 'column', lg: 'row' }}
              gap={{ lg: 3 }}
              sx={{ mb: 1 }}
            >

              <TextField
                error={formerr[0]?.title}
                helperText={formerr[0]?.title && 'required'}
                id="standard-error-helper-text"
                label="HomePage Title"
                value={formdata[0]?.title}
                onChange={(ev) => handleformfieldchange('title', ev.target.value)}
                variant="standard"
                sx={{ flex: 1 }}
              />

              <TextField
                error={formerr[0]?.subtitle}
                helperText={formerr[0]?.subtitle && 'required'}
                id="standard-error-helper-text"
                label="HomePage Subtitle"
                // multiline
                value={formdata[0]?.subtitle}
                onChange={(ev) => handleformfieldchange('subtitle', ev.target.value)}
                variant="standard"
                sx={{ flex: 2 }}
              />

            </Box>

            <Box
              component={"div"}
              sx={{
                '& .MuiTextField-root': { width: '100%', mb: 2 },
              }}
              noValidate
              autoComplete="off"
            >

              <TextField
                error={formerr[0]?.description}
                helperText={formerr[0]?.description && 'required'}
                id="standard-error-helper-text"
                label="HomePage Description"
                multiline
                value={formdata[0]?.description}
                onChange={(ev) => handleformfieldchange('description', ev.target.value)}
                variant="standard"
              />

            </Box>

            <Box
              component={'div'}
              display={'flex'}
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              sx={{ mt: 2 }}
            >
              <Button variant="contained" onClick={() => onSubmit()}>
                Save
              </Button>
            </Box>

          </Box>
        </CardContent>
      </Card>

    </Box>
  )
}

export default UploadHomePageForm;