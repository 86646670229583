import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, Collapse, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import moment from 'moment';
import LogServices from 'app/pages/LogServices/LogServices';
import SuperAdminServices from '../SuperAdminServices';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Switch from '@mui/material/Switch';

const FeatureSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    // width: 42,
    width: 49,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        // transitionDuration: '300ms',
        transitionDuration: '200ms',
        '&.Mui-checked': {
            // transform: 'translateX(16px)',
            transform: 'translateX(23px)',
            transitionDuration: '500ms',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#7352c7',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        // backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        backgroundColor: theme.palette.mode === 'light' ? '#e59c9c' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Users = () => {
    const [data, setdata] = useState([]);
    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);

    const [togglerow, settogglerow] = useState(null);
    const [maprolesdat, setmaprolesdat] = useState([]);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format("llll");

    useEffect(() => {
        const ef = userdat?.rolename.includes("SUPER_ADMIN") ? true : false;

        setlogincred(ef);

        if (ef) {
            console.log("If part");
        } else {
            console.log("Else part");
            Swal.fire({
                icon: 'error',
                title: 'UnAuthorized Access',
                text: 'Please Login to continue',
            }).then(() => navigate('/'))
        }
    }, [])

    useEffect(() => {

        SuperAdminServices
            .getuserswroles()
            .then((e) => {
                setdata(e.data);
                setpuredat(e.data);
            })
            .catch((err) => console.error("Error fetching Users", err));

    }, []);

    const deleteuser = (e) => {

        if (e.id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat.username,
                        pagename: "Users",
                        operation: "DELETE",
                        description: `Deleted User with Username: ${e.username} by LoggedIn User ${userdat.username} on ${cusDateString}`,
                        datetime: cusDateString
                    };

                    SuperAdminServices
                        .deletecmsuser(e.id)
                        .then(() => SuperAdminServices.getuserswroles())
                        .then((e) => {
                            setdata(e.data);
                            setpuredat(e.data);
                        })
                        .catch((err) => console.error("Error deleting Users", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };

    const handleuserstate = (e) => {

        const logdat = {
            loggeduser: userdat.username,
            pagename: "Users",
            operation: "USER ACTIVE",
            description: `Changed User State in Users Page with Username: ${e.username} by LoggedIn User ${userdat.username} on ${cusDateString}`,
            datetime: cusDateString
        };

        SuperAdminServices
            .changeuserstate(e?.id, e?.isActive.includes("TRUE") ? "FALSE" : "TRUE")
            .then(() => SuperAdminServices.getuserswroles())
            .then((ev) => {
                setdata(ev?.data);
                setpuredat(ev?.data);
            })
            .catch((err) => console.error("Error Changing State of the User: ", err));

        LogServices
            .addlogs(logdat)
            .catch((err) => console.error("Error Adding Logs: ", err));
    }


    const handleSearch = (hes) => {
        const filtered = puredat?.filter((es) => es?.username.toLowerCase().includes(hes.toLowerCase()) || es?.emailid.toLowerCase().includes(hes.toLowerCase()));
        setdata(filtered);
        settogglerow(null);
    };

    const handleSort = () => {

        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            const sorted = data?.sort((a, b) => a?.username > b?.username ? 1 : -1);
            setdata(sorted);
        } else {
            const sorted = data?.sort((a, b) => a?.username < b?.username ? 1 : -1);
            setdata(sorted);
        }
        settogglerow(null);
    };

    const rowtoggle = (i, id) => {
        const filt = data?.filter((ef) => ef?.id === id);
        setmaprolesdat(filt[0]?.maproles);
        settogglerow((prev) => (prev === i ? null : i));
    }


    return (
        <>
            {logincred ?
                <Card >
                    <CardHeader
                        sx={{
                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                        }}
                        action={
                            <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                <Tooltip title="Search Users">
                                    <Input
                                        id="input-with-icon-adornment"
                                        placeholder='Search'
                                        onChange={(ev) => handleSearch(ev.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchOutlinedIcon fontSize='small' color='action' />
                                            </InputAdornment>
                                        }
                                    />
                                </Tooltip>

                                <Tooltip title="Sort Users">
                                    <IconButton onClick={() => handleSort()}>
                                        <Link>
                                            <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                        </Link>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Add Users">
                                    <IconButton >
                                        <Link to="/usersform">
                                            <AddOutlinedIcon fontSize='medium' color='action' />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }

                        title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                            Users
                        </Typography>
                    />

                    <CardContent sx={{ py: 0 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell align="left">Sl No</StyledTableCell>
                                        <StyledTableCell align="left">UserName</StyledTableCell>
                                        <StyledTableCell align="center">EmailId</StyledTableCell>
                                        <StyledTableCell align="center">Contact No</StyledTableCell>
                                        <StyledTableCell align='center'>User Active</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((e, i) => {
                                        return (
                                            <>
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => rowtoggle(i, e.id)}
                                                        >
                                                            {togglerow === i ?
                                                                <Tooltip title="Collapse">
                                                                    <KeyboardArrowUpIcon />
                                                                </Tooltip> :
                                                                <Tooltip title="Expand">
                                                                    <KeyboardArrowDownIcon />
                                                                </Tooltip>}
                                                        </IconButton>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{i + 1}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e?.username}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e?.emailid}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e?.phno}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">
                                                        <FeatureSwitch
                                                            checked={e.isActive === 'TRUE' ? true : false}
                                                            onClick={() => handleuserstate(e)}
                                                        // sx={{ m: 1 }}
                                                        />
                                                    </StyledTableCell>

                                                    {/* <StyledTableCell align="center" component="th" scope="row">{moment(e?.createdDate).format("DD-MM-YYYY")}</StyledTableCell> */}
                                                    <StyledTableCell align="center" component="th" scope="row">
                                                        <Stack justifyContent="center" direction="row" alignItems="center" spacing={-0.3}>
                                                            <Tooltip title="Modify">
                                                                <Link to='/usersform' state={e}>
                                                                    <IconButton>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Link>
                                                            </Tooltip>

                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={() => deleteuser(e)}>
                                                                    <DeleteIcon color='error' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </StyledTableCell>
                                                </StyledTableRow>

                                                <StyledTableRow>
                                                    <StyledTableCell
                                                        sx={{ p: 0, backgroundColor: 'white' }}
                                                        colSpan={8}
                                                    >
                                                        <Collapse
                                                            in={togglerow === i} timeout={"auto"} unmountOnExit sx={{ m: 2 }}
                                                        >
                                                            <TableContainer component={Paper} >
                                                                <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                                                    <TableBody>
                                                                        {maprolesdat?.length > 0 ?
                                                                            maprolesdat?.map((ev, index) => {
                                                                                return (
                                                                                    <StyledTableRow key={index}>
                                                                                        {/* <StyledTableCell /> */}
                                                                                        <StyledTableCell
                                                                                            width={340}
                                                                                            align='center' component="th" scope="row">{index + 1}</StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            width={330}
                                                                                            align='left' component="th" scope="row">{ev?.rolename}</StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            width={460}
                                                                                            align='left' component="th" scope="row">{ev?.description}</StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                )
                                                                            }) :
                                                                            (<p style={{ textAlign: 'center' }}>No data available for this User</p>)
                                                                        }

                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Collapse>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>

                </Card> : <></>}

        </>
    )
}

export default Users;