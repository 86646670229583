import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Services from './Services';
import PhoneInput from 'react-phone-number-input'
import "./SocialMedia.css"
import moment from 'moment';
import LogServices from '../LogServices/LogServices';
// import ReactPhoneInput from 'react-phone-input-material-ui';

const SocialMediaForm = () => {

    let { state } = useLocation();

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const [preformdat, setpreformdat] = useState([{ id: '', facebook: '', instagram: '', x: '', linkedin: '', snapchat: '', phno: null }]);
    const [formdata, setformdata] = useState([{ id: '', facebook: '', instagram: '', x: '', linkedin: '', snapchat: '', phno: null }]);
    const [formerr, setformerr] = useState([]);

    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));
    const currDate = new Date();
    const cusDateString = moment(currDate).format("llll");

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Social Media"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        if (state) {
            setformdata([{ id: state.id, facebook: state?.facebook, instagram: state?.instagram, x: state?.x, linkedin: state?.linkedin, snapchat: state?.snapchat, phno: state?.phno }]);
            setpreformdat([{ id: state.id, facebook: state?.facebook, instagram: state?.instagram, x: state?.x, linkedin: state?.linkedin, snapchat: state?.snapchat, phno: state?.phno }]);
        }
    }, [])


    const handleformfieldchange = (field, value) => {
        const newfield = [...formdata];
        newfield[0][field] = value;
        setformdata(newfield);
        validation();
    };

    const validation = () => {
        const formerror = formdata.map((e) => {
            const facebookerr = !e?.facebook.trim();
            const instagramerr = !e?.instagram.trim();
            const xErr = !e?.x.trim();
            const linkedinerr = !e?.linkedin.trim();
            const snapchaterr = !e?.snapchat.trim();

            return { facebook: facebookerr, instagram: instagramerr, x: xErr, linkedin: linkedinerr, snapchat: snapchaterr };
        });

        setformerr(formerror);

        const formerrboo = formdata.every((e) => {
            return e?.facebook.trim() && e?.instagram.trim() && e?.linkedin.trim() && e?.snapchat.trim() && e?.x.trim();
        });

        return formerrboo;
    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating SocialMedia Successful!',
            }).then(() => navigate('/socialmedia'))
        }
    }

    const onSubmit = () => {
        if (validation()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: formdata[0]?.id,
                    facebook: formdata[0]?.facebook,
                    instagram: formdata[0]?.instagram,
                    x: formdata[0]?.x,
                    linkedin: formdata[0]?.linkedin,
                    snapchat: formdata[0]?.snapchat,
                    phno: formdata[0]?.phno
                };

                const logdat = {
                    loggeduser: userdat.username,
                    pagename:"Social Media",
                    operation: "UPDATE",
                    description: `Updated SocialMedia${preformdat[0]?.facebook !== formdata[0]?.facebook ? ", " + `Changed Facebook Link` : ''}${preformdat[0]?.instagram !== formdata[0]?.instagram ? ", " + `Changed Instagram Link` : ''}${preformdat[0]?.linkedin !== formdata[0]?.linkedin ? ", " + `Changed LinkedIn Link` : ''}${preformdat[0]?.phno !== formdata[0]?.phno ? ", " + `Changed Whatsapp Number` : ''}${preformdat[0]?.snapchat !== formdata[0]?.snapchat ? ", " + `Changed Snapchat Link` : ''}${preformdat[0]?.x !== formdata[0]?.x ? ", " + `Changed X(formerly Twitter) Link` : ''} by the LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                }

                Services
                    .updateSocialMedia(dat)
                    .catch((err) => console.error("error updating SocialMedia", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs: ", err));
            }

            setformdata([{ id: '', facebook: '', instagram: '', x: '', linkedin: '', snapchat: '', phno: null }]);

        }
    }


    return (
        <>
            {logincred?.length == 1 ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/socialmedia'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    SocialMedia Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">SocialMedia Form</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                SocialMedia Form
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >
                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >

                                    <TextField
                                        error={formerr[0]?.facebook}
                                        helperText={formerr[0]?.facebook && 'required'}
                                        id="standard-error-helper-text"
                                        label="Facebook Link"
                                        value={formdata[0]?.facebook}
                                        onChange={(ev) => handleformfieldchange('facebook', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.instagram}
                                        helperText={formerr[0]?.instagram && 'required'}
                                        id="standard-error-helper-text"
                                        label="Instagram Link"
                                        value={formdata[0]?.instagram}
                                        onChange={(ev) => handleformfieldchange('instagram', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.x}
                                        helperText={formerr[0]?.x && 'required'}
                                        id="standard-error-helper-text"
                                        label="X Link (formerly Twitter)"
                                        value={formdata[0]?.x}
                                        onChange={(ev) => handleformfieldchange('x', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                </Box>

                                <Box
                                    component={"div"}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 2 }}
                                >

                                    <TextField
                                        error={formerr[0]?.linkedin}
                                        helperText={formerr[0]?.linkedin && 'required'}
                                        id="standard-error-helper-text"
                                        label="LinkedIn Link"
                                        value={formdata[0]?.linkedin}
                                        onChange={(ev) => handleformfieldchange('linkedin', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.snapchat}
                                        helperText={formerr[0]?.snapchat && 'required'}
                                        id="standard-error-helper-text"
                                        label="Snapchat Link"
                                        value={formdata[0]?.snapchat}
                                        onChange={(ev) => handleformfieldchange('snapchat', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />
                                    <div className="MuiFormControl-root MuiTextField-root css-1vs1jgp-MuiFormControl-root-MuiTextField-root">
                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard css-cys4tf-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true" for="standard-error-helper-text" id="standard-error-helper-text-label">Whatsapp Number</label>

                                        <PhoneInput
                                            name="contact"
                                            className={`outline-none contactnum`}
                                            placeholder="Enter phone number"
                                            value={formdata[0]?.phno}
                                            onChange={(ev) => handleformfieldchange('phno', ev)}
                                            // placeholder="CONTACT NUMBER"
                                            defaultCountry="IN"
                                            withCountryCallingCode={true}
                                            international={true}

                                        />
                                        {/* <div class="PhoneInputCountrySelectArrow"></div> */}
                                    </div>

                                </Box>

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                                    sx={{ mt: 2 }}
                                >
                                    <Button variant="contained" onClick={() => onSubmit()}>
                                        Save
                                    </Button>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>

                </Box> : <></>}
        </>
    )
}

export default SocialMediaForm