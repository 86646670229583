import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import moment from 'moment';
import services from './Services';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Services from './Services';

import LinearProgress from '@mui/material/LinearProgress';
import LogServices from '../LogServices/LogServices';

const HomeBannersForm = () => {

    let { state } = useLocation();

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const currentDate = new Date();
    const datestring = moment(currentDate).format('YYYY-MM-DD');

    const cusdateString = moment(currentDate).format('llll');

    const [firsttext, setfirsttext] = useState(null);
    const [secondtext, setsecondtext] = useState(null);

    const [carimgcount, setcarimgcount] = useState(1);

    const [bannerform, setbannerform] = useState([{ id: '', bannertype: '', tooltip: '' }]);
    const [bannerdatform, setbannerdatform] = useState([{ id: '', heading: '', title: '', subtitle: '', buttonlabel: '', videoPath: '', imagePath: '', imageslider: '', buttonlink: '', filename: '' }]);
    const [carousaldat, setcarousaldat] = useState('');
    const [carousalimg, setcarousalimg] = useState([]);
    const [carousalimgerr, setcarousalimgerr] = useState([]);
    const [bannerimg, setbannerimg] = useState(null);
    const [bannerimgerr, setbannerimgerr] = useState('');
    const [bannervid, setbannervid] = useState(null);
    const [bannerviderr, setbannerviderr] = useState('');

    const [linkdrop, setlinkdrop] = useState([]);

    const [bannerformerr, setbannerformerr] = useState([]);
    const [bannerformdaterr, setbannerformdaterr] = useState([]);

    const [loading, setloading] = useState(true);
    const [progress, setProgress] = useState(0);

    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Home Page Banners"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {

        if (loading) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        return 0;
                    }
                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };
        } else {
            setProgress(0);
        }

    }, [loading])


    useEffect(() => {
        services
            .gethyperlinks()
            .then((e) => setlinkdrop(e.data))
            .catch((err) => console.error("an error occurred while fetching hyperlinks: ", err));
    }, [])


    useEffect(() => {
        if (state) {
            const fetchData = async () => {

                var [tablename, date, filename] = '';
                var carcircus = '';

                if (state?.bannertype === "BAN1") {
                    [tablename, date, filename] = state?.bannerdat?.videoPath.split('/');

                    Services
                        .getImage(tablename, date, filename)
                        .then((res) => setbannervid(res.data))
                        .catch((err) => console.error("Error fetching video", err));

                } else if (state?.bannertype === "BAN2") {

                    carcircus = state?.bannerdat?.imageslider.split(',');
                    [tablename, date, filename] = state?.bannerdat?.imagePath?.split('/');
                    setcarousaldat(carcircus);
                    const carousalImgPromises = carcircus.map(async (ef) => {
                        const [tab, d, fil] = ef.split('/');

                        try {
                            const res = await Services.getImage(tab.trim(), d, fil);
                            return res.data;
                        } catch (err) {
                            console.error("Error fetching Carousal Images: ", err);
                            return null;
                        }
                    });

                    const carousalImgs = await Promise.all(carousalImgPromises);
                    setcarousalimg(carousalImgs);

                    Services
                        .getImage(tablename, date, filename)
                        .then(res => setbannerimg(res.data))
                        .catch((err) => console.error("error fetching homebanner image", err));

                } else {
                    [tablename, date, filename] = state?.bannerdat?.imagePath?.split('/');

                    Services
                        .getImage(tablename, date, filename)
                        .then(res => setbannerimg(res.data))
                        .catch((err) => console.error("error fetching homebanner image", err));

                }

                setbannerform([{ id: state.id, bannertype: state.bannertype, tooltip: state.tooltip }]);
                setbannerdatform([{ id: state?.bannerdat?.id, heading: state?.bannerdat?.heading, title: state?.bannerdat?.title, subtitle: state?.bannerdat?.subtitle, buttonlabel: state?.bannerdat?.buttonlabel, videoPath: state?.bannerdat?.videoPath, imagePath: state?.bannerdat?.imagePath, imageslider: state?.bannerdat?.imageslider, buttonlink: state?.bannerdat?.buttonlink, filename: filename }]);
                setloading(false);
            };

            fetchData();
        }

    }, [])


    const handlebannerfieldchange = (field, value) => {

        if (field === "tooltip") {
            const newfield = [...bannerform];
            newfield[0][field] = value;
            setbannerform(newfield)
        } else if (field === "imageslider") {
            const newfield = [...bannerdatform];
            newfield[0][field] = value.toString();
            setbannerdatform(newfield);
        } else {
            const newfield = [...bannerdatform];
            newfield[0][field] = value;
            setbannerdatform(newfield);
        }
        validation();
    }

    const handleBannersImageChange = (e) => {
        const file = e.target.files[0];
        setbannerimgerr('');

        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            setbannerimg(file);
            setfirsttext("Replaced Background Image");
            handlebannerfieldchange('filename', file.name);
            handlebannerfieldchange('imagePath', `HomeBanners/${datestring}/${file.name}`)
        } else {
            setbannerimgerr('Invalid file type. Please choose a JPEG or PNG Image.');
        }
    }

    const handleBannerCarousalChange = (i, ev) => {
        const file = ev.target.files[0];
        setcarousalimgerr([]);

        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            setcarimgcount((prev) => prev + 1);
            setsecondtext(`Replaced ${carimgcount} carousal images`);
            const newimg = [...carousalimg];
            newimg[i] = file;
            const carstring = carousaldat;
            carstring[i] = `HomeBanners/${datestring}/${file.name}`
            setcarousalimg(newimg);
            handlebannerfieldchange('imageslider', carstring);
        } else {
            const newerr = [...carousalimgerr];
            newerr[i] = 'Invalid file type. Please choose a JPEG or PNG Image.';
            setcarousalimgerr(newerr);
        }
    }

    const handleBannerVideoChange = (e) => {
        const file = e.target.files[0];
        setbannerviderr('');
        setbannervid(null);

        //required for proper preview of videos when user re-uploads
        setTimeout(() => {
            if (file && (file.type === 'video/mp4')) {
                setsecondtext("Replaced Background Video");
                setbannervid(file);
                handlebannerfieldchange('filename', file.name);
                handlebannerfieldchange('videoPath', `HomeBanners/${datestring}/${file.name}`)
            } else {
                setbannerviderr('Invalid file type. Please choose a MP4 Video.');
            }
        }, 0.5);

    }


    const validation = () => {

        const banfromerr = bannerform?.map((e) => {
            const tooltiperr = !e.tooltip.trim();

            return { tooltip: tooltiperr };
        });

        const bandatfromerr = bannerdatform?.map((e) => {
            const headingerr = !e.heading.trim();
            const titleerr = !e.title.trim();
            const subtitleerr = !e.subtitle.trim();
            const buttonlabelerr = !e.buttonlabel.trim();

            return { heading: headingerr, title: titleerr, subtitle: subtitleerr, buttonlabel: buttonlabelerr };
        });

        setbannerformerr(banfromerr);
        setbannerformdaterr(bandatfromerr);

        const banfromerrboo = bannerform?.every((e) => {
            return !!e?.tooltip.trim();
        });

        const bandatfromerrboo = bannerdatform?.every((e) => {
            return e?.buttonlabel.trim() && e?.buttonlink?.trim() && e?.heading.trim() && e?.subtitle.trim() && e?.title.trim();
        });

        if (state?.bannertype === "BAN1") {
            const viderr = bannerdatform?.map((e) => {
                if (!e?.videoPath.trim()) {
                    return "Video is required"
                }
            });

            setbannerviderr(viderr)
        } else if (state?.bannertype === "BAN2") {
            const banimgerr = bannerdatform?.map((e) => {
                if (!e?.imagePath.trim()) {
                    return "Image is required";
                }
            });

            setbannerimgerr(banimgerr);

        } else {
            const commbanimgerr = bannerdatform?.map((e) => {
                if (!e?.imagePath.trim()) {
                    return "Image is required";
                }
            });

            setbannerimgerr(commbanimgerr);
        }

        return banfromerrboo && bandatfromerrboo;

    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating HomePage Banners Successful!',
            }).then(() => navigate('/homebanners'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding HomePage Banners Successful!',
            }).then(() => navigate('/homebanners'))
        }
    }

    const onSubmit = () => {

        if (validation()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: bannerform[0]?.id,
                    tooltip: bannerform[0]?.tooltip,
                    bannertype: bannerform[0]?.bannertype,
                    bannerdat: bannerdatform[0]
                };

                const logdat = {
                    loggeduser: userdat?.username,
                    pagename: "Home Page Banners",
                    operation: "UPDATE",
                    description: `Updated HomeBanner with Banner tooltip ${bannerform[0]?.tooltip}${firsttext != null ? ", " + firsttext : ''}${secondtext != null ? ", " + secondtext : ''} by the LoggedIn user ${userdat?.username} on ${cusdateString}`,
                    datetime: cusdateString
                }

                Services
                    .updatehomebanner(dat)
                    .catch((err) => console.error("Error updating HomeBanners: ", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs: ", err));


                if (state?.bannertype === "BAN1") {

                    let ban1dat = new FormData();

                    ban1dat.append("file", bannervid);
                    ban1dat.append("tablename", "HomeBanners");
                    ban1dat.append("date", datestring);

                    Services
                        .uploadImage(ban1dat)
                        .catch((err) => console.error("Error uploading Video: ", err));

                } else if (state?.bannertype === "BAN2") {
                    let ban2dat = new FormData();

                    ban2dat.append("file", bannerimg);
                    ban2dat.append("tablename", "HomeBanners");
                    ban2dat.append("date", datestring);

                    Services
                        .uploadImage(ban2dat)
                        .catch((err) => console.error("Error uploading background image: ", err));

                    carousalimg.map((et) => {
                        let car2dat = new FormData();

                        car2dat.append("file", et);
                        car2dat.append("tablename", "HomeBanners");
                        car2dat.append("date", datestring);

                        Services
                            .uploadImage(car2dat)
                            .catch((err) => console.error("Error uploading Carousal Images: ", err));
                    })

                } else {
                    let com2dat = new FormData();

                    com2dat.append("file", bannerimg);
                    com2dat.append("tablename", "HomeBanners");
                    com2dat.append("date", datestring);

                    Services
                        .uploadImage(com2dat)
                        .catch((err) => console.error("Error uploading Banner Images: ", err));
                }

            }

            setbannerform([{ id: '', bannertype: '', tooltip: '' }]);
            setbannerdatform([{ id: state?.bannerdat?.id, heading: state?.bannerdat?.heading, title: state?.bannerdat?.title, subtitle: state?.bannerdat?.subtitle, buttonlabel: state?.bannerdat?.buttonlabel, videoPath: state?.bannerdat?.videoPath, imagePath: state?.bannerdat?.imagePath, imageslider: state?.bannerdat?.imageslider, buttonlink: state?.bannerdat?.buttonlink }]);
            setbannerimg();
            setbannerimgerr();
            setbannervid();
            setbannerviderr();
        }
    }
    return (
        <>
            {logincred?.length == 1 ?
                loading ?
                    <Box sx={{ width: '100%', mt: '20%' }}>
                        <Typography align='center' color="#4f329a" variant="body2">
                            One moment while we get everything in order. . .
                        </Typography>
                        <LinearProgress sx={{ mt: 1 }} variant="indeterminate" value={progress} />
                        {/* <Typography align='center' color="#4f329a" variant="body2">
                        One moment while we get everything in order. . .
                    </Typography> */}
                    </Box> :
                    <Box
                        component={'form'}
                    >
                        <Box
                            component={'div'}
                            sx={{ mb: 2 }}
                        >
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to='/homebanners'>
                                    <Typography fontSize={'.875rem'} color="text.primary">
                                        Home Page Banners Table
                                    </Typography>
                                </Link>
                                <Typography fontSize={'.875rem'} color="text.primary">HomePage Banners Form</Typography>
                            </Breadcrumbs>

                        </Box>

                        <Card >
                            <CardHeader
                                title=<Typography fontSize={'1.7rem'} color="text.primary">
                                    HomePage Banners Form
                                </Typography>
                            />

                            <CardContent sx={{ py: 0 }}>
                                <Box
                                    component={'section'}
                                >
                                    <Box
                                        component={'div'}
                                        display='flex'
                                        flexDirection={{ xs: 'column', lg: 'row' }}
                                        gap={{ lg: 3 }}
                                        sx={{ mb: 1 }}
                                    >

                                        <TextField
                                            error={bannerformdaterr[0]?.title}
                                            helperText={bannerformdaterr[0]?.title && 'required'}
                                            id="standard-error-helper-text"
                                            label="Title"
                                            value={bannerdatform[0]?.title}
                                            onChange={(ev) => handlebannerfieldchange('title', ev.target.value)}
                                            variant="standard"
                                            sx={{ flex: 1 }}
                                        />

                                        <TextField
                                            error={bannerformdaterr[0]?.subtitle}
                                            helperText={bannerformdaterr[0]?.subtitle && "required"}
                                            id="standard-error-helper-text"
                                            label="Subtitle"
                                            value={bannerdatform[0]?.subtitle}
                                            onChange={(ev) => handlebannerfieldchange("subtitle", ev.target.value)}
                                            variant="standard"
                                            sx={{ flex: 1 }}
                                        />

                                    </Box>

                                    <Box
                                        component={"div"}
                                        display='flex'
                                        flexDirection={{ xs: 'column', lg: 'row' }}
                                        gap={{ lg: 3 }}
                                        sx={{ mb: 2 }}
                                    >

                                        <TextField
                                            error={bannerformdaterr[0]?.heading}
                                            helperText={bannerformdaterr[0]?.heading && 'required'}
                                            id="standard-error-helper-text"
                                            label="Heading"
                                            value={bannerdatform[0]?.heading}
                                            onChange={(ev) => handlebannerfieldchange('heading', ev.target.value)}
                                            variant="standard"
                                            sx={{ flex: 1 }}
                                        />

                                        <TextField
                                            error={bannerformdaterr[0]?.buttonlabel}
                                            helperText={bannerformdaterr[0]?.buttonlabel && "required"}
                                            id="standard-error-helper-text"
                                            label="Button Label"
                                            value={bannerdatform[0]?.buttonlabel}
                                            onChange={(ev) => handlebannerfieldchange("buttonlabel", ev.target.value)}
                                            variant="standard"
                                            sx={{ flex: 1 }}
                                        />

                                        <TextField
                                            error={bannerformerr[0]?.tooltip}
                                            helperText={bannerformerr[0]?.tooltip && "required"}
                                            id="standard-error-helper-text"
                                            label="Tooltip"
                                            value={bannerform[0]?.tooltip}
                                            onChange={(ev) => handlebannerfieldchange("tooltip", ev.target.value)}
                                            variant="standard"
                                            sx={{ flex: 1 }}
                                        />

                                        <FormControl variant="standard" sx={{ flex: 1 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Button Link</InputLabel>
                                            <Select
                                                // error={true}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={bannerdatform[0]?.buttonlink}
                                                onChange={(ev) => handlebannerfieldchange("buttonlink", ev.target.value)}
                                                label="Button Link"
                                            >
                                                {linkdrop?.map((ef) => (
                                                    <MenuItem value={ef?.link}>{ef?.description}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>

                                    </Box>

                                    {bannerform[0]?.bannertype === "BAN1"
                                        ? (
                                            <Box
                                                component={'div'}
                                            >
                                                {bannervid ?
                                                    <Button
                                                        color='warning'
                                                        variant="contained"
                                                        component="label"
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        Re-Upload Video
                                                        <input
                                                            hidden
                                                            accept='video/mp4'
                                                            type="file"
                                                            onChange={(ev) => handleBannerVideoChange(ev)}
                                                        />
                                                    </Button>
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        Upload Video
                                                        <input
                                                            hidden
                                                            accept='video/mp4'
                                                            type="file"
                                                            onChange={(ev) => handleBannerVideoChange(ev)}
                                                        />
                                                    </Button>
                                                }

                                                {(bannerviderr) && (
                                                    <Typography color="error" variant="body2">
                                                        {bannerviderr}
                                                    </Typography>
                                                )}

                                                <Box
                                                    component={'div'}
                                                    mt={2}
                                                >
                                                    {bannervid && (
                                                        <Box
                                                            component="ul"
                                                            sx={{ width: 600, p: 0, m: 0 }}
                                                        >
                                                            <Card component="li"
                                                                sx={{ flexGrow: 1 }}
                                                            // sx={{ flex: 2 }}
                                                            >
                                                                <video
                                                                    autoPlay
                                                                    loop
                                                                    muted
                                                                    width={600}
                                                                >
                                                                    <source
                                                                        src={URL.createObjectURL(bannervid)}
                                                                        type="video/mp4"
                                                                    />
                                                                </video>
                                                            </Card>
                                                        </Box>
                                                    )}

                                                </Box>

                                            </Box>
                                        ) : bannerform[0]?.bannertype === "BAN2"
                                            ? (
                                                <Box
                                                    component={'div'}
                                                    sx={{ display: 'flex', flexDirection: 'column' }}
                                                >

                                                    <Typography color={'GrayText'} level="body-sm">{`Image Carousal (Preferred Dimensions - 2232 x 3036)`}</Typography>

                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flex: 1,
                                                            gap: 1,
                                                            py: 1,
                                                            overflow: 'auto',
                                                            scrollSnapType: 'x mandatory',
                                                            '& > *': {
                                                                scrollSnapAlign: 'center',
                                                            },
                                                            '::-webkit-scrollbar': { display: 'none' },
                                                        }}
                                                    >

                                                        {carousalimg.map((item, imf) => (
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                            >
                                                                <Box
                                                                >
                                                                    <img
                                                                        src={URL.createObjectURL(item)}
                                                                        height={190}
                                                                        style={{ objectFit: 'cover' }}
                                                                        alt="image"
                                                                    />
                                                                </Box>

                                                                {item ?
                                                                    <Box sx={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center', mx: 1, mb: 1 }}>
                                                                        <Button
                                                                            color='warning'
                                                                            variant="contained"
                                                                            component="label"
                                                                            sx={{ fontSize: 12 }}
                                                                            startIcon={<CloudUploadIcon />}
                                                                        >
                                                                            Re-Upload
                                                                            <input
                                                                                hidden
                                                                                accept="image/jpeg, image/png"
                                                                                type="file"
                                                                                onChange={(ev) => handleBannerCarousalChange(imf, ev)}
                                                                            />
                                                                        </Button>
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ whiteSpace: 'nowrap', mx: 1, mb: 1 }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            component="label"
                                                                            startIcon={<CloudUploadIcon />}
                                                                        >
                                                                            Upload Image
                                                                            <input
                                                                                hidden
                                                                                accept="image/jpeg, image/png"
                                                                                type="file"
                                                                                onChange={(ev) => handleBannerCarousalChange(imf, ev)}
                                                                            />
                                                                        </Button>
                                                                    </Box>
                                                                }

                                                                {(carousalimgerr[imf]) && (
                                                                    <Typography color="error" variant="body2" fontSize={12}>
                                                                        {carousalimgerr[imf]}
                                                                    </Typography>
                                                                )}

                                                            </Box>
                                                        ))}
                                                    </Box>

                                                    <Box
                                                        component={'div'}
                                                    >
                                                        <Typography color={'GrayText'} sx={{ mt: 2, mb: 1 }} level="body-sm">Background Image</Typography>

                                                        {bannerimg ?
                                                            <Button
                                                                color='warning'
                                                                variant="contained"
                                                                component="label"
                                                                startIcon={<CloudUploadIcon />}
                                                            >
                                                                Re-Upload BG Image
                                                                <input
                                                                    hidden
                                                                    accept="image/jpeg, image/png"
                                                                    type="file"
                                                                    onChange={(ev) => handleBannersImageChange(ev)}
                                                                />
                                                            </Button>
                                                            :
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                startIcon={<CloudUploadIcon />}
                                                            >
                                                                Upload BG Image
                                                                <input
                                                                    hidden
                                                                    accept="image/jpeg, image/png"
                                                                    type="file"
                                                                    onChange={(ev) => handleBannersImageChange(ev)}
                                                                />
                                                            </Button>
                                                        }

                                                        {(bannerimgerr) && (
                                                            <Typography color="error" variant="body2">
                                                                {bannerimgerr}
                                                            </Typography>
                                                        )}

                                                        <Box
                                                            component={'div'}
                                                            mt={2}
                                                        >
                                                            {bannerimg && (
                                                                <img src={URL.createObjectURL(bannerimg)} alt="image" height={100} width={100} />
                                                            )}
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component={'div'}
                                                >
                                                    {bannerimg ?
                                                        <Button
                                                            color='warning'
                                                            variant="contained"
                                                            component="label"
                                                            startIcon={<CloudUploadIcon />}
                                                        >
                                                            Re-Upload Image
                                                            <input
                                                                hidden
                                                                accept="image/jpeg, image/png"
                                                                type="file"
                                                                onChange={(ev) => handleBannersImageChange(ev)}
                                                            />
                                                        </Button>
                                                        :
                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            startIcon={<CloudUploadIcon />}
                                                        >
                                                            Upload Image
                                                            <input
                                                                hidden
                                                                accept="image/jpeg, image/png"
                                                                type="file"
                                                                onChange={(ev) => handleBannersImageChange(ev)}
                                                            />
                                                        </Button>
                                                    }

                                                    {(bannerimgerr) && (
                                                        <Typography color="error" variant="body2">
                                                            {bannerimgerr}
                                                        </Typography>
                                                    )}

                                                    <Box
                                                        component={'div'}
                                                        mt={2}
                                                    >
                                                        {bannerimg && (
                                                            <img src={URL.createObjectURL(bannerimg)} alt="image" height={100} width={100} />
                                                        )}
                                                    </Box>

                                                </Box>
                                            )}

                                    <Box
                                        component={'div'}
                                        display={'flex'}
                                        justifyContent={{ xs: 'center', md: 'flex-end' }}
                                        sx={{ mt: 2 }}
                                    >
                                        <Button variant="contained" onClick={() => onSubmit()}>
                                            Save
                                        </Button>
                                    </Box>

                                </Box>
                            </CardContent>
                        </Card>

                    </Box> : <></>}

        </>
    )
}

export default HomeBannersForm;