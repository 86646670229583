import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import moment from 'moment';
import LogServices from '../LogServices/LogServices';
import FaviconServices from './FaviconServices';

const Favicon = () => {

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const currentDate = new Date();
    const datestring = moment(currentDate).format('YYYY-MM-DD');
    const cusDateString = moment(currentDate).format("llll");

    const [id, setid] = useState(1);
    const [imagePath, setimagePath] = useState(null);
    const [filename, setfilename] = useState(null);
    const [faviconimg, setfaviconimg] = useState(null);
    const [faviconimgerr, setfaviconimgerr] = useState('');

    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const [faviconimgtext, setfaviconimgtext] = useState(null);

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Footer"));

        setlogincred(ef);

        if (ef?.length == 1) {
            console.log("If part");
        } else {
            console.log("Else part");
            Swal.fire({
                icon: 'error',
                title: 'UnAuthorized Access',
                text: 'Please Login to continue',
            }).then(() => navigate('/'))
        }
    }, [])

    useEffect(() => {

        FaviconServices
            .getfaviconbyid(id)
            .then((ev) => {
                const [tablename, date, filename] = ev?.data?.imagePath.split('/');
                setimagePath(ev?.data?.imagePath);

                FaviconServices
                    .getImage(tablename, date, filename)
                    .then((res) => setfaviconimg(res.data))
                    .catch((err) => console.error("Error fetching Favicon Image: ", err));

            });

    }, []);


    const handleFaviconImageChange = (e) => {
        const file = e.target.files[0];
        setfaviconimgerr('');

        if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
            const imgpath = `Favicon/${datestring}/${file.name}`
            setfaviconimgtext(`Replaced Favicon Image`);
            setfaviconimg(file);
            setimagePath(imgpath);
            setfilename(file.name);

        } else {
            setfaviconimgerr("Invaid file type. Please choose a JPEG or PNG image.");
        }
    }

    const validation = () => {

        setfaviconimgerr('');
        const imgerr = !filename.trim() ? "Image is required" : "";

        setfaviconimgerr(imgerr);

        const errboo = filename.trim().length > 2;

        return errboo;

    };

    const invokeAlert = () => {

        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Updating Favicon Successful!',
        })

    }

    const onSubmit = () => {

        if (validation()) {
            invokeAlert();

            const dat = {
                id: id,
                imagePath: imagePath
            };

            const logdat = {
                loggeduser: userdat.username,
                pagename: "Favicon",
                operation: "UPDATE",
                description: `Updated Favicon${faviconimgtext != null ? ", " + faviconimgtext : ''} by the LoggedIn user ${userdat.username} on ${cusDateString}`,
                datetime: cusDateString
            };

            FaviconServices
                .updatefavicon(dat)
                .catch((err) => console.error("Error Updating Favicon Image", err));

            LogServices
                .addlogs(logdat)
                .catch((err) => console.error("Error Adding Logs: ", err));

            let formdata = new FormData();

            formdata.append("file", faviconimg);
            formdata.append("tablename", "Favicon");
            formdata.append("date", datestring);

            FaviconServices
                .uploadImage(formdata)
                .catch((err) => console.error("Error updating Favicon Image: ", err));

        }
        setfaviconimgtext(null);
    }

    return (
        <>
            {logincred?.length == 1 ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography fontSize={'.875rem'} color="text.primary">
                                Mannai Home
                            </Typography>
                            <Typography fontSize={'.875rem'} color="text.primary">Favicon</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                Favicon
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >
                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    sx={{ mb: 1 }}
                                >

                                    <Box
                                        component={'div'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        sx={{ flex: 1 }}
                                    >

                                        <Typography color={'GrayText'} align='center' level="body-sm">{`Favicon Image`}</Typography>
                                        <Typography color={'GrayText'} sx={{ mb: 1 }} align='center' level="body-sm">{`(Upload Image with preferred dimensions 16 x 16, not exceeding 8MB)`}</Typography>

                                        {faviconimg ?
                                            <Button
                                                color='warning'
                                                variant="contained"
                                                component="label"
                                                sx={{ fontSize: 12 }}
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Re-Upload Image
                                                <input
                                                    hidden
                                                    accept="image/jpeg, image/png"
                                                    type="file"
                                                    onChange={(e) => handleFaviconImageChange(e)}
                                                />
                                            </Button>
                                            :
                                            <Button
                                                variant="contained"
                                                component="label"
                                                sx={{ fontSize: 12 }}
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Upload Image
                                                <input
                                                    hidden
                                                    accept="image/jpeg, image/png"
                                                    type="file"
                                                    onChange={(e) => handleFaviconImageChange(e)}
                                                />
                                            </Button>
                                        }

                                        <Box
                                            component={'div'}
                                            mt={2}
                                        >
                                            {faviconimg && (
                                                <img src={URL.createObjectURL(faviconimg)} alt="image" height={190} style={{ objectFit: 'cover' }} />
                                            )}
                                        </Box>

                                        {faviconimgerr && (
                                            <Typography color="error" align='center' variant="body2">
                                                {faviconimgerr}
                                            </Typography>
                                        )}

                                    </Box>
                                </Box>

                            </Box>

                            <Box
                                component={'div'}
                                display={'flex'}
                                justifyContent={{ xs: 'center', md: 'flex-end' }}
                                sx={{ mt: 2 }}
                            >
                                <Button variant="contained"
                                    onClick={() => onSubmit()}
                                >
                                    Save
                                </Button>
                            </Box>

                        </CardContent>
                    </Card>

                </Box > : <></>
            }

        </>
    )
}

export default Favicon