import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import moment from 'moment';
import LogServices from 'app/pages/LogServices/LogServices';
import SuperAdminServices from '../SuperAdminServices';

const MasterMenusForm = () => {

    let { state } = useLocation();

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const [formdata, setformdata] = useState([{ id: '', menuname: '', description: '' }]);
    const [formerr, setformerr] = useState([]);

    const [logincred, setlogincred] = useState();
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format("llll");

    useEffect(() => {
        const ef = userdat?.rolename.includes("SUPER_ADMIN") ? true : false;
    
        setlogincred(ef);
    
        if (ef) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        if (state) {
            setformdata([{ id: state.id, menuname: state.menuname, description: state.description }]);
        }
    }, []);

    const handleformfieldchange = (field, value) => {
        const newfield = [...formdata];
        newfield[0][field] = value;
        setformdata(newfield);
        validation();
    };

    const validation = () => {

        const formerror = formdata.map((e) => {
            const menunameerr = !e.menuname.trim();
            const descriptionerr = !e.description.trim();

            return { menuname: menunameerr, description: descriptionerr };
        });

        setformerr(formerror);

        const formerrboo = formdata.every((e) => {
            return e.menuname.trim() && e.description.trim();
        });

        return formerrboo;
    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating MasterMenus Successful!',
            }).then(() => navigate('/mastermenus'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding Mastermenus Successful!',
            }).then(() => navigate('/mastermenus'))
        }
    }

    const onSubmit = () => {

        if (validation()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: formdata[0]?.id,
                    menuname: formdata[0]?.menuname,
                    description: formdata[0]?.description
                };

                const logdat = {
                    loggeduser: userdat.username,
                    pagename: "Master Menus",
                    operation: "UPDATE",
                    description: `Updated MasterMenus with MenuName: ${formdata[0]?.menuname} by the LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                };

                SuperAdminServices
                    .updatemenumaster(dat)
                    .catch((err) => console.error("Error Updating MasterMenus", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error adding Logs", err));

            } else {
                const dat = {
                    menuname: formdata[0]?.menuname,
                    description: formdata[0]?.description
                };

                const logdat = {
                    loggeduser: userdat?.username,
                    pagename: "Master Menus",
                    operation: "ADD",
                    description: `Added MasterMenus by LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                }

                SuperAdminServices
                    .addmenumaster(dat)
                    .catch((err) => console.error("error adding menumaster", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs: ", err));
            }
            setformdata([{ id: '', menuname: '', description: '' }]);
        }

    }

    return (
        <>
            {logincred ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/mastermenus'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    Master Menus Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">Master Menus Form</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                Master Menus Form
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >
                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >

                                    <TextField
                                        error={formerr[0]?.menuname}
                                        helperText={formerr[0]?.menuname && 'required'}
                                        id="standard-error-helper-text"
                                        label="Menu Name"
                                        value={formdata[0]?.menuname}
                                        onChange={(ev) => handleformfieldchange('menuname', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.description}
                                        helperText={formerr[0]?.description && 'required'}
                                        id="standard-error-helper-text"
                                        label="Description"
                                        multiline
                                        value={formdata[0]?.description}
                                        onChange={(ev) => handleformfieldchange('description', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                </Box>

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                                    sx={{ mt: 2 }}
                                >
                                    <Button variant="contained" onClick={() => onSubmit()}>
                                        Save
                                    </Button>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>

                </Box> : <></>}

        </>
    )
}

export default MasterMenusForm;