import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Card, CardContent, CardHeader, IconButton, Input, InputAdornment, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import moment from 'moment';
import LogServices from 'app/pages/LogServices/LogServices';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MannaiLogs = () => {
    const [data, setdata] = useState([]);
    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);

    const [loading, setloading] = useState(true);
    const [progress, setProgress] = useState(0);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState();
    const userdat = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const ef = userdat?.rolename.includes("SUPER_ADMIN") ? true : false;

        setlogincred(ef);

        if (ef) {
            console.log("If part");
        } else {
            console.log("Else part");
            Swal.fire({
                icon: 'error',
                title: 'UnAuthorized Access',
                text: 'Please Login to continue',
            }).then(() => navigate('/'))
        }
    }, [])

    useEffect(() => {

        if (loading) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        return 0;
                    }
                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };
        } else {
            setProgress(0);
        }

    }, [loading])

    useEffect(() => {

        LogServices
            .getlogs()
            .then((e) => {
                const sorted = e?.data?.sort((a, b) => moment(a?.datetime).format() < moment(b?.datetime).format() ? 1 : -1);
                setdata(sorted);
                setpuredat(e?.data);
                setloading(false);
            })
            .catch((err) => console.error("Error fetching Logs: ", err));
    }, []);


    const handleSearch = (hes) => {
        console.log("HES: ", hes);
        console.log("Pure: ", puredat);
        console.log("TIMETOO: ", puredat?.datetime);

        // const filtered = puredat?.filter((es) => es?.loggeduser.toLowerCase().includes(hes.toLowerCase()) || es?.pagename.toLowerCase().includes(hes.toLowerCase()) || es?.operation.toLowerCase().includes(hes.toLowerCase()));
        const filtered = puredat?.filter((es) => es?.loggeduser.toLowerCase().includes(hes.toLowerCase()) || es?.pagename.toLowerCase().includes(hes.toLowerCase()) || es?.operation.toLowerCase().includes(hes.toLowerCase()) || es?.datetime?.includes(hes));
        console.log("FILTE: ", filtered);

        setTimeout(() => {
            setdata(filtered);
        }, 400);
    };

    const handleSort = () => {
        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            const sorted = data?.sort((a, b) => moment(a?.datetime).format() > moment(b?.datetime).format() ? 1 : -1);
            setTimeout(() => {
                setdata(sorted);
            }, 100);
        } else {
            const sorted = data?.sort((a, b) => moment(a?.datetime).format() < moment(b?.datetime).format() ? 1 : -1);
            setTimeout(() => {
                setdata(sorted);
            }, 100);
        }
    };


    return (
        <>
            {logincred ?
                loading ?
                    <Box sx={{ width: '100%', mt: '20%' }}>
                        <Typography align='center' color="#4f329a" variant="body2">
                            One moment while we get everything in order. . .
                        </Typography>
                        <LinearProgress sx={{ mt: 1 }} variant="indeterminate" value={progress} />
                        {/* <Typography align='center' color="#4f329a" variant="body2">
                        One moment while we get everything in order. . .
                    </Typography> */}
                    </Box>
                    :
                    <Card >
                        <CardHeader
                            sx={{
                                '& .MuiCardHeader-action': { alignSelf: 'center' },
                            }}
                            action={
                                <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                    <Tooltip title="Search Logs">
                                        <Input
                                            id="input-with-icon-adornment"
                                            placeholder='Search'
                                            onChange={(ev) => handleSearch(ev.target.value)}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchOutlinedIcon fontSize='small' color='action' />
                                                </InputAdornment>
                                            }
                                        />
                                    </Tooltip>

                                    <Tooltip title="Sort Logs">
                                        <IconButton onClick={() => handleSort()}>
                                            <Link>
                                                <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                            </Link>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            }

                            title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                                Logs
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Sl No</StyledTableCell>
                                            <StyledTableCell align="center">User LoggedIn</StyledTableCell>
                                            <StyledTableCell align="center">Page Name</StyledTableCell>
                                            <StyledTableCell width={100} align="center">Operation</StyledTableCell>
                                            <StyledTableCell width={240} align="center">Timestamp</StyledTableCell>
                                            <StyledTableCell align="center">Description</StyledTableCell>
                                            {/* <StyledTableCell align="center">Country</StyledTableCell>
                                        <StyledTableCell align="center">PostalCode</StyledTableCell>
                                        <StyledTableCell align="center">Designation</StyledTableCell>
                                        <StyledTableCell align="center">No. of users required</StyledTableCell> */}
                                            {/* <StyledTableCell align="center">Last Name</StyledTableCell> */}
                                            {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((e, i) => {
                                            return (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align='center' component="th" scope="row">{i + 1}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e?.loggeduser}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e?.pagename}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e?.operation}</StyledTableCell>
                                                    <StyledTableCell align="center" component="th" scope="row">{e?.datetime}</StyledTableCell>
                                                    <StyledTableCell align='center' component="th" scope="row">{e?.description}</StyledTableCell>
                                                    {/* <StyledTableCell align="center" component="th" scope="row">{e.country}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">{e.postalcode}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">{e.designation}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">{e.noofusersrequired}</StyledTableCell> */}
                                                    {/* <StyledTableCell align="center" component="th" scope="row">
                                                    <Stack justifyContent="center" direction="row" alignItems="center" spacing={-0.3}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => deletemannaiholiday(e.id)}>
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </StyledTableCell> */}
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>

                    </Card> : <></>}

        </>
    )
}

export default MannaiLogs;