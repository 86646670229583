import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class FaviconServices {

    addfavicon(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addfavicon`, dat)
    }

    updatefavicon(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatefavicon`, dat);
    };

    getfaviconbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getfavicon/${id}`)
    }

    //Image Uploading
    uploadImage(formdata) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addimage`, formdata);
    };

    //Image Retrieving
    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
    };
}

export default new FaviconServices();