import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class MannaiFooterServices {

    addfooter(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addfooter`, dat);
    };

    updatefooter(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatefooter`, dat);
    };

    getfooter(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getfooter/${id}`);
    };

    //Image Uploading
    uploadImage(formdata) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addimage`, formdata);
    };

    //Image Retrieving
    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
    };
}

export default new MannaiFooterServices();