import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Services from '../FormServices';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import moment from 'moment';
import LogServices from 'app/pages/LogServices/LogServices';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const GlobalPartner = () => {
    const [data, setdata] = useState([]);
    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currDate = new Date();
    const cusdateString = moment(currDate).format("llll");

    useEffect(() => {
        if (userdat?.username !== "mannai") {
            console.log("If part");
            Swal.fire({
                icon: 'error',
                title: 'Access Denied',
                text: 'Please Login to continue',
            }).then(() => navigate('/'))
        }
        console.log("Else part");
    }, [])

    useEffect(() => {
        Services
            .getformcommon()
            .then((e) => {
                const btraveldat = e?.data?.filter((ev) => ev?.formname === "Global Partner")
                setdata(btraveldat);
                setpuredat(btraveldat);
            })
            .catch((err) => console.error("Error fetching Global Partner", err));
    }, []);

    const deletemannaiholiday = (id) => {

        if (id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat.username,
                        pagename: "Global Partner",
                        operation: "DELETE",
                        description: `Deleted Global Partner Form Data by the LoggedIn user ${userdat.username} on ${cusdateString}`,
                        datetime: cusdateString
                    };

                    Services
                        .deleteformcommon(id)
                        .then(() => Services.getformcommon())
                        .then((e) => {
                            const btraveldat = e?.data.filter((ev) => ev?.formname === "Global Partner");
                            setdata(btraveldat);
                            setpuredat(btraveldat);
                        })
                        .catch((err) => console.error("Error deleting Global Partner", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };


    const handleSearch = (hes) => {
        const filtered = puredat?.filter((es) => es?.fname.toLowerCase().includes(hes.toLowerCase()) || es?.lname.toLowerCase().includes(hes.toLowerCase()) || es?.companyname.toLowerCase().includes(hes.toLowerCase()) || es?.workemail.toLowerCase().includes(hes.toLowerCase()) || es?.phno.toLowerCase().includes(hes.toLowerCase()) || es?.country.toLowerCase().includes(hes.toLowerCase()) || es?.postalcode.toLowerCase().includes(hes.toLowerCase()) || es?.designation.toLowerCase().includes(hes.toLowerCase()) || es?.howdidyouhear.toLowerCase().includes(hes.toLowerCase()) || es?.noofusersrequired.toLowerCase().includes(hes.toLowerCase()));
        setdata(filtered);
    };

    const handleSort = () => {
        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            const sorted = data?.sort((a, b) => a?.fname < b?.fname ? 1 : -1);
            setdata(sorted);
        } else {
            const sorted = data?.sort((a, b) => a?.fname > b?.fname ? 1 : -1);
            setdata(sorted);
        }
    };


    return (
        <>
            {userdat?.username === "mannai" ?
                <Card >
                    <CardHeader
                        sx={{
                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                        }}
                        action={
                            <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                <Tooltip title="Search Global Partner">
                                    <Input
                                        id="input-with-icon-adornment"
                                        placeholder='Search'
                                        onChange={(ev) => handleSearch(ev.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchOutlinedIcon fontSize='small' color='action' />
                                            </InputAdornment>
                                        }
                                    />
                                </Tooltip>

                                <Tooltip title="Sort Global Partner">
                                    <IconButton onClick={() => handleSort()}>
                                        <Link>
                                            <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }

                        title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                            Global Partner
                        </Typography>
                    />

                    <CardContent sx={{ py: 0 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Sl No</StyledTableCell>
                                        <StyledTableCell align="center">First Name</StyledTableCell>
                                        <StyledTableCell align="center">Last Name</StyledTableCell>
                                        <StyledTableCell align="center">Company Name</StyledTableCell>
                                        <StyledTableCell align="center">Email</StyledTableCell>
                                        <StyledTableCell align="center">Phone No</StyledTableCell>
                                        <StyledTableCell align="center">Country</StyledTableCell>
                                        <StyledTableCell align="center">PostalCode</StyledTableCell>
                                        <StyledTableCell align="center">Designation</StyledTableCell>
                                        <StyledTableCell align="center">No. of users required</StyledTableCell>
                                        {/* <StyledTableCell align="center">Last Name</StyledTableCell> */}
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((e, i) => {
                                        return (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align='center' component="th" scope="row">{i + 1}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">{e.fname}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">{e.lname}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">{e.companyname}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">{e.workemail}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">{e.phno}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">{e.country}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">{e.postalcode}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">{e.designation}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">{e.noofusersrequired}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">
                                                    <Stack justifyContent="center" direction="row" alignItems="center" spacing={-0.3}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => deletemannaiholiday(e.id)}>
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>

                </Card> : <></>}

        </>
    )
}

export default GlobalPartner;