import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Breadcrumbs, Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import services from './services';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import moment from 'moment';
import LogServices from '../LogServices/LogServices';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const NewsSections = () => {

    const [newssubdat, setnewssubdat] = useState([]);
    const [newsid, setnewsid] = useState('');
    let { state } = useLocation();

    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format('llll');

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("News"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])


    useEffect(() => {
        setnewsid(state.id);
        setnewssubdat(state.newssubsection);
        setpuredat(state.newssubsection);
    }, []);

    const deletenewssubsections = (e) => {
        if (e.id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat.username,
                        pagename: "News > News Sections",
                        operation: "DELETE",
                        description: `Deleted NewsSubSection with Title: ${e.title} by the LoggedIn User ${userdat.username} on ${cusDateString}`,
                        datetime: cusDateString
                    };

                    services
                        .deletenewssubsection(e.id)
                        .then(() => services.getnewsbyid(newsid))
                        .then((res) => {
                            setnewssubdat(res.data.newssubsection);
                            setpuredat(res?.data?.newssubsection)
                        })
                        .catch((err) => console.error("Error Deleting NewsSubSection: ", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };

    const handleSearch = (des) => {
        const filtered = puredat?.filter((es) => es?.title.toLowerCase().includes(des.toLowerCase()) || es?.description.toLowerCase().includes(des.toLowerCase()));
        setnewssubdat(filtered);
    }

    const handleSort = () => {
        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            const sorted = newssubdat?.sort((a, b) => a?.title > b?.title ? 1 : -1);
            setnewssubdat(sorted);
        } else {
            const sorted = newssubdat?.sort((a, b) => a?.title < b?.title ? 1 : -1);
            setnewssubdat(sorted);
        }
    }

    return (
        <>
            {logincred?.length == 1 ?
                <>
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/news'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    News Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">News Sections</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            sx={{
                                '& .MuiCardHeader-action': { alignSelf: 'center' },
                            }}

                            action={
                                <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                    <Tooltip title="Search NewsSubSection">
                                        <Input
                                            id="input-with-icon-adornment"
                                            placeholder='Search'
                                            onChange={(ev) => handleSearch(ev.target.value)}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchOutlinedIcon fontSize='small' color='action' />
                                                </InputAdornment>
                                            }
                                        />
                                    </Tooltip>

                                    <Tooltip title="Sort NewsSubSection">
                                        <IconButton onClick={() => handleSort()}>
                                            <Link>
                                                <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                            </Link>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            }

                            title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                                NewsSubSections
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Sl No</StyledTableCell>
                                            <StyledTableCell align="left">Title</StyledTableCell>
                                            <StyledTableCell align="left">Description</StyledTableCell>
                                            {/* <StyledTableCell align="center">Image Path</StyledTableCell>
                                    <StyledTableCell align="center">Image Header</StyledTableCell> */}
                                            <StyledTableCell align="left">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {newssubdat.map((e, i) => {

                                            return (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align='left' component="th" scope="row">{i + 1}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e.title}</StyledTableCell>
                                                    <StyledTableCell align='left' component="th" scope="row">{e.description}</StyledTableCell>
                                                    {/* <StyledTableCell align='center' component="th" scope="row">{e.imagePath}</StyledTableCell>
                                            <StyledTableCell align='center' component="th" scope="row">{e.imageheader}</StyledTableCell> */}
                                                    <StyledTableCell align="left" component="th" scope="row">
                                                        <Stack justifyContent={'flex-start'} direction="row" alignItems="center" spacing={-0.3}>

                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={() => deletenewssubsections(e)}>
                                                                    <DeleteIcon color='error' />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </Stack>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </> : <></>}


        </>
    )
}

export default NewsSections