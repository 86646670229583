import React from 'react';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const Footer = () => {

    const date = new Date();
    const year = date.getFullYear();

    return (
        <Div sx={{
            py: 2,
            px: {lg: 6, xs: 4},
            borderTop: 2,
            borderColor: 'divider',
            bgcolor: 'background.paper',
        }}>
            {/* <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant={"body1"} color={"text.primary"}>Copyright Company Name © 2022</Typography>
                <Div sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h5"  sx={{display: { xs: 'none', sm: 'block' }, mb: 0, mr: 2, color: 'primary.main'}}>$24 Only</Typography>
                    <Button variant="contained">Buy Now</Button>
                </Div>
            </Div> */}

            <Div sx={{display: 'flex', alignItems: 'center'}}>
                <Div sx={{mr:1}}>
                    {/* <Typography variant="h5"  sx={{display: { xs: 'none', sm: 'block' }, mb: 0, mr: 2, color: 'primary.main'}}>$24 Only</Typography> */}
                    <a href="https://jnanasoft.com/" target='_blank'>
                    <img style={{height:'30px'}} src={`${ASSET_IMAGES}/jncmshlogo.png`} alt="Jnana" />
                    {/* <Button variant="contained">Buy Now</Button> */}
                    </a>
                </Div>
                <Typography variant={"body1"} color={"text.primary"}>Copyright © {year} | Jnana Software Technologies Pvt Ltd </Typography>
            </Div>
        </Div>
    );
};

export default Footer;
