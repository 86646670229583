import React from "react";
// import dashboardRoutes from "./dashboardRoutes";
// import metricsRoutes from "./metricsRoutes";
// import widgetsRoutes from "./widgetRoutes";
// import calendarRoutes from "./calendarRoutes";
// import chartRoutes from "./chartRoutes";
// import mapRoutes from "./mapRoutes";
// import extensionsRoutes from "./extensionsRoutes";
// import {Navigate} from "react-router-dom";
// import extraRoutes from "./extraRoutes";
// import appsRoutes from "./appsRoutes";
// import {muiRoutes} from "./muiRoutes";
// import authRoutes from "./authRoutes";
// import userRoutes from "./userRoutes";
// import listViewRoutes from "./listViewRoutes";
// import gridViewRoutes from "./gridViewRoutes";
import cmsRoutes from "./cmsRoutes";
import Login1 from "app/pages/auth-pages/login1";
// import Auth from "./middleware/Auth";
// import Admin from "./middleware/Admin";
import { element } from "prop-types";
import Auth from "./middleware/Auth";
import Blog from "app/pages/blogs/Blog";
import Menus from "app/pages/menus/Menus";
import News from "app/pages/news/News";
import HyperLinks from "app/pages/hyperlinks/HyperLinks";
import Subscribers from "app/pages/subscribers/Subscribers";
import BlogForm from "app/pages/blogs/BlogForm";
import MenuForm from "app/pages/menus/MenuForm";
import NewsForm from "app/pages/news/NewsForm";
import IntroBanner from "app/pages/introbanner/IntroBanner";
import HomeBanners from "app/pages/homebanners/HomeBanners";
import HomeBannersForm from "app/pages/homebanners/HomeBannersForm";
import HyperLinksForm from "app/pages/hyperlinks/HyperLinksForm";
import IntroBannerForm from "app/pages/introbanner/IntroBannerForm";
import Faqs from "app/pages/faqs/Faqs";
import FaqsForm from "app/pages/faqs/FaqsForm";
import SocialMedia from "app/pages/socialmedia/SocialMedia";
import BlogSections from "app/pages/blogs/BlogSections";
import NewsSections from "app/pages/news/NewsSections";
import SocialMediaForm from "app/pages/socialmedia/SocialMediaForm";
import UploadBanners from "app/pages/ckc/UploadBanners/UploadBanners";
import UploadBannersForm from "app/pages/ckc/UploadBanners/UploadBannersForm";
import SavingPlans from "app/pages/ckc/SavingPlans/SavingPlans";
import SavingPlansForm from "app/pages/ckc/SavingPlans/SavingPlansForm";
import UploadHomePage from "app/pages/ckc/UploadHomePage/UploadHomePage";
import UploadHomePageForm from "app/pages/ckc/UploadHomePage/UploadHomePageForm";
import UploadLogin from "app/pages/ckc/UploadLogin/UploadLogin";
import UploadLoginForm from "app/pages/ckc/UploadLogin/UploadLoginForm";
import UploadSignUp from "app/pages/ckc/UploadSignUp/UploadSignUp";
import UploadSignUpForm from "app/pages/ckc/UploadSignUp/UploadSignUpForm";
import TermsAndConditions from "app/pages/ckc/TermsAndConditions/TermsAndConditions";
import Home from "app/pages/home";
import BusinessTravel from "app/pages/MannaiForms/BusinessTravel/BusinessTravel";
import MannaiHoliday from "app/pages/MannaiForms/MannaiHoliday/MannaiHoliday";
import Technologies from "app/pages/MannaiForms/Technologies/Technologies";
import SpaceTravel from "app/pages/MannaiForms/SpaceTravel/SpaceTravel";
import SpaceCargo from "app/pages/MannaiForms/SpaceCargo/SpaceCargo";
import ContactUs from "app/pages/MannaiForms/ContactUs/ContactUs";
import MannaiFooter from "app/pages/MannaiFooter/MannaiFooter";
import MannaiLogs from "app/pages/MannaiLogs/MannaiLogs";
import GlobalPartner from "app/pages/MannaiForms/GlobalPartner/GlobalPartner";
import CustomerQueries from "app/pages/MannaiForms/CustomerQueries/CustomerQueries";
import Roles from "app/pages/superadmin/Roles/Roles";
import RolesForm from "app/pages/superadmin/Roles/RolesForm";
import MasterMenus from "app/pages/superadmin/MasterMenus/MasterMenus";
import MasterMenusForm from "app/pages/superadmin/MasterMenus/MasterMenusForm";
import Users from "app/pages/superadmin/Users/Users";
import UsersForm from "app/pages/superadmin/Users/UsersForm";
import Favicon from "app/pages/favicon/Favicon";

const routes = [
    {
        path: "/",
        element: <Login1 />,
    },
    //DashBoard --commented out --starts
    // {
    //     path: "/home",
    //     element: <Home />
    // },
    //DashBoard --commented out --ends
    {
        path: "/blog",
        element: <Blog />,
    },

    {
        path: "/menu",
        element: <Menus />,
    },

    {
        path: "/news",
        element: <News />,
    },

    // {
    //   path: "/metatags",
    //   element: <Page component={MetaTags} />,
    // },

    {
        path: "/hyperlinks",
        element: <HyperLinks />,
    },

    {
        path: "/subscribers",
        element: <Subscribers />,
    },

    {
        path: "/blogform",
        element: <BlogForm />,
    },

    {
        path: "/menuform",
        element: <MenuForm />,
    },

    {
        path: "/newsform",
        element: <NewsForm />,
    },

    // {
    //   path: "/banners",
    //   element: <Page component={Banners} />,
    // },

    {
        path: "/banners",
        element: <IntroBanner />,
    },

    {
        path: "/homebanners",
        element: <HomeBanners />,
    },

    {
        path: "/homebannersform",
        element: <HomeBannersForm />,
    },

    {
        path: "/hyperlinksform",
        element: <HyperLinksForm />,
    },

    {
        path: "/bannersform",
        element: <IntroBannerForm />,
    },

    {
        path: "/faqs",
        element: <Faqs />,
    },

    {
        path: "/faqsform",
        element: <FaqsForm />,
    },

    {
        path: "/socialmedia",
        element: <SocialMedia />,
    },

    {
        path: "/socialmediaform",
        element: <SocialMediaForm />,

    },

    {
        path: "/favicon",
        element: <Favicon />
    },

    {
        path: "/blogsections",
        element: <BlogSections />,
    },

    {
        path: "/newssections",
        element: <NewsSections />
    },

    {
        path: "/businesstravel",
        element: <BusinessTravel />
    },

    {
        path: "/mannaiholiday",
        element: <MannaiHoliday />
    },

    {
        path: "/technologies",
        element: <Technologies />
    },

    {
        path: "/spacetravel",
        element: <SpaceTravel />
    },

    {
        path: "/spacecargo",
        element: <SpaceCargo />
    },

    {
        path: "/mannaifooter",
        element: <MannaiFooter />
    },

    {
        path: "/contactus",
        element: <ContactUs />
    },

    {
        path: "/globalpartner",
        element: <GlobalPartner />
    },

    {
        path: "/customerqueries",
        element: <CustomerQueries />
    },

    {
        path: "/roles",
        element: <Roles />
    },

    {
        path: "/rolesform",
        element: <RolesForm />
    },

    {
        path: "/mastermenus",
        element: <MasterMenus />
    },

    {
        path: "/mastermenusform",
        element: <MasterMenusForm />
    },

    {
        path: "/users",
        element: <Users />
    },

    {
        path: "/usersform",
        element: <UsersForm />
    },

    //Uncomment this later --required for logs
    {
        path: "/logs",
        element: <MannaiLogs />
    },

    // {
    //   path: "/adduserForm",
    //   element: <Page component={UserForm} />,
    // },
    /////////////////////////////////

    //-------------------------- CKC Links Start ----------------------------------------

    {
        path: "/uploadbanners",
        element: <UploadBanners />,
    },

    {
        path: "/uploadbannersform",
        element: <UploadBannersForm />,
    },

    {
        path: "/savingplans",
        element: <SavingPlans />,
    },

    {
        path: "/savingplansform",
        element: <SavingPlansForm />,
    },

    {
        path: "/uploadhomepage",
        element: <UploadHomePage />,
    },

    {
        path: "/uploadhomepageform",
        element: <UploadHomePageForm />,
    },

    {
        path: "/uploadlogin",
        element: <UploadLogin />,
    },

    {
        path: "/uploadloginform",
        element: <UploadLoginForm />,
    },

    {
        path: "/uploadsignup",
        element: <UploadSignUp />,
    },

    {
        path: "/uploadsignupform",
        element: <UploadSignUpForm />,
    },

    {
        path: "/ckctnc",
        element: <TermsAndConditions />
    },

    //------------------------- CKC Links End -------------------------------------------

];

export default routes;
