import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class Services {
    gethyperlinks() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/gethyperlinks`);
    };

    addhyperlinks(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addhyperlinks`, dat);
    };

    updatehyperlinks(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatehyperlinks`, dat);
    };

    deletehyperlink(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletehyperlink/${id}`);
    };
};

export default new Services();