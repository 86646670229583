import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class Services {
    getintrobanners() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getintrobanners`);
    };

    addintrobanners(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addintrobanner`, dat);
    };

    deleteintrobanners(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteintrobanner/${id}`);
    };

    updateintrobanners(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateintrobanners`, dat);
    };
}

export default new Services();