import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class FormServices {

    getformcontactus() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getformcontactus`);
    };

    deleteformcontactus(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteformcontactus/${id}`)
    }

    getformcommon() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getformcommon`);
    };

    deleteformcommon(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteformcommon/${id}`)
    }

};

export default new FormServices();