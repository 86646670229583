import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class Services {

    //Blogs

    getBlogs() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getblogs`);
    };

    getBlogById(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getblog/${id}`);
    };

    addBlogs(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addblogs`, dat);
    };

    updateBlogs(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateblogs`, dat);
    };

    deleteBlog(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteblog/${id}`);
    };

    featureBlog(id) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/blogfeature/${id}`)
    }

    //Attractions

    addAttractions(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addattractions`, dat);
    };

    updateAttractions(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateattractions`, dat);
    };

    deleteattraction(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteattraction/${id}`);
    };

    //BlogSubSections

    addBlogSubSections(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addblogsubsections`, dat);
    };

    updateblogsubsections(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateblogsubsections`, dat);
    };

    deleteblogsubsection(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteblogsubsection/${id}`);
    };

    //Image Uploading

    uploadImage(formdata) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addimage`, formdata);
    };

    //Image Retrieving
    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
    };
};

export default new Services();