import React, { useState } from 'react';
import { Card, CardContent, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from 'yup';
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";

import Services from './Services';
import Swal from 'sweetalert2';
import moment from 'moment';

const validationSchema = yup.object({
    username: yup
        .string('Enter your username')
        .required('Username is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Login1 = () => {
    const navigate = useNavigate();
    const { setActiveLayout } = useJumboApp();
    const [loading, setloading] = useState(false)
    localStorage.clear();

    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    }, []);


    const mutation = useAuthSignInWithEmailAndPassword(auth, {
        onError(error) {
            console.log(error);
        },
        onSuccess(data) {
            navigate("/", { replace: true });
        }
    });

    const onSignIn = (username, password) => {
        console.log("inside onsignin");
        // mutation.mutate({username, password});

        Services
            .getusercred(username)
            .then((res) => {
                if (res?.data?.isActive.includes("TRUE") && res?.data?.password === password) {
                    console.log("Services If Part");
                    setloading(true);
                    localStorage.setItem('user', JSON.stringify(res?.data));
                    setTimeout(() => {
                        navigate("/menu");
                        //required to fix misaignment isse prolly arising from switching layout back & forth
                        navigate(0);
                    }, 2000);
                } else {
                    console.log("Services Else Part");
                    Swal.fire({
                        icon: 'error',
                        title: 'Incorrect Credentials',
                        text: 'Please Enter correct username & password'
                    });
                }
                // } 
            })
            .catch((err) => {
                console.error("Error fetching User Credentials: ", err)
                Swal.fire({
                    icon: 'error',
                    title: 'Incorrect Credentials',
                    text: 'Please Enter correct username & password'
                });
            });

        // Services
        //     .getuserdatbyusername(username)
        //     .then((res) => {
        //         if(res?.data?.password === password) {
        //             console.log("Services Ifpart");
        //             setloading(true);
        //             // console.log("resdat: ", res?.data);
        //             localStorage.setItem('user', JSON.stringify(res?.data));
        //             setTimeout(() => {
        //                 navigate("/home");
        //                 //required to fix misalignment issue prolly arising from switching layout back & forth
        //                 navigate(0);
        //             }, 2000);
        //         } else {
        //             console.log("Services Else Part");
        //             Swal.fire({
        //                 icon: 'error',
        //                 title: 'Incorrect Credentials',
        //                 text: 'Please Enter correct username & password',
        //                 // footer: '<a href>Why do I have this issue?</a>',
        //             });
        //         }
        //     })
        //     .catch((err) => console.error("Error fetching userInfo: ", err));
    };

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>
            {/* <Div sx={{mb: 3, display: 'inline-flex'}}>
                <Link
                    href="#"
                    underline="none"
                    sx={{display: 'inline-flex'}}
                >
                    <img src={`${ASSET_IMAGES}/logo.png`} alt="Jumbo React"/>
                </Link>
            </Div> */}
            <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
                <Div sx={{ position: 'relative', height: '151px' }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="100%"
                        width={"100%"}
                        style={{objectFit: "contain"}}
                        // image={`${ASSET_IMAGES}/colin-watts.jpg`}
                        image={`${ASSET_IMAGES}/jncmslogo.png`}
                    />
                    <Div
                        sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme => alpha(theme.palette.common.black, .4),
                            p: theme => theme.spacing(3),
                        }}
                    >
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mt: 12
                            }}
                        >
                            Sign In
                        </Typography>
                    </Div>
                </Div>
                <CardContent sx={{ pt: 0 }}>

                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            username: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting }) => {
                            setSubmitting(true);
                            onSignIn(data.username, data.password);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                {/* <Avatar
                                    alt="Remy Sharp" src={getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`)}
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        marginLeft: 'auto',
                                        boxShadow: shadows[3],
                                        transform: 'translateY(-50%)',
                                    }}
                                /> */}
                                {mutation.isError && <p>{mutation.error.message}</p>}
                                <Div sx={{ mb: 3, mt: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="username"
                                        label="Username or Email Id"
                                    />
                                </Div>
                                <Div sx={{ mb: 2, mt: 1 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                    />
                                </Div>
                                {/* <Typography
                                    textAlign={"right"}
                                    variant={"body1"}
                                >
                                    <Link underline="none" href="#">Forgot your password?</Link>
                                </Typography> */}
                                {/* <Div sx={{ mb: 1 }}>
                                    <FormControlLabel control={<Checkbox />} label="Remember me" />
                                </Div> */}
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    // loading={isSubmitting || mutation.isLoading}
                                    loading={loading}
                                >Login</LoadingButton>
                                {/* <Typography textAlign={"center"} variant={"body1"} mb={1}>Don't have an account?
                                    <Link underline="none" href="#"> Sign up now</Link>
                                </Typography> */}
                            </Form>
                        )}
                    </Formik>
                    <Typography
                            variant={"h2"}
                            sx={{
                                color: 'grey',
                                textAlign: 'center',
                                fontSize: '11px',
                                mb: 0
                            }}
                        >
                            {`Copyright @${moment(new Date()).format("YYYY")} | Jnana Software Technologies Pvt Ltd`}
                        </Typography>
                        <div style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>
                        <img src={`${ASSET_IMAGES}/jncmshlogo.png`} width={120} style={{objectFit:"contain"}} alt="" />
                        </div>
                </CardContent>
            </Card>
            {/* <Typography variant={"body1"} mb={2}>Or sign up with</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                    sx={{
                        bgcolor: '#385196',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#385196',
                        }
                    }}
                    aria-label="Facebook"
                >
                    <Facebook fontSize={"small"}/>
                </IconButton>
                <IconButton
                    sx={{
                        bgcolor: '#00a8ff',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#00a8ff',
                        }
                    }}
                    aria-label="Twitter"
                >
                    <Twitter fontSize={"small"}/>
                </IconButton>
                <IconButton
                    sx={{
                        bgcolor: '#23272b',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                            backgroundColor: '#23272b',
                        }
                    }}
                    aria-label="Twitter"
                >
                    <Google fontSize="small"/>
                </IconButton>
            </Stack> */}
        </Div>
    );
};

export default Login1;
