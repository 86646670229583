import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Card, CardContent, CardHeader, Fab, IconButton, Input, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableChartIcon from '@mui/icons-material/TableChart';
import Services from './Services';
import { Link, useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';

// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import moment from 'moment';
import LogServices from '../LogServices/LogServices';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';

const FeatureSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    // width: 42,
    width: 49,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        // transitionDuration: '300ms',
        transitionDuration: '200ms',
        '&.Mui-checked': {
            // transform: 'translateX(16px)',
            transform: 'translateX(23px)',
            transitionDuration: '500ms',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#7352c7',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        // backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        backgroundColor: theme.palette.mode === 'light' ? '#e59c9c' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Blog = () => {

    const [data, setdata] = useState([]);
    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusdateString = moment(currentDate).format('llll');

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Blogs"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        Services
            .getBlogs()
            .then((e) => {
                setdata(e.data);
                setpuredat(e?.data);
            })
            .catch((err) => console.error("Error fetching blogs", err));
    }, []);

    const deleteblog = (e) => {
        if (e.id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat.username,
                        pagename: "Blogs",
                        operation: "DELETE",
                        description: `Deleted Blog with Heading: ${e.heading} & Traveller Name: ${e.travellername} by the LoggedIn User ${userdat.username} on ${cusdateString}`,
                        datetime: cusdateString
                    };

                    Services
                        .deleteBlog(e.id)
                        .then(() => Services.getBlogs())
                        .then((es) => {
                            setdata(es.data);
                            setpuredat(e?.data);
                        })
                        .catch((err) => console.error("Error deleting blog", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }
    };

    const featureBlog = (e) => {

        const logdat = {
            loggeduser: userdat.username,
            pagename: "Blogs",
            operation: "FEATURE",
            description: `Featured Blog with Heading: ${e.heading} & Traveller Name: ${e.travellername} by the LoggedIn User ${userdat.username} on ${cusdateString}`,
            datetime: cusdateString
        };

        Services
            .featureBlog(e.id)
            .then(() => Services.getBlogs())
            .then((es) => {
                setdata(es.data);
                setpuredat(es?.data);
            })
            .catch((err) => console.error("Error featuring Blog :", err));

        LogServices
            .addlogs(logdat)
            .catch((err) => console.error("Error adding logs: ", err));


    };

    const handleSearch = (val) => {
        const filtered = puredat?.filter((es) => es?.title.toLowerCase().includes(val.toLowerCase()) || es?.heading.toLowerCase().includes(val.toLowerCase()));
        setdata(filtered);
    }

    const handleSort = () => {
        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            const sorted = data?.sort((a, b) => a?.heading > b?.heading ? 1 : -1);
            setdata(sorted);
        } else {
            const sorted = data?.sort((a, b) => a?.heading < b?.heading ? 1 : -1);
            setdata(sorted);
        }
    }

    return (
        <>
            {logincred?.length == 1 ?
                <Card >
                    <CardHeader
                        sx={{
                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                        }}
                        action={
                            <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                <Tooltip title="Search Blogs">
                                    <Input
                                        id="input-with-icon-adornment"
                                        placeholder='Search'
                                        onChange={(ev) => handleSearch(ev.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchOutlinedIcon fontSize='small' color='action' />
                                            </InputAdornment>
                                        }
                                    />
                                </Tooltip>

                                <Tooltip title="Sort Blogs">
                                    <IconButton onClick={() => handleSort()} >
                                        <Link>
                                            <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                        </Link>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Add Blogs">
                                    <IconButton >
                                        <Link to="/blogform">
                                            <AddOutlinedIcon fontSize='medium' color='action' />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }

                        title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                            Blogs
                        </Typography>
                    />

                    <CardContent sx={{ py: 0 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Sl No</StyledTableCell>
                                        <StyledTableCell align="center">Title</StyledTableCell>
                                        {/* <StyledTableCell align="center">Blog Category</StyledTableCell> */}
                                        {/* <StyledTableCell align="center">SubTitle</StyledTableCell> */}
                                        <StyledTableCell align="left">Heading</StyledTableCell>
                                        <StyledTableCell align="center">Read Time</StyledTableCell>
                                        <StyledTableCell align="center">Featured</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((e, i) => {
                                        return (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align='left' component="th" scope="row">{i + 1}</StyledTableCell>
                                                <StyledTableCell align='left' component="th" scope="row">{e.title}</StyledTableCell>
                                                {/* <StyledTableCell align='center' component="th" scope="row">{e.blogcategory}</StyledTableCell> */}
                                                {/* <StyledTableCell align='center' component="th" scope="row">{e.subtitle}</StyledTableCell> */}
                                                <StyledTableCell align='left' component="th" scope="row">{e.heading}</StyledTableCell>
                                                <StyledTableCell align='left' component="th" scope="row">{e.readtime}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">
                                                    {/* {e.featured === 'TRUE' ?
                                                    <Fab onClick={() => featureBlog(e.id)} sx={{ m: 1, fontSize: '12px', fontWeight: 300, letterSpacing: 2 }
                                                    } variant="extended" size="small" color="success">
                                                        FEATURED
                                                    </Fab> :
                                                    <Fab onClick={() => featureBlog(e.id)} sx={{ m: 1, fontSize: '12px', fontWeight: 300, letterSpacing: 2 }
                                                    } variant="extended" size="small" color="error">
                                                        FEATURE
                                                    </Fab>
                                                } */}
                                                    <FeatureSwitch
                                                        checked={e.featured === 'TRUE' ? true : false}
                                                        onClick={() => featureBlog(e)}
                                                    // sx={{ m: 1 }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="left" component="th" scope="row">
                                                    <Stack justifyContent={'flex-start'} direction="row" alignItems="center" spacing={-0.4}>
                                                        <Tooltip title="Modify">
                                                            <Link to="/blogform" state={e}>
                                                                <IconButton >
                                                                    <EditIcon color='action' />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>

                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => deleteblog(e)}>
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="View More">
                                                            <Link to="/blogsections" state={e}>
                                                                <IconButton >
                                                                    <TableChartIcon color='action' />
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                    </Stack>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>

                </Card> : <></>}

        </>
    )
}

export default Blog;