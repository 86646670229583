import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class Services {

    getSocialMedia(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getsocialmedia/${id}`)
    }

    updateSocialMedia(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatesocialmedia`, dat)
    }

}

export default new Services();