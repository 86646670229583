import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios"

class Services {
    getfaqs() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getfaqs`);
    };

    addfaqs(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addfaq`, dat);
    };

    deletefaq(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletefaq/${id}`);
    };

    updatefaq(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatefaq`, dat);
    };

};

export default new Services();