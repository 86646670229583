import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import MannaiFooterServices from './MannaiFooterServices';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css"
import moment from 'moment';
import LogServices from '../LogServices/LogServices';

const MannaiFooter = () => {

    const [color, setColor] = useColor("#1ec4cb");

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const currentDate = new Date();
    const datestring = moment(currentDate).format('YYYY-MM-DD');
    const cusDateString = moment(currentDate).format("llll");

    const [id, setid] = useState(1);
    const [imagePath, setimagePath] = useState(null);
    const [filename, setfilename] = useState(null);
    const [footerimg, setfooterimg] = useState(null);
    const [footerimgerr, setfooterimgerr] = useState('');

    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const [precol, setprecol] = useState(null);
    const [fooimgtext, setfooimgtext] = useState(null);

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Footer"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {

        MannaiFooterServices
            .getfooter(id)
            .then((res) => {
                const [tablename, date, fil] = res?.data?.imagePath.split('/');

                setfilename(fil);
                setimagePath(res?.data?.imagePath);
                setColor(JSON.parse(res?.data?.color));
                setprecol(JSON.parse(res?.data?.color));

                MannaiFooterServices
                    .getImage(tablename, date, fil)
                    .then((res) => setfooterimg(res.data))
                    .catch((err) => console.error("Error fetching Footer Image: ", err));
            })

    }, []);
    

    const handleFooterImageChange = (e) => {
        const file = e.target.files[0];
        setfooterimgerr('');

        if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
            const imgpath = `MannaiFooter/${datestring}/${file.name}`
            setfooimgtext(`Replaced Footer Image`);
            setfooterimg(file);
            setimagePath(imgpath);
            setfilename(file.name);

        } else {
            setfooterimgerr("Invalid file type. Please choose a JPEG or PNG image.")
        }
    }

    const validation = () => {

        setfooterimgerr('');
        const imgerr = !filename.trim() ? "Image is required" : "";

        setfooterimgerr(imgerr);
        const errboo = filename.trim().length > 2;

        return errboo;

    };

    const invokeAlert = () => {

        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Updating Footer Successful!',
        })

    }

    const onSubmit = () => {

        if (validation()) {
            invokeAlert();

            const dat = {
                id: id,
                imagePath: imagePath,
                color: JSON.stringify(color)
            };

            const logdat = {
                loggeduser: userdat.username,
                pagename: "Footer",
                operation: "UPDATE",
                description: `Updated Footer${fooimgtext != null ? ", " + fooimgtext : ''}${precol.hex !== color.hex ? ", " + `Changed Footer Color from ${precol.hex} to ${color.hex}` : ''} by the LoggedIn user ${userdat.username} on ${cusDateString}`,
                datetime: cusDateString
            };

            MannaiFooterServices
                .updatefooter(dat)
                .catch((err) => console.error("Error Updating footer", err));

            LogServices
                .addlogs(logdat)
                .catch((err) => console.error("Error Adding Logs: ", err));

            let formdata = new FormData();

            formdata.append("file", footerimg);
            formdata.append("tablename", "MannaiFooter");
            formdata.append("date", datestring);

            MannaiFooterServices
                .uploadImage(formdata)
                .catch((err) => console.error("Error uploading Footer Image", err));

        }
        setprecol(color);
        setfooimgtext(null);
    }

    return (
        <>
            {logincred?.length == 1 ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography fontSize={'.875rem'} color="text.primary">
                                Mannai Home
                            </Typography>
                            <Typography fontSize={'.875rem'} color="text.primary">Footer</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                Footer
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >
                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    sx={{ mb: 1 }}
                                >

                                    <Box
                                        component={'div'}
                                        sx={{ flex: 2.5 }}
                                    >
                                        <Typography color={'GrayText'} sx={{ mb: 2 }} align='center' level="body-sm">{`Footer Background Color`}</Typography>
                                        <ColorPicker color={color} onChange={setColor} />

                                    </Box>

                                    <Box
                                        component={'div'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        sx={{ flex: 1 }}
                                    >

                                        <Typography color={'GrayText'} align='center' level="body-sm">{`Footer Image`}</Typography>
                                        <Typography color={'GrayText'} sx={{ mb: 1 }} align='center' level="body-sm">{`(Preferred Dimensions - 2232 x 3036)`}</Typography>

                                        {footerimg ?
                                            <Button
                                                color='warning'
                                                variant="contained"
                                                component="label"
                                                sx={{ fontSize: 12 }}
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Re-Upload Image
                                                <input
                                                    hidden
                                                    accept="image/jpeg, image/png"
                                                    type="file"
                                                    onChange={(e) => handleFooterImageChange(e)}
                                                />
                                            </Button>
                                            :
                                            <Button
                                                variant="contained"
                                                component="label"
                                                sx={{ fontSize: 12 }}
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Upload Image
                                                <input
                                                    hidden
                                                    accept="image/jpeg, image/png"
                                                    type="file"
                                                    onChange={(e) => handleFooterImageChange(e)}
                                                />
                                            </Button>
                                        }

                                        <Box
                                            component={'div'}
                                            mt={2}
                                        >
                                            {footerimg && (
                                                <img src={URL.createObjectURL(footerimg)} alt="image" height={190} style={{ objectFit: 'cover' }} />
                                            )}
                                        </Box>

                                        {footerimgerr && (
                                            <Typography color="error" align='center' variant="body2">
                                                {footerimgerr}
                                            </Typography>
                                        )}

                                    </Box>
                                </Box>

                            </Box>

                            <Box
                                component={'div'}
                                display={'flex'}
                                justifyContent={{ xs: 'center', md: 'flex-end' }}
                                sx={{ mt: 2 }}
                            >
                                <Button variant="contained"
                                    onClick={() => onSubmit()}
                                >
                                    Save
                                </Button>
                            </Box>

                        </CardContent>
                    </Card>

                </Box > : <></>
            }

        </>
    )
}

export default MannaiFooter