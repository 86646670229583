import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class CKCService {

    //----------------------- CKC Saving Plan Start ----------------------
    addckcsavingplan(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addckcsavingplan`, dat);
    };

    updateckcsavingplan(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateckcsavingplan`, dat);
    };

    getallckcsavingplan() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getallckcsavingplan`);
    };

    getckcsavingplanbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getckcsavingplan/${id}`);
    };

    deleteckcsavingplan(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteckcsavingplan/${id}`);
    };

    //------------------------ CKC Saving Plan End -----------------------

    //------------------------- CKUploadBanners Start -----------------------------

    addckcuploadbanner(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addckcuploadbanner`, dat);
    };

    updateckcuploadbanner(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateckcuploadbanner`, dat);
    };

    getallckcuploadbanners() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getallckcuploadbanners`);
    };

    getckcuploadbannerbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getckcuploadbanner/${id}`);
    };

    deleteckcuploadbanner(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteckcuploadbanner/${id}`);
    };

    //------------------------- CKUploadBanners End ----------------------------------

    //------------------------- CKCUploadHomePage Start -------------------

    addckcuploadhomepage(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addckcuploadhomepage`, dat);
    };

    updateckcuploadhomepage(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateckcuploadhomepage`, dat);
    };

    getallckcuploadhomepage() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getallckcuploadhomepage`);
    };

    getckcuploadhomepage(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getckcuploadhomepage/${id}`);
    };

    deleteckcuploadhomepage(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteckcuploadhomepage/${id}`);
    };

    //------------------------- CKCUploadHomePage End -----------------------------

    //------------------------- CKUploadLogin Start ------------------------------

    addckcuploadlogin(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addckcuploadlogin`, dat);
    };

    updateckcuploadlogin(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateckcuploadlogin`, dat);
    };

    getallckcuploadlogin() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getallckcuploadlogin`);
    }

    getckcuploadloginbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getckcuploadlogin/${id}`);
    };

    deleteckcuploadlogin(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteckcuploadlogin/${id}`);
    };

    //------------------------- CKUploadLogin End --------------------------------

    //------------------------- CKCUploadSignUp Start ----------------------------------

    addckcuploadsignup(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addckcuploadsignup`, dat);
    };

    updateckcuploadsignup(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateckcuploadsignup`, dat);
    };

    getallckcuploadsignup() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getallckcuploadsignup`);
    };

    getckcuploadsignupbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getckcuploadsignup/${id}`);
    };

    deleteckcuploadsignup(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteckcuploadsignup/${id}`);
    };

    //------------------------- CKCUploadSignUp End ------------------------------------

    //------------------------- CKCTermsAndConditions Start ----------------------------------

    getallckctnc() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getallckctnc`);
    };

    getckctncbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getckctnc/${id}`);
    };

    addckctnc(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addckctnc`, dat);
    };

    deleteckctnc(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deleteckctnc/${id}`);
    };

    updateckctnc(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updateckctnc`, dat);
    };


    //------------------------- CKCTermsAndConditions End ------------------------------------

    //-------------------------- Image Upload/Retrieve Start ---------------------------------
        //Image Uploading

        uploadImage(formdata) {
            return axios.post(`${SCHEME}${DOMAIN}${PORT}/addimage`, formdata);
        };
    
        //Image Retrieving
        getImage(tablename, date, filename) {
            return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
        };

    //------------------------ Image Upload/Retrieve End --------------------------------------
}

export default new CKCService();