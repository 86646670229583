import React, { useState } from 'react';
import Avatar from "@mui/material/Avatar";
import { authUser } from "./fake-db";
import { ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import { useJumboTheme } from "@jumbo/hooks";
import Swal from 'sweetalert2';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const AuthUserDropdown = () => {
    // const mutation = useAuthSignOut(auth);
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const [droptrigg, setdroptrigg] = useState(true);

    const userdat = JSON.parse(localStorage.getItem('user'));
    console.log("HJS: ", userdat);


    const onLogout = () => {
        //mutation.mutate();
        setdroptrigg(false);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, log out!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                localStorage.clear();
                navigate('/')
                navigate(0);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
            }
            setdroptrigg(true)
        });
    };

    return (
        <ThemeProvider theme={theme}>
            {droptrigg &&
                <JumboDdPopover

                    triggerButton={
                        <Div sx={{display: 'flex', alignItems: 'center'}}>
                            <Div sx={{
                                display: 'flex',
                                alignItems: 'end',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                p: theme => theme.spacing(2.5),
                            }}>
                                {/* <Avatar src={authUser.profile_pic} alt={authUser.name} sx={{ width: 60, height: 60, mb: 2 }} /> */}
                                <Typography variant={"subtitle1"} fontSize={14}>{`Welcome ${userdat?.firstname} ${userdat?.lastname}`}</Typography>
                                <Typography variant={"subtitle1"} fontSize={10}>{userdat?.rolename}</Typography>
                            </Div>
                            <Avatar
                                // src={authUser.profile_pic}
                                // src={userdat?.username === "mannai" ? `${ASSET_IMAGES}/mannaiprofile.png` : `${ASSET_IMAGES}/ckcprofile.png`}
                                src={`${ASSET_IMAGES}/mannaiprofile.png`}
                                // src={`${ASSET_IMAGES}/logoJnanafav.png`}
                                sizes={"small"}
                                sx={{ boxShadow: "rgb(94 94 94) 0px 5px 17px", cursor: 'pointer' }}
                            />
                        </Div>
                    }

                >
                    {/* <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={authUser.profile_pic} alt={authUser.name} sx={{width: 60, height: 60, mb: 2}}/>
                    <Typography variant={"h5"}>{userdat.username}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{userdat.emailid}</Typography>
                </Div>
                <Divider/> */}
                    {/* <nav> */}

                    <nav>
                        <List disablePadding sx={{ pb: 1 }}>
                            <ListItemButton onClick={() => onLogout()}>
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout" sx={{ my: 0 }} />
                            </ListItemButton>
                        </List>
                    </nav>
                    {/* </nav> */}
                </JumboDdPopover>
            }
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
