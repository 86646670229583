import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Services from '../CKCService';

const SavingPlansForm = () => {

    let { state } = useLocation();

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const [formdata, setformdata] = useState([{ id: '', planname: '', plandesc: '' }]);
    const [formerr, setformerr] = useState([]);

    useEffect(() => {
        if (state) {
            setformdata([{ id: state.id, planname: state.planname, plandesc: state.plandesc }]);
        }
    }, [])


    const handleformfieldchange = (field, value) => {
        const newfield = [...formdata];
        newfield[0][field] = value;
        setformdata(newfield);
        validation();
    };

    const validation = () => {
        const formerror = formdata.map((e) => {
            // const linkerr = !e.link.trim();
            // const descriptionerr = !e.description.trim();

            // return { link: linkerr, description: descriptionerr };
            const plannameerr = !e.planname.trim();
            const plandescerr = !e.plandesc.trim();

            return { planname: plannameerr, plandesc: plandescerr };
        });

        setformerr(formerror);

        const formerrboo = formdata.every((e) => {
            // return e.link.trim() && e.description.trim();
            return e.planname.trim() && e.plandesc.trim();
        });

        return formerrboo;
    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating Saving Plan Successful!',
            }).then(() => navigate('/savingplans'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding Saving Plan Successful!',
            }).then(() => navigate('/savingplans'))
        }
    }

    const onSubmit = () => {
        if (validation()) {
            invokeAlert();
            if (state) {
                // const dat = {
                //     id: formdata[0]?.id,
                //     link: formdata[0]?.link,
                //     description: formdata[0]?.description
                // };

                const dat = {
                  id: formdata[0]?.id,
                  planname: formdata[0]?.planname,
                  plandesc: formdata[0]?.plandesc
                }

                Services
                    .updateckcsavingplan(dat)
                    .catch((err) => console.log("error updating Saving Plan", err));

                // Services
                //     .updatehyperlinks(dat)
                //     .catch((err) => console.error("error updating Saving Plan", err));
            } else {
                // const dat = {
                //     link: formdata[0]?.link,
                //     description: formdata[0]?.description
                // };

                const dat = {
                  id: formdata[0]?.id,
                  planname: formdata[0]?.planname,
                  plandesc: formdata[0]?.plandesc
                }

                // Services
                //     .addhyperlinks(dat)
                //     .catch((err) => console.error("error adding Saving Plan", err));

                Services
                    .addckcsavingplan(dat)
                    .catch((err) => console.log("error adding Saving Plan", err));
            }

            // setformdata([{ id: '', link: '', description: '' }]);
            setformdata([{id: '', planname: '', plandesc: ''}]);

        }
    }


    return (
        <Box
            component={'form'}
        >
            <Box
                component={'div'}
                sx={{ mb: 2 }}
            >
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/savingplans'>
                        <Typography fontSize={'.875rem'} color="text.primary">
                            Savings Plan Table
                        </Typography>
                    </Link>
                    <Typography fontSize={'.875rem'} color="text.primary">Saving Plans Form</Typography>
                </Breadcrumbs>

            </Box>

            <Card >
                <CardHeader
                    title=<Typography fontSize={'1.7rem'} color="text.primary">
                        Saving Plans Form
                    </Typography>
                />

                <CardContent sx={{ py: 0 }}>
                    <Box
                        component={'section'}
                    >
                        <Box
                            component={'div'}
                            display='flex'
                            flexDirection={{ xs: 'column', lg: 'row' }}
                            gap={{ lg: 3 }}
                            sx={{ mb: 1 }}
                        >

                            <TextField
                                error={formerr[0]?.planname}
                                helperText={formerr[0]?.planname && 'required'}
                                id="standard-error-helper-text"
                                label="Plan Name"
                                value={formdata[0]?.planname}
                                onChange={(ev) => handleformfieldchange('planname', ev.target.value)}
                                variant="standard"
                                sx={{ flex: 1 }}
                            />

                            <TextField
                                error={formerr[0]?.plandesc}
                                helperText={formerr[0]?.plandesc && 'required'}
                                id="standard-error-helper-text"
                                label="Plan Description"
                                multiline
                                value={formdata[0]?.plandesc}
                                onChange={(ev) => handleformfieldchange('plandesc', ev.target.value)}
                                variant="standard"
                                sx={{ flex: 2 }}
                            />

                        </Box>

                        <Box
                            component={'div'}
                            display={'flex'}
                            justifyContent={{ xs: 'center', md: 'flex-end' }}
                            sx={{ mt: 2 }}
                        >
                            <Button variant="contained" onClick={() => onSubmit()}>
                                Save
                            </Button>
                        </Box>

                    </Box>
                </CardContent>
            </Card>

        </Box>
    )
}

export default SavingPlansForm;