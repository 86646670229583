import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Services from './Services';
import moment from 'moment';
import LogServices from '../LogServices/LogServices';

const FaqsForm = () => {

    let { state } = useLocation();

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const [formdata, setformdata] = useState([{ id: '', question: '', answer: '' }]);
    const [formerr, setformerr] = useState([]);

    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format("llll");

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("FAQ's"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        if (state) {
            setformdata([{ id: state.id, question: state.question, answer: state.answer }]);
        }
    }, []);

    const handleformfieldchange = (field, value) => {
        const newfield = [...formdata];
        newfield[0][field] = value;
        setformdata(newfield);
        validation();
    };

    const validation = () => {

        const formerror = formdata.map((e) => {
            const questionerr = !e.question.trim();
            const answererr = !e.answer.trim();

            return { question: questionerr, answer: answererr };
        });

        setformerr(formerror);

        const formerrboo = formdata.every((e) => {
            return e.question.trim() && e.answer.trim();
        });

        return formerrboo;
    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating FAQ Successful!',
            }).then(() => navigate('/faqs'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding FAQ Successful!',
            }).then(() => navigate('/faqs'))
        }
    }

    const onSubmit = () => {

        if (validation()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: formdata[0]?.id,
                    question: formdata[0]?.question,
                    answer: formdata[0]?.answer
                };

                const logdat = {
                    loggeduser: userdat.username,
                    pagename: "FAQ's",
                    operation: "UPDATE",
                    description: `Updated FAQ's with Question: ${formdata[0]?.question} by the LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                };

                Services
                    .updatefaq(dat)
                    .catch((err) => console.error("error updating faqs", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error adding Logs", err));

            } else {
                const dat = {
                    question: formdata[0]?.question,
                    answer: formdata[0]?.answer
                };

                const logdat = {
                    loggeduser: userdat?.username,
                    pagename: "FAQ's",
                    operation: "ADD",
                    description: `Added FAQ's by LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                }

                Services
                    .addfaqs(dat)
                    .catch((err) => console.error("error adding faqs", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs: ", err));
            }
            setformdata([{ id: '', question: '', answer: '' }]);
        }

    }

    return (
        <>
            {logincred?.length == 1 ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/faqs'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    FAQs Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">FAQs Form</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                FAQ's Form
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >
                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >

                                    <TextField
                                        error={formerr[0]?.question}
                                        helperText={formerr[0]?.question && 'required'}
                                        id="standard-error-helper-text"
                                        label="Question"
                                        value={formdata[0]?.question}
                                        onChange={(ev) => handleformfieldchange('question', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.answer}
                                        helperText={formerr[0]?.answer && 'required'}
                                        id="standard-error-helper-text"
                                        label="Answer"
                                        multiline
                                        value={formdata[0]?.answer}
                                        onChange={(ev) => handleformfieldchange('answer', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                </Box>

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                                    sx={{ mt: 2 }}
                                >
                                    <Button variant="contained" onClick={() => onSubmit()}>
                                        Save
                                    </Button>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>

                </Box> : <></>}

        </>
    )
}

export default FaqsForm