import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import Services from './Services';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import LogServices from '../LogServices/LogServices';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Subscribers = () => {
    const [data, setdata] = useState([]);
    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    const currentDate = new Date();
    const cusDateString = moment(currentDate).format("llll");

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Subscribers"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        Services
            .getsubscribers()
            .then((e) => {
                setdata(e.data);
                setpuredat(e?.data);
            })
            .catch((err) => console.error("Error fetching Subscribers: ", err));
    }, []);

    const deletesubscriber = (id) => {

        if (id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true,
            }).then(result => {
                if (result.value) {

                    const logdat = {
                        loggeduser: userdat.username,
                        pagename: "Subscribers",
                        operation: "DELETE",
                        description: `Deleted Subscriber by the LoggedIn user ${userdat.username} on ${cusDateString}`,
                        datetime: cusDateString
                    };

                    Services
                        .deletesubscribers(id)
                        .then(() => Services.getsubscribers())
                        .then((e) => {
                            setdata(e.data);
                            setpuredat(e?.data);
                        })
                        .catch((err) => console.error("Error Deleting Subscribers: ", err));

                    LogServices
                        .addlogs(logdat)
                        .catch((err) => console.error("Error Adding Logs: ", err));

                    Swal.fire('Deleted!', 'Record has been deleted', 'success');
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });
        }

    };

    const handleSearch = (tes) => {
        const filtered = puredat?.filter((es) => es?.email.toLowerCase().includes(tes.toLowerCase()) || es?.createdDate.toLowerCase().includes(tes.toLowerCase()));
        setdata(filtered);
    };

    const handleSort = () => {
        setsorttoggle((prev) => !prev);
        if (sorttoggle) {
            const sorted = data?.sort((a, b) => a?.createdDate < b?.createdDate ? 1 : -1);
            setdata(sorted);
        } else {
            const sorted = data?.sort((a, b) => a?.createdDate > b?.createdDate ? 1 : -1);
            setdata(sorted);
        }
    };

    return (
        <>
            {logincred?.length == 1 ?
                <Card >
                    <CardHeader
                        sx={{
                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                        }}

                        action={
                            <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                <Tooltip title="Search Subscribers">
                                    <Input
                                        id="input-with-icon-adornment"
                                        placeholder='Search'
                                        onChange={(ev) => handleSearch(ev.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchOutlinedIcon fontSize='small' color='action' />
                                            </InputAdornment>
                                        }
                                    />
                                </Tooltip>

                                <Tooltip title="Sort Subscribers">
                                    <IconButton onClick={() => handleSort()}>
                                        <Link>
                                            <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }


                        title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                            Subscribers
                        </Typography>
                    />

                    <CardContent sx={{ py: 0 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Sl No</StyledTableCell>
                                        <StyledTableCell align="center">Email</StyledTableCell>
                                        <StyledTableCell align="center">Joined Date</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((e, i) => {
                                        const createddate = moment(e.createdDate).format('DD-MM-YYYY');
                                        return (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align='center' component="th" scope="row">{i + 1}</StyledTableCell>
                                                <StyledTableCell align='center' component="th" scope="row">{e.email}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">{createddate}</StyledTableCell>
                                                <StyledTableCell align="center" component="th" scope="row">
                                                    <Stack justifyContent={'center'} direction="row" alignItems="center" spacing={0.3}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => deletesubscriber(e.id)}>
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card> : <></>}
        </>
    )
}

export default Subscribers;