import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class Services {

    getuserdatbyusername(username) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getuserdatbyusername/${username}`)
    };

    getusercred(user) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/getusercred/${user}`);
    };

}

export default new Services();