import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Services from './Services';

const HyperLinksForm = () => {

    let { state } = useLocation();

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const [formdata, setformdata] = useState([{ id: '', link: '', description: '' }]);
    const [formerr, setformerr] = useState([]);

    const userdat = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (userdat?.username !== "mannai") {
            console.log("If part");
            Swal.fire({
                icon: 'error',
                title: 'Access Denied',
                text: 'Please Login to continue',
            }).then(() => navigate('/'))
        }
        console.log("Else part");
    }, [])

    useEffect(() => {
        if (state) {
            setformdata([{ id: state.id, link: state.link, description: state.description }]);
        }
    }, [])


    const handleformfieldchange = (field, value) => {
        const newfield = [...formdata];
        newfield[0][field] = value;
        setformdata(newfield);
        validation();
    };

    const validation = () => {
        const formerror = formdata.map((e) => {
            const linkerr = !e.link.trim();
            const descriptionerr = !e.description.trim();

            return { link: linkerr, description: descriptionerr };
        });

        setformerr(formerror);

        const formerrboo = formdata.every((e) => {
            return e.link.trim() && e.description.trim();
        });

        return formerrboo;
    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating HyperLinks Successful!',
            }).then(() => navigate('/hyperlinks'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding Hyperlinks Successful!',
            }).then(() => navigate('/hyperlinks'))
        }
    }

    const onSubmit = () => {
        if (validation()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: formdata[0]?.id,
                    link: formdata[0]?.link,
                    description: formdata[0]?.description
                };

                Services
                    .updatehyperlinks(dat)
                    .catch((err) => console.error("error updating hyperlinks", err));
            } else {
                const dat = {
                    link: formdata[0]?.link,
                    description: formdata[0]?.description
                };

                Services
                    .addhyperlinks(dat)
                    .catch((err) => console.error("error adding hyperlinks", err));
            }

            setformdata([{ id: '', link: '', description: '' }]);

        }
    }


    return (
        <>
            {userdat?.username === "mannai" ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/hyperlinks'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    HyperLinks Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">HyperLinks Form</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                HyperLinks Form
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >
                                <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >

                                    <TextField
                                        error={formerr[0]?.link}
                                        helperText={formerr[0]?.link && 'required'}
                                        id="standard-error-helper-text"
                                        label="Link"
                                        value={formdata[0]?.link}
                                        onChange={(ev) => handleformfieldchange('link', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={formerr[0]?.description}
                                        helperText={formerr[0]?.description && 'required'}
                                        id="standard-error-helper-text"
                                        label="Description"
                                        multiline
                                        value={formdata[0]?.description}
                                        onChange={(ev) => handleformfieldchange('description', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 2 }}
                                    />

                                </Box>

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                                    sx={{ mt: 2 }}
                                >
                                    <Button variant="contained" onClick={() => onSubmit()}>
                                        Save
                                    </Button>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>

                </Box> : <></>}

        </>
    )
}

export default HyperLinksForm