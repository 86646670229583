import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from 'moment';
import services from './services';
import LogServices from '../LogServices/LogServices';

const NewsForm = () => {

    let { state } = useLocation();

    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const currentDate = new Date();
    const datestring = moment(currentDate).format('YYYY-MM-DD');
    const cusDateString = moment(currentDate).format('llll');

    const [newssubaddcount, setnewssubaddcount] = useState(1);
    const [newssubdelcount, setnewssubdelcount] = useState(1);
    const [newssubaddtext, setnewssubaddtext] = useState(null);
    const [newssubdeltext, setnewssubdeltext] = useState(null);

    const [newsubimgcount, setnewsubimgcount] = useState(1);
    const [newsubimgtext, setnewsubimgtext] = useState(null);
    const [newsimgtext, setnewsimgtext] = useState(null);

    const [newsform, setnewsform] = useState([{ id: '', title: '', authorname: '', description: '', subtitle: '', readtime: '', imagePath: '', imageheader: '', filename: '' }]);
    const [newsimg, setnewsimg] = useState(null);
    const [newsformerr, setnewsformerr] = useState([]);
    const [newsimgerr, setnewsimgerr] = useState('')
    const [imgerrtoggle, setimgerrtoggle] = useState(false);

    const [togglenewssub, settogglenewssub] = useState(false);
    const [newssubform, setnewssubform] = useState([{ id: '', title: '', description: '', imagePath: '', imageheader: '', filename: '' }]);
    const [newssuberr, setnewssuberr] = useState([]);
    const [newssubimg, setnewssubimg] = useState([]);
    const [newsubimgerr, setnewsubimgerr] = useState([]);

    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("News"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        if (state) {
            const fetchData = async () => {

                const [tablename, date, filename] = state.imagePath.split('/');

                setnewsform([{ id: state.id, title: state.title, authorname: state.authorname, description: state.description, imagePath: state.imagePath, imageheader: state?.imageheader, subtitle: state?.subtitle, readtime: state?.readtime, filename: filename }]);

                services
                    .getImage(tablename, date, filename)
                    .then(res => setnewsimg(res.data))
                    .catch((err) => console.error("error fetching news image", err));

                settogglenewssub(true);
                setnewssubform(
                    state?.newssubsection.map((e) => {
                        const [table, d, file] = e?.imagePath.split('/');
                        return ({
                            id: e?.id,
                            title: e?.title,
                            description: e?.description,
                            imagePath: e?.imagePath,
                            imageheader: e?.imageheader,
                            filename: file
                        })
                    })
                );

                const newssubImagePromises = state?.newssubsection.map(async (e) => {
                    const [table, d, file] = e?.imagePath.split('/');
                    try {
                        const res = await services.getImage(table, d, file);
                        return res.data;
                    } catch (err) {
                        console.error("Error fetching newssubsection image: ", err);
                        return null;
                    }
                });

                const newssubimages = await Promise.all(newssubImagePromises);
                setnewssubimg(newssubimages);

            };

            fetchData();
        }

    }, [])

    const handlenewsfieldchange = (field, value) => {
        const newfield = [...newsform];
        newfield[0][field] = value;
        setnewsform(newfield);
        validation();
    }

    const handleNewsImageChange = (e) => {
        const file = e.target.files[0];
        setnewsimgerr('');

        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            setnewsimgtext("Replaced Background Image")
            setnewsimg(file);
            handlenewsfieldchange('filename', file.name);
            handlenewsfieldchange('imagePath', `News/${datestring}/${file.name}`);
        } else {
            setnewsimgerr('Invalid file type. Please choose a JPEG or PNG image.');
        }
    }

    const handleaddnewssubbutton = () => {
        setnewssubaddcount((prev) => prev + 1);
        setnewssubaddtext(`Added ${newssubaddcount} NewsSubSections`);
        setnewssubform([{ id: '', description: '', filename: '', imageheader: '', imagePath: '', title: '' }]);
        settogglenewssub(true);
    }

    const handleNewsSubSecFieldChange = (i, e, val) => {
        const newssub = [...newssubform];
        newssub[i][e] = val;
        setnewssubform(newssub);
        validation();
    };

    const handleNewsSubSecField = () => {
        setnewssubaddcount((prev) => prev + 1);
        setnewssubaddtext(`Added ${newssubaddcount} NewsSubSections`);
        setnewssubform([...newssubform, { id: '', description: '', filename: '', imageheader: '', imagePath: '', title: '' }]);
    };

    const handleRemoveNewsSubField = (i) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                setnewssubdelcount((prev) => prev + 1);
                setnewssubdeltext(`Deleted ${newssubdelcount} NewsSubSections`);
                const updatednewssub = [...newssubform];
                const updatednewssubimg = [...newssubimg];
                updatednewssub.splice(i, 1);
                updatednewssubimg.splice(i, 1);
                setnewssubform(updatednewssub);
                setnewssubimg(updatednewssubimg);
                Swal.fire('Deleted!', 'NewsSubSection has been removed!', 'success');
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
            }
        });
    };

    const handleNewsSubImageChange = (i, event) => {
        const file = event.target.files[0];
        setnewsubimgerr([]);

        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            const newimg = [...newssubimg];
            setnewsubimgcount((prev) => prev + 1);
            setnewsubimgtext(`Replaced ${newsubimgcount} NewsSubSection Images`);
            newimg[i] = file;
            setnewssubimg(newimg);
            handleNewsSubSecFieldChange(i, "filename", file.name);
            handleNewsSubSecFieldChange(i, "imagePath", `NewsSubSection/${datestring}/${file.name}`)
        } else {
            const newerr = [...newssuberr];
            newerr[i] = "Invalid file type. Please choose a JPEG or PNG image.";
            setnewsubimgerr(newerr);
        }
    };

    const validation = () => {

        const newserrors = newsform.map((e) => {
            const titleerr = !(e.title.trim().length > 50);
            const authornameerr = !e.authorname.trim();
            const descriptionerr = !(e.description.trim().length > 500);
            const imagepatherr = !e.imagePath.trim();
            const subtitleerr = !(e.subtitle.trim().length > 100);
            const readtimeerr = !e.readtime.trim();
            const imageheadererr = !e.imageheader.trim();

            return { title: titleerr, authorname: authornameerr, description: descriptionerr, image: imagepatherr, imageheader: imageheadererr, subtitle: subtitleerr, readtime: readtimeerr };
        });

        const newserrboo = newsform.every((e) => {
            return e.title.trim().length > 50 && e.authorname.trim() && e.description.trim().length > 500 && e.imagePath.trim() && e.imageheader.trim() && e.subtitle.trim().length > 100 && e.readtime.trim() && e?.filename.trim();
        });

        const newssuberrors = newssubform.map((e) => {
            const titleError = !(e?.title.trim().length > 30);
            const imagePathError = !e?.imagePath.trim();
            const descriptionError = !(e?.description.trim().length > 120);
            const imageheaderError = !e?.imageheader.trim();

            return { title: titleError, imagePath: imagePathError, imageheader: imageheaderError, description: descriptionError };
        });

        const newsimgerrs = newsform.map((e) => {
            if (!e?.filename.trim()) {
                return "Image is required";
            }
        });

        const newssubimgerror = newssubform.map((e) => {
            if (!e?.filename.trim()) {
                return "Image is required";
            }
        });

        const newssuberrboo = newssubform.every((e) => {
            return e?.description.trim().length > 120 && e?.filename.trim() && e?.imagePath.trim() && e?.imageheader.trim() && e?.title.trim().length > 30;
        });

        setnewsformerr(newserrors);
        setnewssuberr(newssuberrors);
        setnewsimgerr(newsimgerrs);
        setnewsubimgerr(newssubimgerror);

        return newserrboo && newssuberrboo;

    };

    const invokeAlert = () => {
        if (state) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Updating News Successful!',
            }).then(() => navigate('/news'))
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Adding News Successful!',
            }).then(() => navigate('/news'))
        }
    }

    const onSubmit = () => {
        if (validation()) {
            invokeAlert();
            if (state) {
                const dat = {
                    id: newsform[0]?.id,
                    title: newsform[0]?.title,
                    description: newsform[0]?.description,
                    imagePath: newsform[0]?.imagePath,
                    imageheader: newsform[0]?.imageheader,
                    authorname: newsform[0]?.authorname,
                    subtitle: newsform[0]?.subtitle,
                    readtime: newsform[0]?.readtime,
                    newssubsection: newssubform
                };

                const logdat = {
                    loggeduser: userdat.username,
                    pagename: "News",
                    operation: "UPDATE",
                    description: `Updated News with ImageHeader: ${newsform[0]?.imageheader}${newssubaddtext != null ? ", " + newssubaddtext : ''}${newssubdeltext != null ? ", " + newssubdeltext : ''}${newsimgtext != null ? ", " + newsimgtext : ''}${newsubimgtext != null ? ", " + newsubimgtext : ''} by LoggedIn User ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                }

                services
                    .updatenews(dat)
                    .catch((err) => console.error("error updating news", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs: ", err));

                let formdata = new FormData();

                formdata.append("file", newsimg);
                formdata.append("tablename", "News");
                formdata.append("date", datestring);

                services
                    .uploadImage(formdata)
                    .catch((err) => console.error("error uploading image", err));

                newssubimg.map((e) => {
                    let newssubimgdat = new FormData();
                    newssubimgdat.append("file", e);
                    newssubimgdat.append("tablename", "NewsSubSection");
                    newssubimgdat.append("date", datestring);

                    services
                        .uploadImage(newssubimgdat)
                        .catch((err) => console.error("Error uploading image: ", err));
                });

            } else {
                const dat = {
                    title: newsform[0]?.title,
                    description: newsform[0]?.description,
                    imagePath: newsform[0]?.imagePath,
                    imageheader: newsform[0]?.imageheader,
                    authorname: newsform[0]?.authorname,
                    subtitle: newsform[0]?.subtitle,
                    readtime: newsform[0]?.readtime,
                    newssubsection: newssubform
                };

                const logdat = {
                    loggeduser: userdat.username,
                    pagename: "News",
                    operation: "ADD",
                    description: `Added News by LoggedIn user ${userdat.username} on ${cusDateString}`,
                    datetime: cusDateString
                }

                services
                    .addnews(dat)
                    .catch((err) => console.error("error updating news", err));

                LogServices
                    .addlogs(logdat)
                    .catch((err) => console.error("Error Adding Logs: ", err));

                let formdata = new FormData();

                formdata.append("file", newsimg);
                formdata.append("tablename", "News");
                formdata.append("date", datestring);

                services
                    .uploadImage(formdata)
                    .catch((err) => console.error("error uploading image", err));

                newssubimg.map((e) => {
                    let newssubimgdat = new FormData();
                    newssubimgdat.append("file", e);
                    newssubimgdat.append("tablename", "NewsSubSection");
                    newssubimgdat.append("date", datestring);

                    services
                        .uploadImage(newssubimgdat)
                        .catch((err) => console.error("Error uploading image"));

                })

            }

            setnewsform([{ id: '', title: '', authorname: '', description: '', subtitle: '', readtime: '', imagePath: '', imageheader: '', filename: '' }]);
            setnewsimg(null);
            setnewssubform([{ id: '', description: '', filename: '', imageheader: '', imagePath: '', title: '' }]);
            setnewssubimg([]);
            setnewsformerr([]);
            setnewssuberr([]);
            setnewsubimgerr([]);
        }
    }
    return (
        <>
            {logincred?.length == 1 ?
                <Box
                    component={'form'}
                >
                    <Box
                        component={'div'}
                        sx={{ mb: 2 }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/news'>
                                <Typography fontSize={'.875rem'} color="text.primary">
                                    News Table
                                </Typography>
                            </Link>
                            <Typography fontSize={'.875rem'} color="text.primary">News Form</Typography>
                        </Breadcrumbs>

                    </Box>

                    <Card >
                        <CardHeader
                            title=<Typography fontSize={'1.7rem'} color="text.primary">
                                News Form
                            </Typography>
                        />

                        <CardContent sx={{ py: 0 }}>
                            <Box
                                component={'section'}
                            >

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                >
                                    <Box
                                        component={'div'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        flex={3}
                                    >
                                        <Box
                                            component={'div'}
                                            display='flex'
                                            flexDirection={{ xs: 'column', lg: 'row' }}
                                            gap={{ lg: 3 }}
                                            sx={{ mb: 1 }}
                                        >

                                            <TextField
                                                error={newsformerr[0]?.title}
                                                helperText={newsformerr[0]?.title && 'Please Enter atleast 50 characters'}
                                                id="standard-error-helper-text"
                                                label="Title"
                                                value={newsform[0]?.title}
                                                onChange={(ev) => handlenewsfieldchange('title', ev.target.value)}
                                                variant="standard"
                                                sx={{ flex: 1 }}
                                            />

                                            <TextField
                                                error={newsformerr[0]?.authorname}
                                                helperText={newsformerr[0]?.authorname && 'required'}
                                                id="standard-error-helper-text"
                                                label="Author Name"
                                                value={newsform[0]?.authorname}
                                                onChange={(ev) => handlenewsfieldchange('authorname', ev.target.value)}
                                                variant="standard"
                                                sx={{ flex: 1 }}
                                            />

                                            <TextField
                                                error={newsformerr[0]?.readtime}
                                                id="standard-error-helper-text"
                                                label="Read Time"
                                                value={newsform[0]?.readtime}
                                                onChange={(ev) => handlenewsfieldchange("readtime", ev.target.value)}
                                                helperText={newsformerr[0]?.readtime && "required"}
                                                variant="standard"
                                                sx={{ flex: 1 }}
                                            />

                                            <TextField
                                                error={newsformerr[0]?.subtitle}
                                                id="standard-error-helper-text"
                                                label="Subtitle"
                                                value={newsform[0]?.subtitle}
                                                onChange={(ev) => handlenewsfieldchange("subtitle", ev.target.value)}
                                                helperText={newsformerr[0]?.subtitle && "Please Enter atleast 100 characters"}
                                                variant="standard"
                                                sx={{ flex: 2 }}
                                            />

                                        </Box>

                                        <Box
                                            component={"div"}
                                            display='flex'
                                            flexDirection={{ xs: 'column', lg: 'row' }}
                                            gap={{ lg: 3 }}
                                            sx={{ mb: 2 }}
                                        >

                                            <TextField
                                                error={newsformerr[0]?.imageheader}
                                                id="standard-error-helper-text"
                                                label="Image Header"
                                                value={newsform[0]?.imageheader}
                                                onChange={(ev) => handlenewsfieldchange("imageheader", ev.target.value)}
                                                helperText={newsformerr[0]?.imageheader && "required"}
                                                variant="standard"
                                                sx={{ flex: 1 }}
                                            />

                                            <TextField
                                                error={newsformerr[0]?.description}
                                                helperText={newsformerr[0]?.description && 'Please Enter atleast 500 characters'}
                                                id="standard-error-helper-text"
                                                label="Description"
                                                multiline
                                                value={newsform[0]?.description}
                                                onChange={(ev) => handlenewsfieldchange('description', ev.target.value)}
                                                variant="standard"
                                                sx={{ flex: 3 }}
                                            />

                                        </Box>
                                    </Box>

                                    <Box
                                        component={'div'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        sx={{ flex: 1 }}
                                    >
                                        {newsimg ?
                                            <Button
                                                color='warning'
                                                variant="contained"
                                                component="label"
                                                sx={{ fontSize: 12 }}
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Re-Upload Image
                                                <input
                                                    hidden
                                                    accept="image/jpeg, image/png"
                                                    type="file"
                                                    onChange={(ev) => handleNewsImageChange(ev)}
                                                />
                                            </Button>
                                            :
                                            <Button
                                                variant="contained"
                                                component="label"
                                                sx={{ fontSize: 12 }}
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                Upload Image
                                                <input
                                                    hidden
                                                    accept="image/jpeg, image/png"
                                                    type="file"
                                                    onChange={(ev) => handleNewsImageChange(ev)}
                                                />
                                            </Button>
                                        }

                                        {/* {(newsimgerr) && (
                                                <Typography color="error" variant="body2">
                                                    {newsimgerr}
                                                </Typography>
                                            )} */}

                                        <Box
                                            component={'div'}
                                            mt={2}
                                        >
                                            {newsimg && (
                                                <img src={URL.createObjectURL(newsimg)} alt="image" height={100} width={100} />
                                            )}
                                        </Box>

                                        <Typography color={'GrayText'} sx={{ mb: 1 }} level="body-sm">Background Image</Typography>

                                        {(newsimgerr) && (
                                            <Typography color="error" align='center' variant="body2">
                                                {newsimgerr}
                                            </Typography>
                                        )}


                                    </Box>

                                </Box>

                                {/* <Box
                                    component={'div'}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 1 }}
                                >

                                    <TextField
                                        error={newsformerr[0]?.title}
                                        helperText={newsformerr[0]?.title && 'Please Enter atleast 50 characters'}
                                        id="standard-error-helper-text"
                                        label="Title"
                                        value={newsform[0]?.title}
                                        onChange={(ev) => handlenewsfieldchange('title', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={newsformerr[0]?.authorname}
                                        helperText={newsformerr[0]?.authorname && 'required'}
                                        id="standard-error-helper-text"
                                        label="Author Name"
                                        value={newsform[0]?.authorname}
                                        onChange={(ev) => handlenewsfieldchange('authorname', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={newsformerr[0]?.readtime}
                                        id="standard-error-helper-text"
                                        label="Read Time"
                                        value={newsform[0]?.readtime}
                                        onChange={(ev) => handlenewsfieldchange("readtime", ev.target.value)}
                                        helperText={newsformerr[0]?.readtime && "required"}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={newsformerr[0]?.subtitle}
                                        id="standard-error-helper-text"
                                        label="Subtitle"
                                        value={newsform[0]?.subtitle}
                                        onChange={(ev) => handlenewsfieldchange("subtitle", ev.target.value)}
                                        helperText={newsformerr[0]?.subtitle && "Please Enter atleast 100 characters"}
                                        variant="standard"
                                        sx={{ flex: 2 }}
                                    />

                                </Box>

                                <Box
                                    component={"div"}
                                    display='flex'
                                    flexDirection={{ xs: 'column', lg: 'row' }}
                                    gap={{ lg: 3 }}
                                    sx={{ mb: 2 }}
                                >

                                    <TextField
                                        error={newsformerr[0]?.imageheader}
                                        id="standard-error-helper-text"
                                        label="Image Header"
                                        value={newsform[0]?.imageheader}
                                        onChange={(ev) => handlenewsfieldchange("imageheader", ev.target.value)}
                                        helperText={newsformerr[0]?.imageheader && "required"}
                                        variant="standard"
                                        sx={{ flex: 1 }}
                                    />

                                    <TextField
                                        error={newsformerr[0]?.description}
                                        helperText={newsformerr[0]?.description && 'Please Enter atleast 500 characters'}
                                        id="standard-error-helper-text"
                                        label="Description"
                                        multiline
                                        value={newsform[0]?.description}
                                        onChange={(ev) => handlenewsfieldchange('description', ev.target.value)}
                                        variant="standard"
                                        sx={{ flex: 3 }}
                                    />

                                </Box> */}

                                {/* <Box
                                    component={'div'}
                                >
                                    {newsimg ?
                                        <Button
                                            color='warning'
                                            variant="contained"
                                            component="label"
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Re-Upload Image
                                            <input
                                                hidden
                                                accept="image/jpeg, image/png"
                                                type="file"
                                                onChange={(ev) => handleNewsImageChange(ev)}
                                            />
                                        </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            component="label"
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload Image
                                            <input
                                                hidden
                                                accept="image/jpeg, image/png"
                                                type="file"
                                                onChange={(ev) => handleNewsImageChange(ev)}
                                            />
                                        </Button>
                                    }

                                    {(newsimgerr) && (
                                        <Typography color="error" variant="body2">
                                            {newsimgerr}
                                        </Typography>
                                    )}

                                    <Box
                                        component={'div'}
                                        mt={2}
                                    >
                                        {newsimg && (
                                            <img src={URL.createObjectURL(newsimg)} alt="image" height={100} width={100} />
                                        )}
                                    </Box>

                                </Box> */}

                                {togglenewssub && newssubform.length > 0 ?
                                    <Card>
                                        <CardHeader
                                            sx={{ paddingX: 2 }}
                                            title=<Typography fontSize={'1.35rem'} color="text.primary">
                                                NewsSubSections
                                            </Typography>
                                        />

                                        <CardContent sx={{ p: 0 }}>
                                            {newssubform.map((e, i) => (

                                                <Box component={'div'} key={i} sx={{ mb: 1 }}>
                                                    <CardHeader
                                                        sx={{
                                                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                                                            py: 0, px: 2
                                                        }}
                                                        action={
                                                            <Tooltip title="Remove field">
                                                                <IconButton onClick={() => handleRemoveNewsSubField(i)}>
                                                                    <DeleteOutlineIcon color='error' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        title=<Typography fontSize={'1.1rem'} color="text.primary">
                                                            NewsSubSection {i + 1}
                                                        </Typography>
                                                    />

                                                    <CardContent sx={{ px: 2, py: 0 }}>

                                                        <Box
                                                            component={'section'}
                                                        >

                                                            <Box
                                                                component={'div'}
                                                                display={'flex'}
                                                                flexDirection={{ xs: 'column', lg: 'row' }}
                                                            >

                                                                <Box
                                                                    component={'div'}
                                                                    display={'flex'}
                                                                    flexDirection={'column'}
                                                                    flex={3}
                                                                >
                                                                    <Box
                                                                        component={'div'}
                                                                        display='flex'
                                                                        flexDirection={{ xs: 'column', lg: 'row' }}
                                                                        gap={{ lg: 3 }}
                                                                        sx={{ mb: 1 }}
                                                                    >

                                                                        <TextField
                                                                            error={newssuberr[i]?.title}
                                                                            helperText={newssuberr[i]?.title && 'Please Enter atleast 30 characters'}
                                                                            id="standard-error-helper-text"
                                                                            label="Title"
                                                                            value={e.title}
                                                                            onChange={(ev) => handleNewsSubSecFieldChange(i, 'title', ev.target.value)}
                                                                            variant="standard"
                                                                            sx={{ flex: 1 }}
                                                                        />

                                                                        <TextField
                                                                            error={newssuberr[i]?.imageheader}
                                                                            helperText={newssuberr[i]?.imageheader && 'required'}
                                                                            id="standard-error-helper-text"
                                                                            label="Image Header"
                                                                            value={e.imageheader}
                                                                            onChange={(ev) => handleNewsSubSecFieldChange(i, 'imageheader', ev.target.value)}
                                                                            variant="standard"
                                                                            sx={{ flex: 1 }}
                                                                        />

                                                                    </Box>

                                                                    <Box
                                                                        component={"div"}
                                                                        sx={{
                                                                            '& .MuiTextField-root': { width: '100%', mb: 2 },
                                                                        }}
                                                                        noValidate
                                                                        autoComplete="off"
                                                                    >
                                                                        <TextField
                                                                            error={newssuberr[i]?.description}
                                                                            helperText={newssuberr[i]?.description && 'Please Enter atleast 120 characters'}
                                                                            id="standard-error-helper-text"
                                                                            label="Description"
                                                                            multiline
                                                                            value={e.description}
                                                                            onChange={(ev) => handleNewsSubSecFieldChange(i, 'description', ev.target.value)}
                                                                            variant="standard"
                                                                        />

                                                                    </Box>
                                                                </Box>

                                                                <Box
                                                                    component={'div'}
                                                                    display={'flex'}
                                                                    flexDirection={'column'}
                                                                    alignItems={'center'}
                                                                    sx={{ flex: 1 }}
                                                                >
                                                                    {newssubimg[i] ? (
                                                                        <Button
                                                                            color='warning'
                                                                            variant="contained"
                                                                            component="label"
                                                                            sx={{ fontSize: 12 }}
                                                                            startIcon={<CloudUploadIcon />}
                                                                        >
                                                                            Re-Upload Image
                                                                            <input
                                                                                hidden
                                                                                accept="image/jpeg, image/png"
                                                                                type="file"
                                                                                onChange={(ev) => { handleNewsSubImageChange(i, ev); }}
                                                                            />
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            variant="contained"
                                                                            component="label"
                                                                            sx={{ fontSize: 12 }}
                                                                            startIcon={<CloudUploadIcon />}
                                                                        >
                                                                            Upload Image
                                                                            <input
                                                                                hidden
                                                                                accept="image/jpeg, image/png"
                                                                                type="file"
                                                                                onChange={(ev) => { handleNewsSubImageChange(i, ev); }}
                                                                            />
                                                                        </Button>
                                                                    )
                                                                    }

                                                                    {/* {newsubimgerr[i] && (
                                                                        <Typography color="error" variant="body2">
                                                                            {newsubimgerr[i]}
                                                                        </Typography>
                                                                    )} */}

                                                                    <Box
                                                                        component={'div'}
                                                                        mt={2}
                                                                    >
                                                                        {newssubimg[i] && (
                                                                            <img src={URL.createObjectURL(newssubimg[i])} alt="image" height={100} width={100} />
                                                                        )}

                                                                    </Box>
                                                                    <Typography color={'GrayText'} sx={{ mb: 1 }} level="body-sm">{`NewsSubSection ${i + 1} Image`}</Typography>

                                                                    {newsubimgerr[i] && (
                                                                        <Typography color="error" align='center' variant="body2">
                                                                            {newsubimgerr[i]}
                                                                        </Typography>
                                                                    )}
                                                                </Box>

                                                            </Box>


                                                            {/* <Box
                                                                component={'div'}
                                                                display='flex'
                                                                flexDirection={{ xs: 'column', lg: 'row' }}
                                                                gap={{ lg: 3 }}
                                                                sx={{ mb: 1 }}
                                                            >

                                                                <TextField
                                                                    error={newssuberr[i]?.title}
                                                                    helperText={newssuberr[i]?.title && 'Please Enter atleast 30 characters'}
                                                                    id="standard-error-helper-text"
                                                                    label="Title"
                                                                    value={e.title}
                                                                    onChange={(ev) => handleNewsSubSecFieldChange(i, 'title', ev.target.value)}
                                                                    variant="standard"
                                                                    sx={{ flex: 1 }}
                                                                />

                                                                <TextField
                                                                    error={newssuberr[i]?.imageheader}
                                                                    helperText={newssuberr[i]?.imageheader && 'required'}
                                                                    id="standard-error-helper-text"
                                                                    label="Image Header"
                                                                    value={e.imageheader}
                                                                    onChange={(ev) => handleNewsSubSecFieldChange(i, 'imageheader', ev.target.value)}
                                                                    variant="standard"
                                                                    sx={{ flex: 1 }}
                                                                />

                                                            </Box>

                                                            <Box
                                                                component={"div"}
                                                                sx={{
                                                                    '& .MuiTextField-root': { width: '100%', mb: 2 },
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField
                                                                    error={newssuberr[i]?.description}
                                                                    helperText={newssuberr[i]?.description && 'Please Enter atleast 120 characters'}
                                                                    id="standard-error-helper-text"
                                                                    label="Description"
                                                                    multiline
                                                                    value={e.description}
                                                                    onChange={(ev) => handleNewsSubSecFieldChange(i, 'description', ev.target.value)}
                                                                    variant="standard"
                                                                />

                                                            </Box> */}

                                                            {/* {newssubimg[i] ? (
                                                                <Button
                                                                    color='warning'
                                                                    variant="contained"
                                                                    component="label"
                                                                    startIcon={<CloudUploadIcon />}
                                                                >
                                                                    Re-Upload Image
                                                                    <input
                                                                        hidden
                                                                        accept="image/jpeg, image/png"
                                                                        type="file"
                                                                        onChange={(ev) => { handleNewsSubImageChange(i, ev); }}
                                                                    />
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    variant="contained"
                                                                    component="label"
                                                                    startIcon={<CloudUploadIcon />}
                                                                >
                                                                    Upload Image
                                                                    <input
                                                                        hidden
                                                                        accept="image/jpeg, image/png"
                                                                        type="file"
                                                                        onChange={(ev) => { handleNewsSubImageChange(i, ev); }}
                                                                    />
                                                                </Button>
                                                            )
                                                            } */}


                                                            {/* {newsubimgerr[i] && (
                                                                <Typography color="error" variant="body2">
                                                                    {newsubimgerr[i]}
                                                                </Typography>
                                                            )}

                                                            <Box
                                                                component={'div'}
                                                                mt={2}
                                                            >
                                                                {newssubimg[i] && (
                                                                    <img src={URL.createObjectURL(newssubimg[i])} alt="image" height={100} width={100} />
                                                                )}

                                                            </Box> */}

                                                        </Box>
                                                    </CardContent>
                                                    {i < newssubform.length - 1 ? <Divider /> : <Box component={'div'}></Box>}
                                                </Box>
                                            ))}
                                            <Box
                                                component={'div'}
                                                display={'flex'}
                                                justifyContent={{ xs: 'center', md: 'flex-start' }}
                                                sx={{ px: 2 }}
                                            >
                                                <Button onClick={() => handleNewsSubSecField()} variant="contained">
                                                    Add More
                                                </Button>
                                            </Box>
                                        </CardContent>

                                    </Card> : <>
                                        <Box
                                            component={'div'}
                                            display={'flex'}
                                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                                        >
                                            <Button onClick={() => handleaddnewssubbutton()} variant="contained">
                                                Add NewsSubSections
                                            </Button>
                                        </Box>
                                    </>
                                }

                                <Box
                                    component={'div'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', md: 'flex-end' }}
                                    sx={{ mt: 2 }}
                                >
                                    <Button variant="contained" onClick={() => onSubmit()}>
                                        Save
                                    </Button>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>

                </Box> : <></>}

        </>
    )
}

export default NewsForm