import { DOMAIN, PORT, SCHEME } from "app/constants/constants";
import axios from "axios";

class Services {
    gethyperlinks() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/gethyperlinks`);
    };

    gethomebanners() {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/gethomebanners`);
    };

    gethomebannerbyid(id) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/gethomebanner/${id}`);
    };

    addhomebanner(dat) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addhomebanners`, dat);
    };

    updatehomebanner(dat) {
        return axios.put(`${SCHEME}${DOMAIN}${PORT}/updatehomebanners`, dat);
    };

    deletehomebanner(id) {
        return axios.delete(`${SCHEME}${DOMAIN}${PORT}/deletehomebanner/${id}`);
    };

    //Image Uploading

    uploadImage(formdata) {
        return axios.post(`${SCHEME}${DOMAIN}${PORT}/addimage`, formdata);
    };

    //Image Retrieving
    getImage(tablename, date, filename) {
        return axios.get(`${SCHEME}${DOMAIN}${PORT}/retrieveimage/${tablename}/${date}/${filename}`, { responseType: 'blob' });
    };
};

export default new Services();