import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Services from './Services';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#7352c7",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const SocialMedia = () => {
    const [data, setdata] = useState([]);
    const [rundata, setrundata] = useState([])
    const [puredat, setpuredat] = useState([]);
    const [sorttoggle, setsorttoggle] = useState(true);
    const [id, setid] = useState(1);

    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const [logincred, setlogincred] = useState({});
    const userdat = JSON.parse(localStorage.getItem('user'));

    // rendata = [
    //     {
    //         id: 1,
    //         socialmedia: "Facebook",
    //         link: data?.facebook
    //     },
    //     {
    //         id: 2,
    //         socialmedia: "Instagram",
    //         link: data?.instagram
    //     },
    //     {
    //         id: 3,
    //         socialmedia: `X (formerly Twitter)`,
    //         link: data?.x
    //     },
    //     {
    //         id: 4,
    //         socialmedia: 'LinkedIn',
    //         link: data?.linkedin
    //     },
    //     {
    //         id: 5,
    //         socialmedia: 'Snapchat',
    //         link: data?.snapchat
    //     },
    //     {
    //         id: 6,
    //         socialmedia: 'Whatsapp',
    //         link: data?.phno
    //     }
    // ]
    // useEffect(() => {
    //     setrundata(rendata)
    //     setpuredat(rendata);
    // }, [])

    useEffect(() => {
        const ef = userdat?.menus.filter((es) => es?.menuname.includes("Social Media"));
    
        setlogincred(ef);
    
        if (ef?.length == 1) {
          console.log("If part");
        } else {
          console.log("Else part");
          Swal.fire({
            icon: 'error',
            title: 'UnAuthorized Access',
            text: 'Please Login to continue',
          }).then(() => navigate('/'))
        }
      }, [])

    useEffect(() => {
        Services
            .getSocialMedia(id)
            .then((e) => {
                setdata(e?.data);
                const rendata = [
                    {
                        id: 1,
                        socialmedia: "Facebook",
                        link: e?.data?.facebook
                    },
                    {
                        id: 2,
                        socialmedia: "Instagram",
                        link: e?.data?.instagram
                    },
                    {
                        id: 3,
                        socialmedia: `X (formerly Twitter)`,
                        link: e?.data?.x
                    },
                    {
                        id: 4,
                        socialmedia: 'LinkedIn',
                        link: e?.data?.linkedin
                    },
                    {
                        id: 5,
                        socialmedia: 'Snapchat',
                        link: e?.data?.snapchat
                    },
                    {
                        id: 6,
                        socialmedia: 'Whatsapp',
                        link: e?.data?.phno
                    }
                ]

                setrundata(rendata)
                setpuredat(rendata);
            });
    }, []);

    const handleSearch = (hes) => {
        const filtered = puredat?.filter((res) => res?.socialmedia.toLowerCase().includes(hes.toLowerCase()) || res?.link.toLowerCase().includes(hes.toLowerCase()));
        setrundata(filtered);
    }

    const handleSort = () => {
        setsorttoggle((prev) => !prev);

        if (sorttoggle) {
            const sorted = rundata?.sort((a, b) => a?.socialmedia > b?.socialmedia ? 1 : -1);
            setrundata(sorted);
        } else {
            const sorted = rundata?.sort((a, b) => a?.socialmedia < b?.socialmedia ? 1 : -1);
            setrundata(sorted);
        }
    };

    return (
        <>
            {logincred?.length == 1 ?
                <Card >
                    <CardHeader
                        sx={{
                            '& .MuiCardHeader-action': { alignSelf: 'center' },
                        }}
                        action={
                            <Stack justifyContent={'center'} direction={'row'} alignItems={'center'} spacing={-0.8}>
                                <Tooltip title="Search SocialMedia">
                                    <Input
                                        id="input-with-icon-adornment"
                                        placeholder='Search'
                                        onChange={(ev) => handleSearch(ev.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchOutlinedIcon fontSize='small' color='action' />
                                            </InputAdornment>
                                        }
                                    />
                                </Tooltip>

                                <Tooltip title="Sort SocialMedia">
                                    <IconButton onClick={() => handleSort()}>
                                        <Link>
                                            <SwapVertOutlinedIcon fontSize='small' color={sorttoggle ? "action" : "secondary"} />
                                        </Link>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Update SocialMedia">
                                    <IconButton >
                                        <Link to="/socialmediaform" state={data}>
                                            <ModeOutlinedIcon fontSize='small' color='action' />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        }

                        title=<Typography fontWeight={500} fontSize={'1.7rem'} color="text.primary">
                            SocialMedia
                        </Typography>
                    />

                    <CardContent sx={{ py: 0 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Sl No</StyledTableCell>
                                        <StyledTableCell align="left">SocialMedia</StyledTableCell>
                                        <StyledTableCell width={25} align="center">Link</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rundata?.map((ev, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align='left' component="th" scope="row">{index + 1}</StyledTableCell>
                                            <StyledTableCell align='left' component="th" scope="row">{ev?.socialmedia}</StyledTableCell>
                                            <StyledTableCell align="left" component="th" scope="row">{ev?.link}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}

                                    {/* <StyledTableRow >
                                        <StyledTableCell align='center' component="th" scope="row">1</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">Facebook</StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">{data?.facebook}</StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow >
                                        <StyledTableCell align='center' component="th" scope="row">2</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">Instagram</StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">{data?.instagram}</StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow >
                                        <StyledTableCell align='center' component="th" scope="row">3</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">{`X (formerly Twitter)`}</StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">{data?.x}</StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow >
                                        <StyledTableCell align='center' component="th" scope="row">4</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">LinkedIn</StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">{data?.linkedin}</StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow >
                                        <StyledTableCell align='center' component="th" scope="row">5</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">Snapchat</StyledTableCell>
                                        <StyledTableCell align="center" component="th" scope="row">{data?.snapchat}</StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align='center' component="th" scope="row">6</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">Whatsapp</StyledTableCell>
                                        <StyledTableCell align='center' component="th" scope="row">{data?.phno}</StyledTableCell>
                                    </StyledTableRow> */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card> : <></>}

        </>
    )
}

export default SocialMedia;